/* End Event Two Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Event Area css
============================================================================================ */


.room_list_area {
}

.room_list_area .room_list_inner {
    margin-bottom: -50px;
}

.room_list_item {
    overflow: hidden;
    clear: both;
    margin-bottom: 30px;
    -webkit-box-shadow: 0px 3px 35px 0px rgba(0, 0, 0, 0.09);
    box-shadow: 0px 3px 35px 0px rgba(0, 0, 0, 0.09);
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 8px;
    background: #fff;
}

.room_list_item .room_img {
    margin-left: -30px;
    display: block;
    position: relative;
}

.room_list_item .room_img:before {
    content: "";
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
    -webkit-transition: all 300ms linear 0s;
    -o-transition: all 300ms linear 0s;
    transition: all 300ms linear 0s;
}

.room_list_item .room_img img {
    width: 100%;
    min-height: 350px;
    max-height: 100%;
    object-fit: cover;
}

.room_list_item .room_list_l_text .title-name {
    font-size: 30px;
    font-weight: bold;
    color: #34383b;
    font-weight: bold !important;
    padding: 15px 0px 0px 0px;
    margin-bottom: 0;
    font-size: 20px !important;
    margin-bottom: 10px;
    color: #34383b;
    font-weight: 700 !important;
    padding: 15px 0 0;
}

/*.room_list_item .room_list_l_text h4:hover {*/
    /*color: #00adee*/
/*}*/

.room_list_item .room_list_l_text ul {
    padding-bottom: 0px;
    position: relative;
    margin-bottom: 10px;
    padding-left: 0;
}



.room_list_item .room_list_l_text ul li {
    display: inline-block;
    color:#858688;
}

.room_list_item .room_list_l_text ul li a {
    font-size: 16px;
    color: #34383b;
    letter-spacing: .225px;
    padding-right: 24px;
    position: relative;
    -webkit-transition: all 300ms linear 0s;
    -o-transition: all 300ms linear 0s;
    transition: all 300ms linear 0s;
}

.room_list_item .room_list_l_text ul li a:before {
    content: "/";
    position: absolute;
    right: 8px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 16px;
    color: #34383b;
    letter-spacing: .225px;
}

.room_list_item .room_list_l_text ul li:last-child a {
    padding-right: 0px;
}

.room_list_item .room_list_l_text ul li:last-child a:before {
    display: none;
}

.room_list_item .room_list_l_text ul li:hover a {
    color: #00adee
}

.room_list_item .room_list_l_text p {
    font-size: 14px;
    color: #6d6e70;

}

.room_list_item .room_price_box {
    background: #f9f9f9;
    padding: 20px 20px;
    margin-top: 0px;
    margin-right: 0px;
    min-height: 350px;
    max-height: 100%;
}

.room_list_item .room_price_box h5 {
    font-size: 24px;
    font-weight: bold;
    color: #242424;
    margin: 0px;
}

.room_list_item .room_price_box h6 {
    font-size: 16px;
    color: #a0a0a0;
    font-weight: normal !important;
    text-transform: inherit;
    padding: 0;
    margin: 5px 0 !important;
}

.room_list_item:hover .room_img:before {
    opacity: 1;
}

.room_pagination {
    overflow: hidden;
    text-align: center;
    margin-top: 100px;
    margin-bottom: -6px;
}

.room_pagination .pagination {
    margin: 0px;
    border-radius: 0px;
}

.room_pagination .pagination li {
    margin-right: 18px;
    display: inline-block;
}

.room_pagination .pagination li a {
    border-radius: 0px;
    height: 48px;
    padding: 0px;
    width: 48px;
    line-height: 44px;
    border: 2px solid #dadada;
    font-size: 20px;
    color: #7e7e7e;
    display: block;
    background: transparent;
    -webkit-transition: all 300ms linear 0s;
    -o-transition: all 300ms linear 0s;
    transition: all 300ms linear 0s;
}

.room_pagination .pagination li:hover a {
    border-color: #00adee;
    color: #00adee
}
/* Explor Room Area css
============================================================================================ */
.explor_room_area {
    background: #f6f6f6;
    padding: 100px 0px;
}

.explor_room_area .explor_title {
    padding-bottom: 55px;
}

.explor_room_area .explor_title .pull-right {
    margin-top: 10px;
}

.explor_room_area .explor_title .pull-right .about_btn_b {
    line-height: 46px;
}

.explor_room_area.explor_slider_area {
    position: relative;
    z-index: 2;
}

.explor_room_area.explor_slider_area:before {
    content: "";
    background: #00adee;
    height: 505px;
    width: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: -1;
}

.explor_room_area.explor_slider_area .explor_title {
    padding-bottom: 41px;
}

.explor_room_area.explor_slider_area .explor_slider .explor_slider {
    text-align: center;
    margin-top: 45px;
}

.explor_room_area.explor_slider_area .explor_slider .explor_slider .owl-prev, .explor_room_area.explor_slider_area .explor_slider .explor_slider .owl-next {
    height: 46px;
    width: 46px;
    text-align: center;
    font-size: 25px;
    color: #7e7e7e;
    border: 2px solid #d1d1d1;
    line-height: 41px;
    display: inline-block;
    cursor: pointer;
    -webkit-transition: all 300ms linear 0s;
    -o-transition: all 300ms linear 0s;
    transition: all 300ms linear 0s;
}

.explor_room_area.explor_slider_area .explor_slider .explor_slider .owl-prev:hover, .explor_room_area.explor_slider_area .explor_slider .explor_slider .owl-next:hover {
    background: #00adee;
    color: #fff;
    border-color: #00adee
}

.explor_room_area.explor_slider_area .explor_slider .explor_slider .owl-prev {
    margin-right: 26px;
}

.explore_room_list .explor_room_item_inner {
    margin-bottom: -50px;
    overflow: hidden;
}

.explore_room_list .explor_room_item_inner .explor_item {
    margin-bottom: 50px;
}

.explor_item {
    background: #fff;margin: 5px 0 15px 0;
}

.explor_item .room_image {
    position: relative;
    display: block;
    height: 80px;
    overflow:hidden;
    /*background: #a5d6f6;*/
    border-radius: 5px;
    width: 120px;
}

.explor_item .room_image img {
    width: 120px;
    height: 80px;
}

.explor_item .room_image:before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    opacity: 0;
    -webkit-transition: all 300ms linear 0s;
    -o-transition: all 300ms linear 0s;
    transition: all 300ms linear 0s;
}

.explor_item .room_image:hover:before {
    opacity: 1;
}

.explor_item .explor_text {
    overflow: hidden;
    margin-top: 5px;
}

.explor_item .explor_text h4 {
    font-size: 11px;
    font-weight: normal !important;
    letter-spacing: .60px;
    color: #34383b;
    text-transform: inherit;
    -webkit-transition: all 300ms linear 0s;
    -o-transition: all 300ms linear 0s;
    transition: all 300ms linear 0s;
    padding: 0;
    margin-top: 15px;
}

.room_list_l_text h4 {

    letter-spacing: .60px;
    color: #34383b;
    text-transform: inherit;
    -webkit-transition: all 300ms linear 0s;
    -o-transition: all 300ms linear 0s;
    transition: all 300ms linear 0s;
    font-size: 30px !important;
    margin-bottom: 0px;
    font-weight: 700 !important;
    padding: 20px 0 0;

}

.modal-content .room_list_l_text h4{
    padding: 0;
}

.explor_item .explor_text h4:hover {
    color: #00adee
}

.explor_item .explor_text ul {
    padding-top: 15px;
    padding-bottom: 25px;
    position: relative;
    margin-bottom: 22px;
}

.explor_item .explor_text ul:before {
    content: "";
    background: #eeeeee;
    height: 2px;
    width: 53px;
    position: absolute;
    left: 0px;
    bottom: 0px;
}

.explor_item .explor_text ul li {
    display: inline-block;
}

.explor_item .explor_text ul li a {
    color: #898989;
    letter-spacing: .30px;
    font-size: 16px;
}

.explor_item .explor_text .explor_footer .pull-left h3 {
    font-size: 16px;
    font-weight: bold;
    color: #34383b;
    letter-spacing: .60px;
    padding: 0px;
    margin: 6px 0 0 0;
}

.explor_item .explor_text .explor_footer .pull-left h3 span {
    color: #a0a0a0;
    font-size: 14px;
}

.explor_item .explor_text .explor_footer .pull-right .book_now_btn {
    padding: 0px 13px;
    border: 2px solid #00adee;
    line-height: 34px;
}
.padleft0{ padding-left:0px;}
.padright0{ padding-right:0px;}
a.book_now_btn {
    background: #e92017;
    color: #fff;
    width: 100%;
    float: left;
    padding: 8px 5px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 5px;
    text-align: center;
    margin-top: 5px;
}
.room_price_box ul {
    padding-left: 0;
    list-style: none;
}
.room_price_box ul li i {color:#00adee;}
.room_price_box .above h3 {
    margin-top: 0;
}
.room_price_box .below {
    bottom: 0;
    position: relative;
    margin-top: 40px;
}
.weekend1 a {
    color: #00adee;
    font-size: 16px;
}
.weekend1 p{
    color: #6d6e70;
    font-size: 20px;}
.weekend1 h1{color:#34383b; font-size:40px; margin-top: 40px;}

.padtop0{padding-top:0 !important;}
.moreitem h3 {
    color: #00adee;
    font-size: 14px;
    margin: 20px 0 0 0;
}

.makepass{ border:3px solid #efefef; background:#fff; border-radius:8px;
    text-align: center;
    padding: 20px 10px;}
.makepass h1{font-size:36px; color:#34383b;     font-weight: bold !important;
    margin-top: 0;}
.pad0{padding:0px;}
.makepass a {
    background: #00adee;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 12px 20px;
    margin: 10px 0 0 0;
    display: inline-block;
    border-radius: 5px;
}
.makepass a i {
    font-weight: 300 !important;
    border: 2px solid #fff;
    border-radius: 50px;
    padding: 4px 5px;
    font-size: 14px;
}
strike {
    text-decoration: line-through !important;
}
.hero-image {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("images/header-banner.jpg");
    height: 500px;
    background-position: 50% 70%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    -moz-transition: height .5s;
    -ms-transition: height .5s;
    -o-transition: height .5s;
    -webkit-transition: height .5s;
}
.hero-image-inner{
    background-image: url("images/header-banner-inner.jpg");
    height: 400px;
    background-position: 50% 70%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}


.hero-text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    /*width: 100%;*/
}

.hero-text button {
    border: none;
    outline: 0;
    display: inline-block;
    padding: 10px 25px;
    color: black;
    background-color: #ddd;
    text-align: center;
    cursor: pointer;
}
.hero-text a.weekendpass {
    background: #fff;
    color: #000;
    font-size: 24px;
    font-weight: 700;
    padding: 8px 30px;
    border-radius: 50px;
}
.hero-text a.weekendpass span{
    color: #00adee;
}
.hero-text h1 {
    font-size: 36px;
    color: #fff;
    margin: 20px 0 10px 0;
}
.hero-text div {
    font-size: 16px;
    width: 90%;
    margin: 0 auto 15px auto;
}
.hero-text a.readmore {
    color: #fff;
    border-radius: 50px;
    border: 1px solid #fff;
    padding: 6px 15px;
    font-size: 12px;
}
.martopfirst_item {
    margin-top: -80px;
}

.fade-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to top,rgba(25,25,25,.9) 0%,rgba(0,0,0,0) 50%)
}
.bottom-left {
    position: absolute;
    bottom: 25px;
    width: 100%;
}

.bottom-left ul li {
    display: inline;
    margin-right: 6px;
    color: #fff;
}
.bottom-left ul {
    margin: 0;
    padding-left: 0;
}
.bottom-left ul li a {
    color: #fff;  font-size: 12px;
}
.bottom-left ul li a i {
    margin-left: 6px;
}
.bottom-left h1 {
    color: #fff;
    font-size: 40px;
    margin: 5px 0;
}
.bottom-left span {
    color: #fff;
    font-size: 14px;
}
h1.payment-detail{
    background: rgb(0,172,237);
    background: linear-gradient(10deg, rgba(0,172,237,1) 35%, rgba(0,126,197,1) 100%);}



@media only screen and (max-width: 600px) {
    .hero-text {
        width: 95%;
    }
    .hero-text h1 {
        font-size: 25px;
    }
    .room_list_item .room_img img {
        width: 100%;
        min-height: auto;
        max-height: 100%;
        object-fit: cover;
    }
    .martopfirst_item {
        margin-top: -330px;
    }
    .hero-image {
        height: 650px;
        background-position: 50% 50%;
    }
    .room_list_l_text {
        padding: 15px 15px 15px 0px;
    }
    .room_price_box .above {
        display: none;
    }
    .room_price_box .below {
        /* bottom: 0; */
        position: relative;
        margin-top: 0px;
        float: left;
        width: 100%;
    }
    .room_list_item .room_price_box {
        background: #f9f9f9;
        padding: 20px 20px;
        margin-top: 0px;
        margin-right: 0px;
        min-height: auto;
        max-height: 100%;
        float: left;
        width: 100%;
    }
    .hero-text {
        text-align: center;
        position: absolute;
        top: 25%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
    }
}


/* End Explor Room Area css
============================================================================================ */


.pro-detail .right-section .block {
    padding: 15px !Important;
}
.pro-detail .promo-block {
    padding: 15px !Important;
}
.pro-detail .order-total {
    padding: 15px !Important;
}
.pro-detail .order-total p {
    font-size: 16px !Important;
}
.pro-detail .order-total h5 {
    font-size: 16px !important;
}
.pro-detail .order-total p span {
    float: left;
    width: 100%;
    color: #858688;
    display: block;
    font-size: 12px;
}
.experiance_block_main {
    border: 2px dashed #00adee;
    border-radius: 10px;
    padding: 0 20px 20px 20px;
    margin-bottom: 20px;

}
 .exp_title {
    color: #34383b;
    font-size: 20px;
    margin-bottom: 15px;
     display: block;
     margin-top: 15px;
}
.room_list_item.internal {
    margin: 0 0 0px 0 !important;
}
.room_list_item .room_list_l_text .title-name {
    font-size: 20px !important;margin-bottom: 0px;
    display: block;
}
.room_list_item.internal .room_list_l_text ul li a img {
    width: 12px;
    display: inline-block;
    margin-right: 5px;
}
.room_list_item .room_list_l_text ul li a {
    font-size: 14px !important;
    color: #858688 !important;
}

.room_list_item .rating_block .MuiRating-readOnly{

    margin-left: 0px;

}

.rating_block {
    margin-bottom: 0px;
    display: flex;
    font-size: 16px;
}
.MuiRating-readOnly {
    pointer-events: none;
    margin-left: 10px;
}
.offer_prize h4 span {
    text-decoration: line-through;
    color: #858688;
    font-size: 14px;
}
.offer_prize .pull-right.view_btn {

    background: #00adee;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    padding: 5px 18px;
    position: relative;
    right: 15px;
    box-shadow: none;
    border: none;
    margin-top: 10px;

}
.room_list_item.internal .room_img img {
    min-height: auto !important;
}
section.news_letter_block {
    background: #00adee;
    padding: 50px 0;
    margin-bottom: 40px;
}
section.news_letter_block p {
    margin: 0;
    color: #fff;
    font-size: 16px;
}
section.news_letter_block input.form-control {
    background: transparent;
    border: 0px;
    outline: navajowhite;
    color: #fff;
    border-bottom: 1px solid #fff;
    border-radius: 0px !important;
    font-size: 12px;
    text-align: center;
}

section.news_letter_block input[type="submit"] {
    background: #fff;
    border-color: #fff;
    display: block;
    width: 100%;
    border: 1px solid #fff;
    margin-top: 12px;
    color: #ec1c24;
    font-size: 16px;
    text-transform: capitalize;
    border-radius: 4px;
    padding: 5px 0;
}



.explor_item .explor_text a h4 {
    font-size: 11px !important;
}

.room_price_box .below {
    margin-top: 30px!important;
}

.weekend-pass-popup .modal-dialog {
    max-width: 800px !important;
    box-shadow: 0 0 30px rgba(0,0,0,0.15);
}

/*.weekend-pass-popup .room_list_item.internal {*/
    /*margin: 0 0 0px !important;*/
/*}*/

.weekend-pass-popup .room_list_item .room_img {
    margin-left: 0px;
    display: block;
    position: relative;
}

.weekend-pass-popup .room_list_item.internal {
    margin: 20px 0 20px !important;
    padding: 20px;
}

.weekend-pass-popup .room_list_item.internal .room_img img {
    min-height: auto !important;
    border-radius: 10px;
}

.weekend-pass-popup .room_list_item .room_img::before {
    content: "";
    background: rgba(0,0,0,.4);
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    -webkit-transition: all .3s linear 0s;
    transition: all .3s linear 0s;
    border-radius: 15px;
}

.weekend-pass-popup ul.services_ul li img {
    width: 12px;
    margin-right: 10px;
}

.weekend-pass-popup .room_list_item .room_list_l_text ul.services_ul li {
    width: 100%;
    font-size: 14px;
}

.weekend-pass-popup .info_block h5 {
    font-size: 18px;
}

.weekend-pass-popup .info_block p {
    font-size: 14px;
}

.weekend-pass-popup ul.key_highilits {
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 0;
}
.weekend-pass-popup ul.key_highilits li {
    display: inline-block;
    width: 33.33%;
    font-size: 14px;
    color: #6d6e70;
    margin: 5px 0 10px 0;
}

.weekend-pass-popup ul.key_highilits li img {
    margin-right: 20px;
    width: 15px;
}

.weekend-pass-popup a.read-full {
    background: #00adee;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    padding: 8px 18px;
    display: inline-block;
    margin-top: 12px;
}

.weekend-pass-options-box .top-block>span{display:table-cell; }
.weekend-pass-options-box .top-block>span>h4{margin: 5px; }

.pro-detail {
    width: 100%;
    position: relative;
    /*background: none !important;*/
    float: left;
    padding: 15px 0;
    padding-top: 15px;
    box-shadow: 0 3px 35px 0 rgba(0,0,0,.09);
    /*border-radius: 10px;*/
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.pro-detail .payment {
    background: rgb(0,173,238);
    background: linear-gradient(90deg, rgba(0,173,238,1) 0%, rgba(0,125,196,1) 100%);
    font-size: 20px;
    color: #fff;
    padding: 15px 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: center;
}
.offer-data-left {
    margin-bottom: 12px;
}
.pro-detail .top-block h5 {
    float: right;
    font-size: 14px;
    margin: 0 0 0 0;
    /*width: 100%;*/
    color:#007dc4;
    text-transform: uppercase;
}
.pro-detail .right-side.heading .offer-data-right h4 {
    font-weight: 700 !important;
}
.right-side.heading {
    float: right !important;
}


#rightCol {
    margin-bottom: 50px;
    padding-right: 0 !important;
    padding-left: 0 !important;
}
#sticky-box {

    position: sticky !important;
    top: 93px;
    overflow-y: hidden !important;
    padding-right: 0px;
    padding-left: 0px;

}
.pro-detail .right-section {
    float: left;
    width: 100%;
    margin: 0;
    display: block;
}


.pro-detail-new .offers{

    border-bottom-left-radius: 10px!important;
    border-bottom-right-radius: 10px!important;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}


.pro-detail-new .payment{
    margin-bottom: 0px;
}


.pro-detail .offers {
    width: 100%;
    float: left;
    background: #fff;
    /*border-radius: 10px;*/

    min-height: 20px;
    margin: 0 0 20px;
}
.pro-detail .top-block {
    width: 100%;
    float: left;
}
.pro-detail .top-block p {
    font-size: 14px;
    color:#6d6e70;
    float: left;
    line-height: 27px;
}
.pro-detail .right-section .block {
    width: 100%;
    float: left;
    padding: 18px 0;
}
.pro-detail .right-section .block .date-label{
    font-size: 18px;
    font-weight: 600;
    color:#34383b;
}
.pro-detail .right-section .block .custom-select{
    border:1px solid #dbdbdc;
    background-color: transparent;
    height: 40px;
    cursor: pointer;
    font-size: 16px !important;
    font-weight: 600;
    padding-right: 0;
}
.pro-detail .right-section .block .custom-select .MuiInputBase-root{
    font-size: 16px !important;
    font-weight: 500;
}
.pro-detail .right-section .block .form-group{
    margin: 0;
}
/*.pro-detail .block .left-side {*/
/*width: 72%;*/
/*float: left;*/
/*}*/
.pro-detail .block .right-side {
    float: left;
    width: 28%;
    align-self: end;
    text-align: right;
}
.pro-detail .top-block {
    width: 100%;
    float: left;
}
.pro-detail .top-block h4 {
    float: left;
    font-size: 16px;
    margin: 6px 0 12px !important;
    width: 100%;
    color:#34383b;
}
.value-button {
    display: inline-block;
    border: 1px solid #ddd;
    /*margin: -6px 0 0;*/
    width: 30px;
    height: 30px;
    text-align: center;
    vertical-align: middle;
    padding: 0;
    background:transparent;
    user-select: none;
    line-height: 28px;
    border-radius: 50%;
}
input.number {
    text-align: center;
    border: none;
    margin: 0px;
    width: 30px;
    height: 30px;
    font-size: 14px;
}
.pro-detail .right-side p.totaloff {
    font-size: 11px;
    color: #6a8e06;
    background:#e4f0c3;
    border: 1px solid #6a8e06;
    width: 53px;
    height: 27px;
    line-height: 27px;
    float: right;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 5px;
}
p.totaloff {
    color:#6a8e06 !important;
    background:#e4f0c3 !important;
    border: 1px solid#6a8e06;
    width: 53px !important;
    height: 27px;
    line-height: 27px;
    float: right !important;
    text-align: center;
    border-radius: 5px;
}
.pro-detail .offer-data-right .text-line {
    text-decoration: line-through;
    font-size: 14px;
    margin: 0;
    color:#858688;
}
.pro-detail .offer-data-right h4 {
    font-size: 16px;
    margin: 0;
    float: right;
    color:#34383b;
    font-weight: 300 !important;
}
.pro-detail .order-total {
    float: left;
    padding: 10px 0;
    width: 100%;
    margin-left: 0;
}
.pro-detail .order-total p {
    font-size: 18px;
}
.pro-detail .order-total p.small-text {
    font-size: 14px !important;
    margin: 0 !important;
    color:#858688;
}
.pro-detail .order-total h5 {
    font-size: 18px;
}
.pro-detail .promo-block {
    width: 100%;
    float: left;
    padding: 0 0 15px;
}
.pro-detail .buy-now {
    width: 100%;
    float: left;
    text-align: center;
    height: 50px;
    background:#ec1c24;
    color:#fff;
    border-radius: 6px;
    line-height: 50px;
    font-size: 18px;
    font-weight: 600;
}
.pro-detail .sold-out {
    width: 100%;
    float: left;
    text-align: center;
    height: 50px;
    background:transparent;
    border: 1px solid #ec1c24;
    color:#ec1c24;
    border-radius: 6px;
    line-height: 50px;
    font-size: 18px;
    font-weight: 600;
    opacity: 0.25;
    cursor: not-allowed;
    margin: 10px 0;
}
.pro-detail .submit-interest {
    width: 100%;
    float: left;
    text-align: center;
    height: 50px;
    background:#00adee;
    color:#fff;
    border-radius: 6px;
    line-height: 53px;
    font-size: 18px;
    font-weight: 600;
    border: none;
    position: relative;
}
.pro-detail .submit-interest img {
    margin: -2px 0 0;
    display: inline-block;
}
.pro-detail .submit-interest:hover .interest-tooltip{
    display: block;
}
.interest-tooltip {
    padding: 10px;
    position: absolute;
    width: 340px;
    word-wrap: break-word;
    white-space: break-spaces;
    text-align: left;
    box-shadow: 1px 1px 20px #aaa;
    border-radius: 5px;
    background-color: #fff;
    bottom: 40px;
    left: 50%;
    transform: translate(-50%);
    transform-style: preserve-3d;
    z-index: 200;
    display: none;
}
.interest-tooltip p{
    color:#484a4d !important;
    font-size: 14px !important;
    line-height: 24px;
    margin: 0 !important;
    padding: 0 30px 0 0;
}
.pro-detail .submit-interest .interest-tooltip img {
    position: absolute;
    right: 9px;
    top: 11px;
}
.interest-tooltip::after {
    content: "";
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    transform-origin: 50% 50%;
    transform: rotate(45deg) translateX(-68.5%);
    background-color: #fff;
    left: 68.5%;
    bottom: -8px;
    z-index: 400;
}
.promo-box {
    border: 1px dashed #dddddd;
    margin-left: 0;
    position: relative;
    font-weight: normal;
    width: 100%;
    padding: 10px;
    background:#fff;
    border-radius: 10px;
    float: left;
}
.promo-box p {
    font-size: 12px;
    margin: 0;
    opacity: 0.6;
}
.promo-box p span.text-red {
    color: #ec1c24;
}
.pro-detail .productinfo {
    background: #fff;
    float: left;
    border-radius: 10px;
    padding: 0;
    width: 100%;
    box-shadow: 3px 0 30px rgba(0,0,0,0.1);
    margin-bottom: 15px !important;
}
/*.pro-detail .productinfo .image-gallery-slide img{*/
    /*border-radius: 5px;*/
/*}*/
.pro-detail .nav-pills {
    padding-left: 15px !important;
}
.productinfo .tab-pane .row {
    margin: 0 !important;
}
.detailtabs{
    border-bottom: 1px solid #dee2e6;
    box-shadow: none !important;
    background: transparent !important;
}

.detailtabs .MuiTab-textColorPrimary {
    color:#00adee;
    font-size: 16px;
    font-weight: 600;
    min-width: auto;
}


.detailtabs .MuiTab-textColorPrimary.Mui-selected{
    color:#34383b;
}

.detailtabs .MuiTabs-scrollButtons{
    width:25px;
}

.detailtabs .PrivateTabIndicator-colorPrimary-74{
    background-color:#007dc4;
    height: 3px;
    bottom: 0px;
}
.pro-detail .text-block {
    width: 100%;
    float: left;
    padding:40px 0 40px;
    border-bottom: 1px solid #ddd;
}
.pro-detail .text-block p{
    color:#6d6e70;
    font-size: 16px;
    margin: 0 0 10px;
}
.pro-detail .text-block ul{
    margin: 0;
    padding: 0;
    list-style-type: none;
}
.pro-detail .text-block ul li {
    padding: 0 0 15px 36px;
    color:#6d6e70;
    font-size: 16px !important;
}
.pro-detail .text-block ul li:last-child{
    padding-bottom: 0;
}
.pro-detail .text-block ul.include li{
    background-image: url(../img/tick.png);
    background-repeat: no-repeat;
    background-position: left 3px;
    list-style-type: none;
}
.pro-detail .text-block ul.exclude li{
    background-image: url(../img/cross.png);
    background-repeat: no-repeat;
    background-position: left 3px;
    list-style-type: none;
}
.pro-detail .text-block.dot-ul li{
    background-image: url(../img/dot.png);
    background-repeat: no-repeat;
    background-position: left 8px;
    padding: 0 0 13px 30px;
    list-style-type: none;
    width: 100%;
    float: left;
}
.pro-detail ul.where-ul li {
    padding: 0 0 14px 0;
}
.pro-detail ul.where-ul li span{
    font-weight: 700;
    color: #34383b;
}
.pro-detail ul.where-ul li img {
    margin: -5px 5px 0 0;
}
.pro-detail ul.where-ul li button {
    position: relative;
    font-size: 14px;
    background: #fff;
    color:#6d6e70;
    padding: 2px 10px 2px 10px;
    display: inline-block;
    margin-left: 7px;
    border-radius: 3px;
    border: 1px solid #dbdbdc;
}
.pro-detail .text-block ul.where-ul li button img {
    width: 13px;
    margin: 0;
}
.pro-detail .text-block ul.where-ul li button:hover .shedual-drop {
    display: block;
}
.shedual-drop {
    position: absolute;
    width: 441px;
    background: #fff;
    border: 1px solid#dbdbdc;
    border-radius: 5px;
    left: 0;
    top: 100%;
    overflow: hidden;
    z-index: 9;
    display: none;
}
.title {
    background: #007dc4;
    text-align: center;
    color:#fff;
    font-size: 18px;
    height: 40px;
    line-height: 40px;
    margin: 0;
}
.shedual-drop-content{
    padding: 18px;
    width: 100%;
    float: left;
}
.pro-detail .content-tabs .tab-content .text-block .shedual-drop-content p {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    font-weight: 600;
}
.pro-detail .content-tabs .tab-content .text-block .shedual-drop-content h3{
    color:#6d6e70;
    margin:18px 0 9px;
    width: 100%;
    float: left;
    text-align: left;
    font-size: 18px;
}
.days-shedual {
    width: 100%;
    float: left;
    text-align: left;
    border: 1px solid #dbdbdc;
    border-radius: 5px;
}
.days-shedual .col{
    border-right: 1px solid #dbdbdc;
}
.pro-detail .content-tabs .tab-content .text-block .shedual-drop-content .days-shedual .col:last-child{
    border: none;
}
.pro-detail .content-tabs .tab-content .text-block .shedual-drop-content .days-shedual .col h4{
    padding: 10px 15px;
    border-bottom: 1px solid#dbdbdc;
    margin: 0 !important;
    color:#34383b;
    text-transform: uppercase;
}
.see-reviews {
    font-weight: 600;
    color:#00adee;
}
.text-block p{
    color:#6d6e70 !important;
    font-size: 16px !important;
    margin: 0;
    font-family: 'Archivo', sans-serif;
}
.pro-detail .content-tabs .tab-content .text-block h5{
    color:#6d6e70;
    font-weight: 700 !important;
    text-transform: uppercase;
    font-size: 16px;
}
.pro-detail .content-tabs .tab-content .text-block .shedual-drop-content .days-shedual .col p{
    padding: 10px 15px;
    color:#6d6e70;
    font-size: 14px;
}
.map-block {
    width: 100%;
    float: left;
    border-radius: 5px;
    margin: 15px 0 0;
    overflow: hidden;
}

.map-block div{
    position: relative;
}
.less-link {
    color: #00adee;
    background:transparent;
    border: none;
    padding: 0;
    /*font-weight: 600;*/
}
.less-link img{
    margin: -1px 0 0 5px;
}
.product-head {
    width: 100%;
    float: left;
    margin: 0 0 20px;
}
.detail-info {
    width: 100%;
    float: left;
    padding: 20px 0 5px;
    border-width: 1px 0 1px 0;
    border-color:#ddd;
    border-style: solid;
    margin: 20px 0;
}
.detail-info ul{
    padding: 0;
    margin: 0;
    list-style-type: none;
}
.detail-info ul li {
    float: left;
    margin: 0 12px 15px;
    font-size: 16px;
    color:#858688;
}
.pro-detail .breadcum {
    float: left;
    list-style-type: none;
    width: 100%;
    margin: 0;
    padding: 0;
}
.pro-detail .breadcum li {
    float: left;
    padding: 0 3px;
    font-size: 14px;
}
.pro-detail .breadcum li:first-child{
    padding-left: 0;
}
.pro-detail .breadcum li img {
    width: 6px;
}
.pro-detail .image-gallery-thumbnail.active {
    border: 3px solid #34383b;
    border-radius: 5px;
}
.pro-detail h1 {
    font-size: 30px;
    width: 100%;
    float: left;
    margin: 8px 0;
    color: #34383b;
}
.pro-detail p {
    color: #858688;
    font-size: 14px !important;
    width: 100%;
    float: left;
    margin-bottom: 5px;
}
.pro-detail .rating {
    width: 100%;
    float: left;
}
.pro-detail .rating .detail-head-r {
    float: left;
}
.pro-detail .rating p {
    margin: 0px 0 0 13px;
    float: left;
    width: auto;
    color: #00adee;
    line-height: 20px;
    font-weight: 600;
    font-size: 14px;
}
.pro-detail .rating p span{
    color: #858688;
}
.slide-link {
    position: absolute;
    right: 33px;
    top: 20px;
}
.slide-link .heart, .share-icon {
    position: relative;
    top: 0;
    right: 0;
    float: left;
    background-color: transparent;
    border: none;
}
.share-icon {
    top: -2px;
    right: 10px;
    z-index: 9;
}


/*.pro-detail .content-tabs {*/
/*width: 100%;*/
/*float: left;*/
/*}*/
/*.tabs-bottom{*/
/*width: 100%;*/
/*float: left;*/
/*}*/
/*.pro-detail .nav-tabs .nav-item.show .nav-link, .pro-detail .nav-tabs .nav-link.active, .pro-detail .nav-tabs .nav-link:hover {*/
/*color: #34383b;*/
/*background-color:transparent;*/
/*border-color:#007dc4;*/
/*border-width: 0 0 3px;*/
/*border-style: solid;*/
/*}*/
/*.pro-detail .nav-tabs .nav-link{*/
/*color: #00adee;*/
/*background-color:transparent;*/
/*border-color:transparent;*/
/*font-weight: 600 !important;*/
/*border-color:transparent;*/
/*border-width: 0 0 3px;*/
/*border-style: solid;*/
/*text-transform: uppercase;*/
/*padding: .5rem 10px;*/
/*font-size: 15px;*/
/*}*/
/*.pro-detail .detailtab-dis h4 {*/
/*font-size: 21px;*/
/*margin: 0 0 15px;*/
/*font-weight: 700 !important;*/
/*color: #34383b;*/
/*}*/
/*.pro-detail .review-section {*/
/*width: 100%;*/
/*float: left;*/
/*margin: 40px 0 20px;*/
/*}*/
/*.pro-detail .review-section .rating p{*/
/*font-size: 18px;*/
/*}*/
/*.pro-detail .review-section h3{*/
/*font-size: 21px;*/
/*color:#34383b;*/
/*font-weight: 700 !important;*/
/*}*/
/*.pro-detail .rating img {*/
/*float: left;*/
/*}*/
/*.pro-detail .review-verify {*/
/*background: #fff;*/
/*padding: 20px;*/
/*width: 100%;*/
/*float: left;*/
/*border-radius: 10px;*/
/*margin: 30px 0 0;*/
/*}*/
/*.pro-detail .review-verify img {*/
/*float: left;*/
/*margin: -5px 20px 0 0;*/
/*}*/
/*.pro-detail .verifycontent {*/
/*float: left;*/
/*}*/
/*.pro-detail .verifycontent h5 {*/
/*font-size: 16px;*/
/*margin: 0;*/
/*color: #34383b;*/
/*}*/
/*.pro-detail .verifycontent p {*/
/*font-size: 16px;*/
/*margin: 0;*/
/*color: #6d6e70;*/
/*}*/
/*.pro-detail .reviews {*/
/*width: 100%;*/
/*float: left;*/
/*margin: 20px 0 0;*/
/*}*/
/*.pro-detail .block {*/
/*width: 100%;*/
/*float: left;*/
/*}*/
/*.pro-detail .reviews img.user {*/
/*float: left;*/
/*margin: 0 20px 0 0;*/
/*border-radius: 50%;*/
/*}*/

/*.pro-detail .reviews i.user {*/
/*font-size: 30px;*/
/*float: left;*/
/*margin: 0 20px 0 0;*/
/*border-radius: 50%;*/
/*width: 46px;*/
/*height: 46px;*/
/*background:#ddd;*/
/*text-align: center;*/
/*line-height: 44px;*/
/*}*/
/*.pro-detail .reviewcontent {*/
/*float: left;*/
/*width: 90%;*/
/*}*/
/*.pro-detail .reviews .reviewcontent h5 {*/
/*margin: 14px 0;*/
/*font-size: 18px;*/
/*font-weight: 700 !important;*/
/*color:#34383b;*/
/*}*/
/*.pro-detail .reviews .reviewcontent p {*/
/*font-size: 14px !important;*/
/*color:#858688;*/
/*margin-bottom: 9px !important;*/
/*opacity: 0.9 !important;*/
/*}*/
/*.review-section .review-box {*/
/*width: 100%;*/
/*min-height: 440px;*/
/*background: #fff;*/
/*border-radius:15px;*/
/*padding: 20px;*/
/*float: left;*/
/*margin: 20px 0 0;*/
/*}*/
/*.form-control {*/
/*background:#efefef;*/
/*height: 45px;*/
/*border: none;*/
/*}*/
/*.review-section .review-box .form-row .form-group{*/
/*margin-bottom:25px;*/
/*}*/
/*.review-section .review-box label{*/
/*color:#34383b;*/
/*font-size: 18px;*/
/*font-weight: 600;*/
/*margin-bottom: 15px;*/
/*width: 100%;*/
/*}*/
/*.review-section .review-box textarea{*/
/*height: 120px;*/
/*resize: none;*/
/*}*/
/*.pro-detail .wl_share{*/
/*margin: 40px 0;*/
/*}*/
/*.pro-detail .wl_share p {*/
/*padding: 9px 20px 0 0;*/
/*width: auto;*/
/*margin: 0;*/
/*}*/
/*.pro-detail .reviews .reviewcontent p span {*/
/*font-size: 24px;*/
/*color: #34383b;*/
/*font-weight: 600 !important;*/
/*float: left;*/
/*margin-right:0;*/
/*line-height: 24px;*/
/*}*/

/*.pro-detail .reviews .reviewcontent p span.rating-total {*/
/*margin-right: 10px;*/
/*}*/

/*.pro-detail .reviews .reviewcontent p span svg {*/
/*font-size: 14px;*/
/*margin:9px 0 0 0;*/
/*}*/

/*.pro-detail .reviews .reviewcontent p.ratings img {*/
/*margin: 16px 1px 0;*/
/*float: left;*/
/*width: 11px;*/
/*}*/
/*.pro-detail .wl_share a {*/
/*margin: 0 10px 0 0;*/
/*}*/
/*.buy-now-error{*/
/*border: 1px dashed #ec1c24!important;*/
/*}*/
/*.pro-detail .product-head p.rating-p {*/
/*background: transparent;*/
/*padding: 0;*/
/*}*/

/*.pro-detail .product-head p.rating-p a{*/
/*color:#34383b;*/
/*}*/
/*.pro-detail .product-head p.rating-p a i{*/
/*color:#007bff;*/
/*}*/
/*.pro-detail .product-head p {*/
/*font-size: 16px;*/
/*background: url(../img/map.png);*/
/*background-repeat: no-repeat;*/
/*padding-left: 21px;*/
/*margin-bottom: 8px;*/
/*}*/
/*.product-head .for-mobile p.totaloff{*/
/*margin: 10px 0 5px !important;*/
/*}*/
/*.image-gallery-thumbnails {*/
/*overflow: hidden;*/
/*padding: 10px 0;*/
/*}*/


/*.pro-detail .image-gallery-image{*/
/*height: 430px;*/
/*overflow: hidden;*/
/*}*/
/*.image-gallery-slide img {*/
/*width: 100%;*/
/*height: 100%;*/
/*object-fit: cover;*/
/*}*/
/*.pro-detail .image-gallery-thumbnail {*/
/*width: 120px;*/
/*height: 80px;*/
/*overflow: hidden;*/
/*}*/
/*.pro-detail .image-gallery-thumbnail img{*/
/*height: 100%;*/
/*object-fit: cover;*/
/*}*/
/*.pro-detail .image-gallery-thumbnail .image-gallery-thumbnail-inner {*/
/*position: relative;*/
/*width: 100%;*/
/*height:100%;*/
/*overflow: hidden;*/
/*}*/
/*.image-gallery-play-button, .image-gallery-fullscreen-button {*/
/*display: none;*/
/*}*/
/*.for-web{*/
/*display: flex;*/
/*}*/
/*.for-mobile{*/
/*display: none;*/
/*}*/

/*.mobile-price{*/
/*display: none;*/
/*}*/
/*@media (max-width:1024px) {*/
/*.pro-detail .nav-tabs .nav-link{*/
/*width: 25%;*/
/*text-align: center;*/
/*}*/
/*.pro-detail .nav-tabs .nav-item.show .nav-link,*/
/*.pro-detail .nav-tabs .nav-link.active,*/
/*.pro-detail .nav-tabs .nav-link:hover{*/
/*background: #fff;*/
/*}*/
/*.pro-detail .text-block{*/
/*padding: 30px 0;*/
/*}*/
/*.pro-detail .verifycontent {*/
/*width: 85%;*/
/*}*/
/*}*/


/*.detailtabs .MuiTabs-scrollButtons{width: 0;}*/

/*.pro-detail .MuiBox-root{padding: 0px;}*/


/*@media (max-width:990px) {*/



/*.detailtabs .MuiTabs-scrollButtons{width: 25px;}*/



/*.for-web{*/
/*display: none;*/
/*}*/
/*.for-mobile{*/
/*display: flex;*/
/*}*/
/*.for-mobile .col-sm-9.col-xs-9 {*/
/*width: 70%;*/
/*}*/
/*.for-mobile .col-sm-3.col-xs-3 {*/
/*width: 30%;*/
/*}*/
/*.product-head{*/
/*margin-bottom:15px;*/
/*}*/
/*.product-head p.totaloff {*/
/*color:#6a8e06 !important;*/
/*background:#e4f0c3 !important;*/
/*border: 1px solid#6a8e06;*/
/*width: 53px !important;*/
/*height: 27px;*/
/*line-height: 27px;*/
/*float: right !important;*/
/*text-align: center;*/
/*border-radius: 5px;*/
/*padding: 0 !important;*/
/*font-size: 12px !important;*/
/*margin: 0 0 5px !important;*/
/*}*/
/*.for-mobile .price {*/
/*color:#34383b;*/
/*text-align: right;*/
/*font-weight: 600;*/
/*font-size: 16px;*/
/*width: 100%;*/
/*float: right;*/
/*}*/
/*.or-price, .or-price .line-through{*/
/*font-size: 14px;*/
/*width: 100%;*/
/*float: right;*/
/*}*/
/*.pro-detail h1 {*/
/*font-size: 18px;*/
/*margin: 10px 0;*/
/*}*/
/*.pro-detail .product-head p{*/
/*font-size: 16px;*/
/*background: url(../img/map.png);*/
/*background-repeat: no-repeat;*/
/*padding-left: 21px;*/
/*margin-bottom:0;*/
/*}*/
/*.pro-detail .nav-tabs .nav-link {*/
/*width: 100%;*/
/*text-align: center;*/
/*}*/
/*.pro-detail .text-block li {*/
/*padding: 0 0 20px 36px;*/
/*}*/
/*.pro-detail .text-block ul {*/
/*margin: 20px 0 0;*/
/*}*/
/*.pro-detail .text-block ul.where-ul li {*/
/*padding: 0 0 10px 0;*/
/*}*/
/*.pro-detail .text-block ul.where-ul li button {*/
/*margin-left: 24px;*/
/*}*/

/*.pro-detail .review-section{*/
/*margin-top: 0;*/
/*}*/

/*.review-section .review-box{*/
/*padding: 20px 0;*/
/*}*/

/*.pro-detail .wl_share p{*/
/*width: 100%;*/
/*}*/

/*.pro-detail .wl_share {*/
/*margin: 20px 0 0;*/
/*}*/
/*.pro-detail .text-block:last-child{*/
/*border-bottom: 0px;*/
/*}*/
/*.order-total .col-md-7 {*/
/*width: 70%;*/
/*}*/
/*.order-total .col-md-5 {*/
/*width: 30%;*/
/*}*/
/*#rightCol{*/
/*margin-bottom: 0;*/
/*}*/
/*.productinfo .tab-content{*/
/*margin-bottom: 5px;*/
/*}*/

/*.image-gallery-slides{*/
/*border-radius: 5px;*/
/*}*/
/*.pro-detail .image-gallery-image {*/
/*height:230px;*/
/*overflow: hidden;*/
/*}*/
/*.pro-detail .image-gallery-thumbnail {*/
/*width: 90px;*/
/*height: 60px;*/
/*overflow: hidden;*/
/*}*/
/*.pro-detail .image-gallery-thumbnail img{*/
/*height: 60px;*/
/*}*/
/*.detailtabs .MuiSvgIcon-fontSizeSmall{*/
/*font-size: 22px;*/
/*}*/

/*#rightColrightCol{*/
/*display: none   ;*/
/*}*/

/*.primaryguestform .order-total {*/
/*display: none;*/
/*}*/

/*.procedd-block.border-none.web-price {*/
/*display: none;*/
/*}*/

/*.mobile-price {*/
/*position: fixed;*/
/*width: 100%;*/
/*bottom: 0;*/
/*left: 0;*/
/*z-index: 999;*/
/*display: block;*/
/*}*/

/*.Show.Option {*/
/*display: flex;*/
/*justify-content: space-between;*/
/*background:#fff;*/
/*align-items: center;*/
/*padding: 8px 10px;*/
/*border-radius: 0;*/
/*}*/

/*.Show.Option .red-btn{*/
/*height: 50px;*/
/*margin: 10px 0 0;*/
/*}*/

/*.Show.Option h1{*/
/*font-size: 22px;*/
/*margin: 0;*/
/*display: none;*/
/*}*/

/*.options-show {*/
/*width: 100%;*/
/*text-align: center;*/
/*height: 50px;*/
/*background: #ec1c24;*/
/*color:#fff;*/
/*border-radius: 6px;*/
/*line-height: 50px;*/
/*font-size: 18px;*/
/*font-weight: 600;*/
/*border: none;*/
/*}*/

/*.mobile-pricing-view {*/
/*position: fixed;*/
/*width: 100%;*/
/*height: 100%;*/
/*background: #fff;*/
/*z-index: 9999;*/
/*left: 0;*/
/*top: 0;*/
/*overflow:auto;*/

/*}*/

/*.mobile-pricing-view  .custom-select {*/
/*border: 1px solid #dbdbdc;*/
/*background-color:transparent;*/
/*height: 40px;*/
/*cursor: pointer;*/
/*font-size: 16px !important;*/
/*font-weight: 600;*/
/*padding-right: 0;*/
/*}*/

/*.cross-btn{*/
/*border: none;*/
/*background:*/
/*transparent;*/
/*font-size: 22px;*/
/*float: right;*/
/*}*/

/*.pro-detail .block .right-side{*/
/*width: 24%;*/
/*}*/

/*.pro-detail .block{*/
/*padding:20px 0;*/
/*}*/

/*p.bought-total {*/
/*font-size: 14px;*/
/*color:#6d6e70;*/
/*float: left;*/
/*line-height: 27px;*/
/*}*/

/*}*/

/*.detail-info>.row>.col-lg-12{padding-left: 0;}*/

/*.shedual-drop-content h4{font-size: 16px!important;text-align: center!important; width: 100%!important;margin:10px auto;*/
/*padding: 0!important;text-transform: uppercase!important;}*/
/*.shedual-drop-content h3{font-size: 18px;color: #6d6e70; text-align: center; margin:5px;}*/
/*.shedual-drop-content p{color: #6d6e70; text-align: left;}*/
/*.shedual-drop-content h4{ margin: 5px auto!important;}*/

/*input:focus, textarea:focus, select:focus, button:focus, a:focus, div:focus{*/
/*outline: none!important;*/
/*}*/

/*.shedual-drop-content div h4{border-bottom: 1px solid #dbdbdc;padding: 0px!important;width: 100%!important;padding-bottom:8px!important;}*/
/*.shedual-drop-content .col{padding: 0; text-align:center; }*/
/*.shedual-drop-content p{text-align: center;}*/

/*.pro-detail.padtop0 {*/
/*padding: 0;*/
/*}*/


/*.pro-detail .block .left-side {*/
/*width: 55%;*/
/*float: left;*/
/*}*/





/* weekend detail page*/


.pro-detail {
    width: 100%;
    position: relative;
    background: #f5f5f5;
    float: left;
    padding: 15px 0;
}

#rightCol {
    margin-bottom: 50px;
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.pro-detail.new-block #sticky-box {
    position: sticky !important;
    top: 93px;
    overflow-y: hidden !important;

}

.pro-detail.new-block .right-section {
    float: left;
    width: 100%;
    margin: 0;
    display: block;
}

.pro-detail.new-block .offers {
    width: 100%;
    float: left;
    background: #fff;
    /*border-radius: 10px;*/
    min-height: 20px;
    margin: 0 0 20px;
}




.pro-detail .top-block {
    width: 100%;
    float: left;
}

.pro-detail .top-block p {
    font-size: 14px;
    color: #6d6e70;
    float: left;
    line-height: 27px;
}

.pro-detail .right-section .block {
    width: 100%;
    float: left;
    padding: 18px 0;
}

.pro-detail .right-section .block .date-label {
    font-size: 18px;
    font-weight: 600;
    color: #34383b;
}

.pro-detail .right-section .block .custom-select {
    border: 1px solid #dbdbdc;
    background-color: transparent;
    height: 40px;
    cursor: pointer;
    font-size: 16px !important;
    font-weight: 600;
    padding-right: 0;
}

.pro-detail .right-section .block .custom-select .MuiInputBase-root {
    font-size: 16px !important;
    font-weight: 500;
}

.pro-detail .right-section .block .form-group {
    margin: 0;
}

.pro-detail.new-block.padtop0 .block .left-side {
    width: 100%;
    float: left;
}
.margin-top-10{
    margin-top:15px;
}
.pro-detail.new-block.padtop0 .block .right-side {
    float: right;
    width: 28%;
    align-self: end;
    text-align: right;
}

.pro-detail.new-block  .top-block {
    width: 100%;
    float: left;
}

.top-block.new h5.pull-right {
    color: #34383b;
    font-size: 16px;
    display: inline-block;
    font-weight:bold !Important;
}

.pro-detail.new-block  h4.pull-left {
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 150px !important;
    white-space: nowrap;
    margin: 0 !important;
}

.pro-detail.new-block  .top-block h4 {
    float: left;
    font-size: 16px;
    margin: 6px 0 15px;
    width: 100%;
    color: #34383b;
}

.new-bottom-padding{
    padding-bottom:80px !Important;
}

.pro-detail.new-block .value-button {
    display: inline-block;
    border: 1px solid #ddd;
    /*margin: -6px 0 0;*/
    width: 30px;
    height: 30px;
    text-align: center;
    vertical-align: middle;
    padding: 0;
    background: transparent;
    user-select: none;
    line-height: 26px;
    border-radius: 50%;
}


.pro-detail .right-side p.totaloff {
    font-size: 11px;
    color: #6a8e06;
    background: #e4f0c3;
    border: 1px solid #6a8e06;
    width: 53px;
    height: 27px;
    line-height: 27px;
    float: right;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 5px;
}

p.totaloff {
    color: #6a8e06 !important;
    background: #e4f0c3 !important;
    border: 1px solid#6a8e06;
    width: 53px !important;
    height: 27px;
    line-height: 27px;
    float: right !important;
    text-align: center;
    border-radius: 5px;
}

.pro-detail .offer-data-right .text-line {
    text-decoration: line-through;
    font-size: 14px;
    margin: 0;
    color: #858688;
}

.pro-detail .offer-data-right h4 {
    font-size: 16px;
    margin: 0;
    float: right;
    color: #34383b;
    font-weight: 700 !important;
}

.pro-detail.new-block .order-total {
    float: left;
    padding: 10px 0;
    width: 100%;
    margin-left: 0;
}

.pro-detail .order-total p {
    font-size: 18px;
}

.pro-detail .order-total p.small-text {
    font-size: 14px !important;
    margin: 0 !important;
    color: #858688;
}

.pro-detail .order-total h5 {
    font-size: 18px;
}

.pro-detail .promo-block {
    width: 100%;
    float: left;
    padding: 0 0 15px;
}

.pro-detail .buy-now {
    width: 100%;
    float: left;
    text-align: center;
    height: 50px;
    background: #ec1c24;
    color: #fff;
    border-radius: 6px;
    line-height: 50px;
    font-size: 18px;
    font-weight: 600;
}

.pro-detail .sold-out {
    width: 100%;
    float: left;
    text-align: center;
    height: 50px;
    background: transparent;
    border: 1px solid #ec1c24;
    color: #ec1c24;
    border-radius: 6px;
    line-height: 50px;
    font-size: 18px;
    font-weight: 600;
    opacity: 0.25;
    cursor: not-allowed;
    margin: 10px 0;
}

.pro-detail .submit-interest {
    width: 100%;
    float: left;
    text-align: center;
    height: 50px;
    background: #00adee;
    color: #fff;
    border-radius: 6px;
    line-height: 53px;
    font-size: 18px;
    font-weight: 600;
    border: none;
    position: relative;
}

.pro-detail .submit-interest img {
    margin: -2px 0 0;
    display: inline-block;
}

.pro-detail .submit-interest:hover .interest-tooltip {
    display: block;
}

.interest-tooltip {
    padding: 10px;
    position: absolute;
    width: 340px;
    word-wrap: break-word;
    white-space: break-spaces;
    text-align: left;
    box-shadow: 1px 1px 20px #aaa;
    border-radius: 5px;
    background-color: #fff;
    bottom: 40px;
    left: 50%;
    transform: translate(-50%);
    transform-style: preserve-3d;
    z-index: 200;
    display: none;
}

.interest-tooltip p {
    color: #484a4d !important;
    font-size: 14px !important;
    line-height: 24px;
    margin: 0 !important;
    padding: 0 30px 0 0;
}

.pro-detail .submit-interest .interest-tooltip img {
    position: absolute;
    right: 9px;
    top: 11px;
}

.interest-tooltip::after {
    content: "";
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    transform-origin: 50% 50%;
    transform: rotate(45deg) translateX(-68.5%);
    background-color: #fff;
    left: 68.5%;
    bottom: -8px;
    z-index: 400;
}

.promo-box {
    border: 1px dashed #dddddd;
    margin-left: 0;
    position: relative;
    font-weight: normal;
    width: 100%;
    padding: 10px;
    background: #fff;
    border-radius: 10px;
    float: left;
}

.promo-box p {
    font-size: 12px;
    margin: 0;
    opacity: 0.6;
}

.promo-box p span.text-red {
    color: #ec1c24;
}

.pro-detail .productinfo {
    background: #fff;
    float: left;
    border-radius: 10px;
    padding: 0;
    width: 100%;
    box-shadow: 3px 0 30px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px !important;
}

.pro-detail .productinfo .image-gallery-slide img {
    /*border-radius: 5px;*/
}

.pro-detail .nav-pills {
    padding-left: 15px !important;
}

.productinfo .tab-pane .row {
    margin: 0 !important;
}

.detailtabs {
    border-bottom: 1px solid #dee2e6;
    box-shadow: none !important;
    background: transparent !important;
}

.detailtabs .MuiTab-textColorPrimary {
    color: #00adee;
    font-size: 16px;
    font-weight: 600;
    min-width: auto;
}

.detailtabs .MuiTab-textColorPrimary.Mui-selected {
    color: #34383b;
}

.detailtabs .MuiTabs-scrollButtons {
    width: 25px;
}

.detailtabs .PrivateTabIndicator-colorPrimary-74 {
    background-color: #007dc4;
    height: 3px;
    bottom: 0px;
}

.pro-detail .text-block {
    width: 100%;
    float: left;
    padding: 40px 0 40px;
    border-bottom: 1px solid #ddd;
}

.pro-detail .text-block p {
    color: #6d6e70;
    font-size: 16px;
    margin: 0 0 10px;
}

.pro-detail .text-block ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.pro-detail .text-block ul li {
    padding: 0 0 15px 36px;
    color: #6d6e70;
    font-size: 16px !important;
}

.pro-detail .text-block ul li:last-child {
    padding-bottom: 0;
}

.pro-detail .text-block ul.include li {
    background-image: url(../img/tick.png);
    background-repeat: no-repeat;
    background-position: left 3px;
    list-style-type: none;
}

.pro-detail .text-block ul.exclude li {
    background-image: url(../img/cross.png);
    background-repeat: no-repeat;
    background-position: left 3px;
    list-style-type: none;
}

.pro-detail .text-block.dot-ul li {
    background-image: url(../img/dot.png);
    background-repeat: no-repeat;
    background-position: left 8px;
    padding: 0 0 13px 30px;
    list-style-type: none;
    width: 100%;
    float: left;
}

.pro-detail ul.where-ul li {
    padding: 0 0 13px 0;
}

.pro-detail ul.where-ul li span {
    font-weight: 700;
    color: #34383b;
}

.pro-detail ul.where-ul li img {
    margin: -5px 5px 0 0;
}

.pro-detail ul.where-ul li button {
    position: relative;
    font-size: 14px;
    background: #fff;
    color: #6d6e70;
    padding: 2px 10px 2px 10px;
    display: inline-block;
    margin-left: 7px;
    border-radius: 3px;
    border: 1px solid #dbdbdc;
}

.pro-detail .text-block ul.where-ul li button img {
    width: 13px;
    margin: 0;
}

.pro-detail .text-block ul.where-ul li button:hover .shedual-drop {
    display: block;
}

.shedual-drop {
    position: absolute;
    width: 441px;
    background: #fff;
    border: 1px solid#dbdbdc;
    border-radius: 5px;
    left: 0;
    top: 100%;
    overflow: hidden;
    z-index: 9;
    display: none;
}

.title {
    background: #007dc4;
    text-align: center;
    color: #fff;
    font-size: 18px;
    height: 40px;
    line-height: 40px;
    margin: 0;
}

.shedual-drop-content {
    padding: 18px;
    width: 100%;
    float: left;
}

.pro-detail .content-tabs .tab-content .text-block .shedual-drop-content p {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    font-weight: 600;
}

.pro-detail .content-tabs .tab-content .text-block .shedual-drop-content h3 {
    color: #6d6e70;
    margin: 18px 0 9px;
    width: 100%;
    float: left;
    text-align: left;
    font-size: 18px;
}

.days-shedual {
    width: 100%;
    float: left;
    text-align: left;
    border: 1px solid #dbdbdc;
    border-radius: 5px;
}

.days-shedual .col {
    border-right: 1px solid #dbdbdc;
}

.pro-detail .content-tabs .tab-content .text-block .shedual-drop-content .days-shedual .col:last-child {
    border: none;
}

.pro-detail .content-tabs .tab-content .text-block .shedual-drop-content .days-shedual .col h4 {
    padding: 10px 15px;
    border-bottom: 1px solid#dbdbdc;
    margin: 0 !important;
    color: #34383b;
    text-transform: uppercase;
}

.see-reviews {
    font-weight: 600;
    color: #00adee;
}

.text-block p {
    color: #6d6e70 !important;
    font-size: 16px !important;
    margin: 0;
    font-family: 'Archivo', sans-serif;
}

.pro-detail .content-tabs .tab-content .text-block h5 {
    color: #6d6e70;
    font-weight: 700 !important;
    text-transform: uppercase;
    font-size: 16px;
}

.pro-detail .content-tabs .tab-content .text-block .shedual-drop-content .days-shedual .col p {
    padding: 10px 15px;
    color: #6d6e70;
    font-size: 14px;
}

.map-block {
    width: 100%;
    float: left;
    border-radius: 5px;
    margin: 15px 0 0;
    overflow: hidden;
}

.map-block div {
    position: relative;
}

.less-link {
    color: #00adee;
    background: transparent;
    border: none;
    padding: 0;
    /*font-weight: 600;*/
}

.less-link img {
    margin: -1px 0 0 5px;
}

.product-head {
    width: 100%;
    float: left;
    margin: 0 0 20px;
}

.detail-info {
    width: 100%;
    float: left;
    padding: 20px 0 5px;
    border-width: 1px 0 1px 0;
    border-color: #ddd;
    border-style: solid;
    margin: 20px 0;
}

.detail-info ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.detail-info ul li {
    float: left;
    margin: 0 12px 15px;
    font-size: 16px;
    color: #858688;
}

.pro-detail .breadcum {
    float: left;
    list-style-type: none;
    width: 100%;
    margin: 0;
    padding: 0;
}

p.p1 {
    color: #007dc4 !important;
    font-size: 12px !important;
    font-weight: bold !important;
    text-transform: uppercase;
    margin: 0 !important;
}
.pro-detail .breadcum li {
    float: left;
    padding: 0 3px;
    font-size: 14px;
}

p.p2 {
    color: #858688 !important;
    font-size: 12px !important;
    font-weight: bold !important;
    text-transform: uppercase;
    margin: 0 0 5px 0 !important;
}

span.span1 {
    color: #34383b;
    font-size: 14px;
    margin-left: 10px;
    text-transform: uppercase;
}

.pro-detail .breadcum li:first-child {
    padding-left: 0;
}

.pro-detail .breadcum li img {
    width: 6px;
}

.pro-detail .image-gallery-thumbnail.active {
    border: 3px solid #34383b;
    border-radius: 5px;
}

.pro-detail h1 {
    font-size: 30px;
    width: 100%;
    float: left;
    margin: 8px 0;
    color: #34383b;
}

.pro-detail.new-block p {
    color: #858688;
    font-size: 18px;
    width: 100%;
    float: left;
    margin-bottom: 20px;
}

.pro-detail .rating {
    width: 100%;
    float: left;
}

.pro-detail .rating .detail-head-r {
    float: left;
}

.pro-detail .rating p {
    margin: 0px 0 0 13px;
    float: left;
    width: auto;
    color: #00adee;
    line-height: 20px;
    font-weight: 600;
    font-size: 14px;
}

.pro-detail .rating p span {
    color: #858688;
}

.slide-link {
    position: absolute;
    right: 33px;
    top: 20px;
}

.slide-link .heart,
.share-icon {
    position: relative;
    top: 0;
    right: 0;
    float: left;
    background-color: transparent;
    border: none;
}

.share-icon {
    top: -2px;
    right: 10px;
    z-index: 9;
}

.pro-detail .content-tabs {
    width: 100%;
    float: left;
}

.tabs-bottom {
    width: 100%;
    float: left;
}

.pro-detail .nav-tabs .nav-item.show .nav-link,
.pro-detail .nav-tabs .nav-link.active,
.pro-detail .nav-tabs .nav-link:hover {
    color: #34383b;
    background-color: transparent;
    border-color: #007dc4;
    border-width: 0 0 3px;
    border-style: solid;
}

.pro-detail .nav-tabs .nav-link {
    color: #00adee;
    background-color: transparent;
    border-color: transparent;
    font-weight: 600 !important;
    border-color: transparent;
    border-width: 0 0 3px;
    border-style: solid;
    text-transform: uppercase;
    padding: .5rem 10px;
    font-size: 16px;
}

.pro-detail .detailtab-dis h4 {
    font-size: 21px;
    margin: 0 0 15px;
    font-weight: 700 !important;
    color: #34383b;
}

.pro-detail .review-section {
    width: 100%;
    float: left;
    margin: 40px 0 20px;
}

.pro-detail .review-section .rating p {
    font-size: 18px;
}

.pro-detail .review-section h3 {
    font-size: 21px;
    color: #34383b;
    font-weight: 700 !important;
}

.pro-detail .rating img {
    float: left;
}

.pro-detail .review-verify {
    background: #fff;
    padding: 20px;
    width: 100%;
    float: left;
    border-radius: 10px;
    margin: 30px 0 0;
}

.pro-detail .review-verify img {
    float: left;
    margin: -5px 20px 0 0;
}

.pro-detail .verifycontent {
    float: left;
}

.pro-detail .verifycontent h5 {
    font-size: 16px;
    margin: 0;
    color: #34383b;
}

.pro-detail .verifycontent p {
    font-size: 16px;
    margin: 0;
    color: #6d6e70;
}

.pro-detail .reviews {
    width: 100%;
    float: left;
    margin: 20px 0 0;
}

.pro-detail .block {
    width: 100%;
    float: left;
}

.pro-detail .reviews img.user {
    float: left;
    margin: 0 20px 0 0;
    border-radius: 50%;
}

.pro-detail .reviews i.user {
    font-size: 30px;
    float: left;
    margin: 0 20px 0 0;
    border-radius: 50%;
    width: 46px;
    height: 46px;
    background: #ddd;
    text-align: center;
    line-height: 44px;
}

.pro-detail .reviewcontent {
    float: left;
    width: 90%;
}

.pro-detail .reviews .reviewcontent h5 {
    margin: 14px 0;
    font-size: 18px;
    font-weight: 700 !important;
    color: #34383b;
}

.pro-detail .reviews .reviewcontent p {
    font-size: 14px !important;
    color: #858688;
    margin-bottom: 9px !important;
    opacity: 0.9 !important;
}

.review-section .review-box {
    width: 100%;
    min-height: 440px;
    background: #fff;
    border-radius: 15px;
    padding: 20px;
    float: left;
    margin: 20px 0 0;
}

.form-control {
    background: #efefef;
    height: 45px;
    border: none;
}

.review-section .review-box .form-row .form-group {
    margin-bottom: 25px;
}

.review-section .review-box label {
    color: #34383b;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
    width: 100%;
}

.review-section .review-box textarea {
    height: 120px;
    resize: none;
}

.pro-detail .wl_share {
    margin: 40px 0;
}

.pro-detail .wl_share p {
    padding: 9px 20px 0 0;
    width: auto;
    margin: 0;
}

.pro-detail .reviews .reviewcontent p span {
    font-size: 24px;
    color: #34383b;
    font-weight: 600 !important;
    float: left;
    margin-right: 0;
    line-height: 24px;
}

.pro-detail .reviews .reviewcontent p span.rating-total {
    margin-right: 10px;
}

.pro-detail .reviews .reviewcontent p span svg {
    font-size: 14px;
    margin: 9px 0 0 0;
}

.pro-detail .reviews .reviewcontent p.ratings img {
    margin: 16px 1px 0;
    float: left;
    width: 11px;
}

.pro-detail .wl_share a {
    margin: 0 10px 0 0;
}

.buy-now-error {
    border: 1px dashed #ec1c24!important;
}

.pro-detail .product-head p.rating-p {
    background: transparent;
    padding: 0;
}

.pro-detail .product-head p.rating-p a {
    color: #34383b;
}

.pro-detail .product-head p.rating-p a i {
    color: #007bff;
}

.pro-detail .product-head p {
    font-size: 16px;
    background: url(../img/map.png);
    background-repeat: no-repeat;
    padding-left: 21px;
    margin-bottom: 8px;
}

.product-head .for-mobile p.totaloff {
    margin: 10px 0 5px !important;
}

.image-gallery-thumbnails {
    overflow: hidden;
    padding: 10px 0;
}

.pro-detail .image-gallery-image {
    height: 430px;
    overflow: hidden;
}

.image-gallery-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.pro-detail .image-gallery-thumbnail {
    width: 120px;
    height: 80px;
    overflow: hidden;
}

.pro-detail .image-gallery-thumbnail img {
    height: 100%;
    object-fit: cover;
}

.pro-detail .image-gallery-thumbnail .image-gallery-thumbnail-inner {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.image-gallery-play-button,
.image-gallery-fullscreen-button {
    display: none;
}

.for-web {
    display: flex;
}

.for-mobile {
    display: none;
}

.mobile-price {
    display: none;
}

.offers-new {
    /*border: 1px solid rgb(245, 245, 245);*/
}

.pro-detail.new-block .offers-new .block.border-bottom {
    width: 100%;
    float: left;
    padding: 15px;
}

.pro-detail.new-block .order-total.new {
    padding: 10px 20px 15px 10px;
}

.order-total.new p {
    font-size: 14px;
    color: #858688;
}

.order-total.new h5 {
    font-size: 14px;
    color: #34383b;
}

.order-total.new .pro-detail .order-total p span {
    color: #007dc4;
}

p.mb-0.total_pay {
    color: #34383b;
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
}

h5.total_sum {
    font-size: 16px !important;
    color: #34383b !Important;
    font-weight:bold !Important;
}

.promo-block.new {
    padding: 10px 10px 20px 10px;
}

.pro-detail.new-block .promo-block.new {
    padding: 0px 10px 20px 10px;
}

.pro-detail.new-block .promo-block.new .buy-now {
    text-transform: capitalize;
    width: 100%;
    float: left;
    text-align: center;
    height: 40px;
    background: #ec1c24;
    color: #fff;
    border-radius: 6px;
    line-height: 42px;
    font-size: 18px;
    font-weight: 600;
    padding: 0px 0;
    display: block;
    border: none;
    text-transform: uppercase;
}

.pro-detail.new-block .top-block h4 {
    float: left;
    font-size: 16px;
    margin: 0 0 5px!important;
    width: 100%;
    color: #34383b;
}

p.mb-0.total_pay span {
    color: #858688;
    display: block;
    font-size: 12px;
}

/*.room_list_item.internal {*/
    /*margin: 0 !important;*/
/*}*/

.experiance_block_main.new .room_list_l_text h4 {
    font-size: 18px;
    font-family: 'Archivo', sans-serif;
    font-weight: bold;
    color: #34383b;
    font-weight: bold !important;
    padding: 15px 0px 0px 0px;
    margin-bottom: 0;
}




.room_list_item.internal .room_list_l_text ul li a img {
    width: 12px;
    display: inline-block;
    margin-right: 5px;
}

.room_list_item.internal .room_list_l_text ul li a {
    font-size: 14px;
    color: #858688;
    font-family: 'Archivo', sans-serif;
    letter-spacing: .225px;
    padding-right: 24px;
    position: relative;
    -webkit-transition: all 300ms linear 0s;
    -o-transition: all 300ms linear 0s;
    transition: all 300ms linear 0s;
}

.room_list_item.internal .room_img img {
    width: 100%;
    min-height: auto !Important;
    max-height: auto !Important;
}

.experiance_block_main.new .rating_block span i {
    color: #00adee;
}

.margin-top-20 {
    margin-top: 20px;
}




.experiance_block_main.new{
    border: 2px dotted #00adee;
    border-radius: 10px;
    padding: 20px;
}

.experiance_block_main.new .room_img {
    margin-left: 0px;
    display: block;
    position: relative;
}

.experiance_block_main  .exp_title {
    color: #34383b;
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: 600 !important;
}

.experiance_block_main.new a.pull-right.view_btn {
    background: #00adee;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    padding: 5px 18px;
    position: relative;
    right: 15px;
}

.offer_prize h4 {
    padding: 0 !Important;
}

.experiance_block_main.new .rating_block {
    margin-bottom: 10px;
    font-weight: normal;
}

.offer_prize h4 span {
    text-decoration: line-through;
    color: #858688;
    font-size: 14px;
}

section.news_letter_block {
    background: #00adee;
    padding: 50px 0;
    margin-bottom: 40px;
}

section.news_letter_block h5 {
    font-size: 30px;
    color: #fff;
}

section.news_letter_block p {
    font-size: 16px;
    color: #fff;
    margin: 0;
}

section.news_letter_block input.form-control::placeholder {
    color: #fff;
}

section.news_letter_block input.form-control {
    background: transparent;
    border: 0px;
    outline: navajowhite;
    color: #fff;
    border-bottom: 1px solid #fff;
    border-radius: 0px !important;
    font-size: 12px;
    text-align: center;
}

section.news_letter_block input[type="submit"] {
    background: #fff;
    border-color: #fff;
    display: block;
    width: 100%;
    border: 1px solid #fff;
    margin-top: 12px;
    color: #ec1c24;
    font-size: 16px;
    text-transform: capitalize;
    border-radius: 4px;
    padding: 5px 0;
}

ul.services_ul li {
    display: block !Important;
    font-size: 14px;
    margin: 2px 0;
    color: #858688;
}

ul.services_ul li img {
    width: 12px;
    margin-right: 10px;
}

ul.services_ul {
    border-top: 1px solid #ccc;
    padding-top: 5px;
}

.modal-body {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;
    /*background: #fff;*/
    border-radius: 4px;
}

.info_block p {
    color: #6d6e70;
    font-size: 14px;
}

.info_block h5 {
    color: #34383b;
    font-size: 18px;
}

button.close {
    /*position: absolute;*/
    /*right: 10px !Important;*/
    /*top: 10px !Important;*/
    /*z-index: 99;*/
    /*width: 30px;*/
    /*height: 30px;*/
    /*color: #fff;*/
    /*opacity: 1 !Important;*/
    font-size: 32px;
}
.custom-modal-popup button.close {
    color: #000;
    opacity: .5 !important;
}

ul.key_highilits {
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 0;
}

ul.key_highilits li {
    display: inline-block;
    width: 33.33%;
    font-size: 14px;
    color: #6d6e70;
    margin: 5px 0 10px 0;
}

ul.key_highilits li img {
    margin-right: 20px;
    width: 15px;
}

a.read-full {
    background: #00adee;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    padding: 8px 18px;
    display: inline-block;
    margin-top: 12px;
}
a.read-full:hover{
    color:#fff;
}

@media (max-width:1024px) {
    .pro-detail .nav-tabs .nav-link {
        width: 25%;
        text-align: center;
    }
    .pro-detail .nav-tabs .nav-item.show .nav-link,
    .pro-detail .nav-tabs .nav-link.active,
    .pro-detail .nav-tabs .nav-link:hover {
        background: #fff;
    }
    .pro-detail .text-block {
        padding: 30px 0;
    }
    .pro-detail .verifycontent {
        width: 85%;
    }

    .pro-detail.new-block h4.pull-left {
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 120px !important;
        white-space: nowrap;
        margin: 0 !important;
    }
}

@media (max-width:990px) {
    .section-read-more{margin-top: -80px;}

    .for-web {
        display: none;
    }
    .for-mobile {
        display: flex;
    }
    .for-mobile .col-sm-9.col-xs-9 {
        width: 70%;
    }
    .for-mobile .col-sm-3.col-xs-3 {
        width: 30%;
    }
    .product-head {
        margin-bottom: 15px;
    }
    .product-head p.totaloff {
        color: #6a8e06 !important;
        background: #e4f0c3 !important;
        border: 1px solid#6a8e06;
        width: 53px !important;
        height: 27px;
        line-height: 27px;
        float: right !important;
        text-align: center;
        border-radius: 5px;
        padding: 0 !important;
        font-size: 12px !important;
        margin: 0 0 5px !important;
    }
    .for-mobile .price {
        color: #34383b;
        text-align: right;
        font-weight: 600;
        font-size: 16px;
        width: 100%;
        float: right;
    }
    .or-price,
    .or-price .line-through {
        font-size: 14px;
        width: 100%;
        float: right;
    }
    .pro-detail h1 {
        font-size: 18px;
        margin: 10px 0;
    }
    .pro-detail .product-head p {
        font-size: 16px;
        background: url(../img/map.png);
        background-repeat: no-repeat;
        padding-left: 21px;
        margin-bottom: 0;
    }
    .pro-detail .nav-tabs .nav-link {
        width: 100%;
        text-align: center;
    }
    .pro-detail .text-block li {
        padding: 0 0 20px 36px;
    }
    .pro-detail .text-block ul {
        margin: 20px 0 0;
    }
    .pro-detail .text-block ul.where-ul li {
        padding: 0 0 10px 0;
    }
    .pro-detail .text-block ul.where-ul li button {
        margin-left: 24px;
    }
    .pro-detail .review-section {
        margin-top: 0;
    }
    .review-section .review-box {
        padding: 20px 0;
    }
    .pro-detail .wl_share p {
        width: 100%;
    }
    .pro-detail .wl_share {
        margin: 20px 0 0;
    }
    .pro-detail .text-block:last-child {
        border-bottom: 0px;
    }
    .order-total .col-md-7 {
        width: 70%;
    }
    .order-total .col-md-5 {
        width: 30%;
    }
    #rightCol {
        margin-bottom: 0;
    }
    .productinfo .tab-content {
        margin-bottom: 5px;
    }
    .image-gallery-slides {
        border-radius: 5px;
    }
    .pro-detail .image-gallery-image {
        height: 230px;
        overflow: hidden;
    }
    .pro-detail .image-gallery-thumbnail {
        width: 90px;
        height: 60px;
        overflow: hidden;
    }
    .pro-detail .image-gallery-thumbnail img {
        height: 60px;
    }
    .detailtabs .MuiSvgIcon-fontSizeSmall {
        font-size: 22px;
    }
    #rightColrightCol {
        display: none;
    }
    .primaryguestform .order-total {
        display: none;
    }
    .procedd-block.border-none.web-price {
        display: none;
    }
    .mobile-price {
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        z-index: 999;
        display: block;
    }
    .Show.Option {
        display: flex;
        justify-content: space-between;
        background: #fff;
        align-items: center;
        padding: 8px 10px;
        border-radius: 0;
    }
    .Show.Option .red-btn {
        height: 50px;
        margin: 10px 0 0;
    }
    .Show.Option h1 {
        font-size: 22px;
        margin: 0;
        display: none;
    }
    .options-show {
        width: 100%;
        text-align: center;
        height: 50px;
        background: #ec1c24;
        color: #fff;
        border-radius: 6px;
        line-height: 50px;
        font-size: 18px;
        font-weight: 600;
        border: none;
    }
    .mobile-pricing-view {
        position: fixed;
        width: 100%;
        height: 100%;
        background: #fff;
        z-index: 9999;
        left: 0;
        top: 0;
        overflow: auto;
    }
    .mobile-pricing-view .custom-select {
        border: 1px solid #dbdbdc;
        background-color: transparent;
        height: 40px;
        cursor: pointer;
        font-size: 16px !important;
        font-weight: 600;
        padding-right: 0;
    }
    .cross-btn {
        border: none;
        background: transparent;
        font-size: 22px;
        float: right;
    }
    .pro-detail .block .right-side {
        width: 24%;
    }
    .pro-detail .block {
        padding: 20px 0;
    }
    p.bought-total {
        font-size: 14px;
        color: #6d6e70;
        float: left;
        line-height: 27px;
    }


    .modal-dialog{

        margin: 0;
    }

    .modal-content{

        padding-top: 20px;
    }


    .explor_item .room_image{

        height: 60px;
        width: 90px;
    }


    .list-title-bundle{
        font-size: 12px!important;
    }

}

@media screen and (min-device-width: 768px) and (max-device-width: 1200px) {
    .room_list_item.internal .room_img img {
        width: 100%;
        min-height: 180px !Important;
    }
}

@media screen and (min-device-width: 768px) and (max-device-width: 900px) {
    .order-total.new h5 {
        font-size: 10px;
        color: #34383b;
    }
    .order-total.new p {
        font-size: 12px;
        color: #858688;
    }
    .pro-detail .order-total p.small-text {
        font-size: 12px !important;
        margin: 0 !important;
        color: #858688;
    }
}

@media (max-width:767px) {
    .pro-detail .offers {
        width: 100%;
        float: left;
        background: #fff;
        /*border-radius: 10px;*/
        min-height: 20px;
        margin: 20px 0 20px;
    }
}

@media (max-width:480px) {
    .order-total.new h5 {
        font-size: 10px;
        color: #34383b;
    }
    .room_list_item.internal .room_list_l_text h4 {
        font-size: 14px;
    }
    a.pull-right.view_btn {
        background: #00adee;
        color: #fff;
        border-radius: 4px;
        font-size: 10px;
        padding: 5px 10px;
        position: relative;
        right: 0px;
    }
    ul.key_highilits li {
        display: inline-block;
        width: 50%;
        font-size: 14px;
        color: #6d6e70;
        margin: 5px 0 10px 0;
    }
}

.package-column .col-12:first-child {
    background: #f4f4f4;
    padding: 20px 20px 0px 20px;
    margin: 10px 15px 10px 15px;
    flex: auto;
    border: 1px dashed #007dc4;
    border-radius:8px;
}

.product-dis-list img {
    margin-right: 10px;
    margin-top: -2px;
}

.product-dis-list .row.mb-1 .col-lg-12 {
    margin-bottom: 3px;
}

.package-column .col-12:nth-child(2) {
    margin-bottom: 10px;
    margin-top: 6px;
}
.product-dis-list span {
    margin: 2px 0 0 0px;
    float: left;
}

.row.bundle-items {
    margin: 5px 0 15px 0px;
    border-bottom: 1px solid rgba(74,77,80,.2);
}
.row.bundle-items .col-lg-3.col-3.col-md-3 {
    padding-left: 0px;
}
.custom-modal-popup .edit-cart-bundle #sticky-box {
    padding: 0;
    box-shadow: none;
}

.custom-modal-popup .pro-detail.edit-cart-bundle.new-block.padtop0 {
    box-shadow: none;
}

.bundle-pop-up-form .mobile-menu.edit-cart-block.col-lg-12 {
    padding: 0;
}
.bundle-pop-up-form .form-heading {
    padding: 0 15px;
}
.bundle-pop-up-form .promo-block {
    margin: 0 15px;
}

.checkout-section.bg-gray.py-5 .product-img-block .col-7.col-lg-8.col-md-8 {
    padding-left: 0;
}
.checkout-section.bg-gray.py-5 .product-img-block .bundle-items .col-9.col-lg-9.col-md-9 {
    padding-left: 0;
}

@media screen and (max-width: 600px) {
    .checkout-section.bg-gray.py-5 .product-img-block .bundle-items .product-dis-list .col-lg-12 {
        padding-left: 0;
    }
    .checkout-section.bg-gray.py-5 .product-img-block .bundle-items .product-dis-list .col-lg-12 img {
        display: none;
    }
    .row.bundle-items {
        padding-bottom: 15px;
    }
    .checkout-section.bg-gray.py-5 .product-img-block .col-7.col-lg-8.col-md-8 {
        padding-left: 0;
        margin-bottom: 15px;
    }
    .product-img-block .product-title-booking .price, .product-title-booking h2 {
        font-size: 16px;
    }
    .row.bundle-items .product-title-booking .col-lg-4 .price{
        font-size: 16px;
        color: #34383b;
        font-weight: 600;
        float: left;
    }
    .row.bundle-items .product-title-booking .or-price {
        float: left;
        width: auto;
        margin: 2px 0 0 5px;
    }
    .row.border-bottom.mb-3.pb-2.product-img-block .col-lg-12.for-mobile .product-dis-list {
        margin-bottom: 10px;
    }
    .row.border-bottom.mb-3.pb-2.product-img-block .col-lg-12.for-mobile .product-dis-list .row .col-12 img {
        display: none;
    }
    .row.border-bottom.mb-3.pb-2.product-img-block .col-lg-9.col-md-9.col-7 {
        padding-left: 0;
    }
    .row.border-bottom.mb-3.pb-2.product-img-block .col-lg-9.col-md-9.col-7 .product-title-booking .col-lg-8 h2 {
        font-size: 16px;
        color: #34383b;
        float: none;
    }
    .checkout-section .product-img {
        border-radius: 5px;
        overflow: hidden;
        height: 70px;
    }
    .custom-modal-popup .row.py-3.justify-content-center.mobile-menu-row .edit-cart-block .block.border-bottom.pt-0 {
        padding: 10px 15px 15px 15px !important;
    }
    .custom-modal-popup.bundle-pop-up-form.popup-form .modal-dialog {
        max-width: 100%;
        width: auto;
    }
}

.bottom-border-none{
    border-bottom: none!important;
}
.rating_block .MuiRating-readOnly{

    margin-top: 2px!important;

}

.list-title-bundle{
    font-size: 14px;color: #34383b;
    font-weight: 600;
}
.pro-detail-new .small-text{
    opacity: 1!important;}

.row.bundle-items div.col-lg-4 {
    padding: 0 0 0 15px;
}


.hero-image-big{


    -moz-transition: height .5s;
    -ms-transition: height .5s;
    -o-transition: height .5s;
    -webkit-transition: height .5s;
    transition: height .5s;
    height: 700px;
}

.hero-image-big .hero-text div{

    text-align: left;
}


@media only screen and (max-width: 991px) {

    .pro-detail-web{
        display: none;
    }

    .row.bundle-items>.col-5{padding-left: 0;}

    .room_list_l_text{

        margin-left: 15px;
    }

    .hero-text{

        width: 100%;

    }

    .hero-image-big .hero-text {

        top: 40%;
    }

    .weekend-pass-popup .room_list_l_text{margin-left: 0px;}

    .room_list_l_text .col-sm-3
    {
        margin-right: 20px;
    }

}

.room_list_item .text-line{

    text-decoration: line-through;
    margin: 0;
    color: #858688;
    font-size: 16px !important;
    font-weight: normal;

}

.pro-detail-new .collapsable-inside-content p{margin-top: 6px!important;}


@media only screen and (max-width: 500px) {


    .room_list_l_text .col-sm-3
    {
        margin-right: 10px;
    }
    .hero-image-big .hero-text {
        top: 45% !important;
    }

}


.weekend-pass-popup .bullet-text li{
    background-image: url(../img/dot.png) !important;
    background-repeat: no-repeat;
    background-position: left 8px;
    padding: 0 0 13px 30px;
    list-style-type: none;
    width: 100%;
    float: left;
}

.weekend-pass-popup .bullet-text {

    padding: 0;

}
.mobile-pricing-view .pro-detail{

    background: #ffff!important;
    box-shadow: none
}

.mobile-pricing-view .pro-detail.new-block .offers-new .block.border-bottom {
    padding: 15px 0!important;
}