.subscribe_modal .modal-dialog{
    padding: 0px;
    border-radius: 10px;
    background-image: url(../img/pop-up-bg.jpg);

    background-size: cover;
    /*min-height: 550px;*/
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
    margin:0;
}

.subscribe_modal button.close {
    position: absolute;
    right: 20px;
    top: 20px;
}


@media (min-width: 576px){
    .subscribe_modal .modal-dialog {
        max-width: 400px;
        margin: 1.75rem auto;
    }
    .news-letter-website .modal-dialog{
        max-width: 700px;
        width:700px;
        margin: 1.75rem auto;
    }
}
@media (max-width: 767px){
    .news-letter-website .modal-dialog button.btn.btn-default {
        font-size: 12px !Important;
        width: 35% !Important;
    }
    .subscribe_modal .modal-dialog{
        width: 90%;
        margin: 0 auto !important;
    }
    .news-letter-website .modal-dialog {
        min-height: 600px !Important;
        width: 90%;
    }
}
.modal-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 0px;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px);
}
.subscribe_modal .modal-dialog	.modal-body {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0 1rem;
    text-align: center;
}


.subscribe_modal .modal-content {

    border: 0px !important;
    border-radius: 0;
    background-color:transparent !Important;
    position: initial;
}

.news-letter-website .modal-content {

    border: 0px !important;
    border-radius: 0;
    background-color:transparent !Important;
    position: initial;
}



.subscribe_modal .modal-dialog	.modal-body h2 {
    color: #34383b;
    font-size: 30px;
}
.subscribe_modal .modal-dialog	.modal-body p {
    font-size: 16px;
    color: #858688;
    line-height: 24px;
}
.subscribe_modal .modal-dialog input.form-control {
    width: 100%;
    margin: 0 auto;
    background: #efefef;
}
.subscribe_modal .modal-dialog input.form-control {
    width: 90%;
    margin: 0 auto;
    background: #efefef;
    border-color: #efefef;
    height: 46px;
    color: #858688;
    font-size: 14px;
    text-align: center;
    outline:none;
}
.subscribe_modal .modal-dialog button.btn.btn-default {
    display: block;
    width: 90%;
    margin: 0 auto;
    background: #ec1c24;
    color: #fff;
    height: 46px;
    font-size: 16px;
    font-weight: bold;
}
a.skip_now {
    color: #00aae0;
    font-size: 16px;
    display: block;
    text-align: center;
    margin: 15px 0;
    text-decoration:none;
}
.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: none !important;
}
.news-letter-website .modal-dialog{
    background: url(../img/news-letter-desktop.jpg) no-repeat;
    padding: 0px;
    border-radius: 10px;
    background-size: cover;
    min-height:480px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
    margin:0;
}
.news-letter-website .modal-dialog h2 {
    color: #fff;
    font-size: 36px;
}
.news-letter-website .modal-dialog button.close {
    background: #a0a5a7;
    color: #fff;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    text-align: center;
    padding: 0 !important;
    margin: 0rem 0rem 0rem auto !important;
    font-size: 25px;
    padding: 0px 0 20px 0 !important;
    top: 10px;
    position: absolute;
    z-index: 9999;
    right: 10px;
}
.news-letter-website .modal-dialog p {
    color: #fff;
    font-size: 16px;
}
/*.news-letter-website .modal-dialog input.form-control {*/
    /*background: #efefef;*/
    /*border-color: #efefef;*/
    /*height: 46px;*/
    /*display: inline-block;*/
    /*width: 60%;*/
    /*color: #858688;*/
    /*font-size: 12px;*/
    /*border-top-right-radius: 0px;*/
    /*border-bottom-right-radius: 0;*/
    /*position: relative;*/
    /*right: -5px;*/
    /*top:0px;*/
    /*border-top-right-radius: 0px !important;*/
    /*border-bottom-right-radius: 0px !important;*/
/*}*/

.news-letter-website .modal-dialog input.form-control {
    background: #efefef;
    border-color: #efefef;
    height: 46px;
    display: inline-block;
    width: 60%;
    color: #858688;
    font-size: 12px;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    position: relative;
    right: -5px;
    top: 1px;
}

.news-letter-website .modal-dialog button.btn.btn-default {
    background: #e5181f;
    color: #fff;
    height: 46px;
    font-size: 16px;
    font-weight: bold;
    width: auto;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0;
    border-color:#e5181f;
}
.news-letter-website .modal-dialog a.skip_now {
    color: #00aae0;
    font-size: 16px;
    display: block;
    text-align: left;
    margin: 0px 0 0 8px;
    text-decoration: none;
}
.news-letter-website .modal-dialog .modal-body {
    position: relative;
    top: 140px;
}
.news-letter-website .modal-dialog .form-group {
    display: inline;

}
button.skip_now {
    color: #00aae0;
    font-size: 16px;
    display: block;
    text-align: center;
    margin: 15px 0;
    text-decoration: none;
    background: none;
    border: none;
}

.subscribe_modal button.skip_now {
    width: 100%;
}
.subscribe_modal .modal-dialog .modal-body {
    top: 50px !important;
}
.news-letter-website .modal-dialog-centered::before {

    height: auto !important;

}
.subscribe_modal .modal-dialog .modal-body h2 {
    font-size: 25px;
}
.subscribe_modal .modal-dialog .modal-body p {
    font-size: 14px;
}
/*.subscribe_modal .modal-dialog-centered{min-height: auto!important;}*/

.subscribe_modal .modal-dialog{  align-items: normal !important;}
.news-letter-website .modal-dialog .modal-body {
    text-align: center;
}

.news-letter-website button.skip_now {
    width: 100%;
}