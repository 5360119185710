.block-title h2{
    color:#EC1C24;
}

.block-title p{
    font-size: 18px;
}

.activites{
    position: relative;
    width: 100%;
    display: flex;
   
}

.activites .image {
    transition: all 0.5s;
    overflow: hidden;
    border-radius: 8px;
    height: 270px;
}

.owl-theme .owl-nav .owl-prev {
    float: left;
    margin-left: -18px !important;
}

.owl-theme .owl-nav .owl-next{
    margin-right:-18px !important;
}

.activites .image img {
    display: block;
    width: 100%;
}

.activites .image::after {
    display: block;
    position: relative;
    background-image: linear-gradient(to bottom, 
rgba(255, 255, 255, 0) 0,
    #000 150%);
    margin-top: -150px;
    height: 150px;
    width: 100%;
    content: '';
    border-radius: 11px;
}

.activity-text {
    position: absolute;
    bottom: 25px;
    left: 25px;
}
.activity-text h4{
    color:#fff;
    font-size: 32px;
    font-weight: 500 !important;
}

.activity-text a {
    width: 108px;
    height: 44px;
    background:rgba(255,255,255,0.9);
    box-shadow: 0px 3px 6px#00000029;
    border-radius: 25px;
    opacity: 1;
    display: inline-block;
    color:#00ADEE;
    font-size: 18px;
    line-height: 44px;
    text-align: center;
}

.activity-listing{
    width: 100%;
    position: relative;
    margin: 0 0 40px;
}

.listing-slider .item-heading {
    font-size: 14px;
}

.owl-dots{
    display: none;
}

.owl-theme .owl-nav {
    margin-top: 0;
    text-align: center;
    position: absolute;
    top: 17%;
    width: 100%;
}

.owl-theme .owl-nav button{
background:#FFFFFF !important;
box-shadow: 0px 3px 6px#00000029 !important;
opacity: 1;
width: 48px;
height: 48px;
border-radius: 50% !important;

}

.owl-theme .owl-nav button:hover{
    color: #000 !important;
}
.owl-theme .owl-nav .owl-prev{
float: left;
}
.owl-theme .owl-nav .owl-next{
    float: right;
    }
    

.owl-theme .owl-nav button span{
    font-size:26px;
}

.owl-theme .owl-nav .disabled {
    opacity: 0 !important;
    cursor: default;
}