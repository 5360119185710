
.signup-banner, .signin-banner {
    background: url('../img/signin-banner.jpg') no-repeat center;
    background-attachment: scroll;
    background-size: auto;
    background-attachment: fixed;
    background-size: cover;
    min-height: 700px;
    width: 100%;
    display: inline-block;
    padding: 100px 0 80px;
}
.signup-form {
    background-color:#fff;
    border-radius: 10px;
    padding: 24px 16px;
    box-shadow: 0 0 17px#212121;
}

.form-heading {
    color: #00adee;
    font-size: 22px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 20px;
}

.small-text {
    font-size: 12px !important;
    opacity: 0.4;
    margin: 0 !important;
    width: 100%;
}

.btn {
    background: 
#ec1c24;
border-radius: 5px !important;
border-left: 0;
font-size: 14px;
color:
white;
line-height: 26px;
border: 2px solid
    #ec1c24;
    padding: 5px 18px 4px;
}