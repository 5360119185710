.admin-haeder{
    padding: 40px 0;
    width: 100%;
    float: left;
}

.avatar-upload {
    position: relative;
    width: 100px;
    float: left;
}

.avatar-upload .avatar-edit {
    position: absolute;
    right: 11px;
    z-index: 1;
    top: 0px;
}

.avatar-upload .avatar-edit label {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-bottom: 0;
    border-radius: 100%;
    background: #ec1c24;
    color:#fff;
    border: 1px solid#fff;
    cursor: pointer;
    font-weight: normal;
    transition: all 0.2s ease-in-out;
}

.avatar-upload .avatar-edit label::after {
    content: "\f040";
    font-family: 'FontAwesome';
    color: 
    #ffffff;
    position: absolute;
    top: 44%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;
    content: '';
}

.avatar-upload .avatar-preview {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 100%;
    border: 5px solid #F8F8F8;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.avatar-upload .avatar-preview > div {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../img/bollywood-1.png);
}

.user-name {
    float: left;
    width: auto;
    color: #fff;
    margin: 19px 0 0 15px;
}

.user-name h5 {
    font-size: 16px;
    margin: 0;
    color: #fff;
}

.user-name h2 {
    font-size: 24px;
    font-weight: bold;
    color:#fff;
}

.activity-status_info {
    float: left;
    color: #fff;
    margin: 0 0 0 30px;
}

.activity-status_info a {
    color: #fff;
    text-decoration: underline;
}

.Admintab{
    width: 100%;
    float: left;
    background: #00649d;
}

.Admintab ul.nav{
    margin: 0 !important;
}

.Admintab .nav-pills .nav-link.active, .Admintab .nav-pills .show > .nav-link {
    color: #fff;
    background-color: transparent;
    border-bottom:3px solid #00ADEE;
}

.Admintab .nav-pills .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
}

.Admintab .nav-pills .nav-link {
    border-radius:0px;
    color: #fff;
    padding: 1rem 1rem;
    border-bottom:3px solid transparent;
}

.tab-detail{
    width: 100%;
    float: left;
    background-image: url(../img/vender-png.png);
    background-size: contain;
    background-repeat: no-repeat;
    float: left;
    width: 100%;
    background-position: top right;
    padding: 75px 0;
}

.tab-content {
    width: 100%;
    float: left;
}

.tab-content .tab-pane.shdaow-bg {
    width: 100%;
    float: left;
    box-shadow: -2px 3px 15px rgba(0, 0, 0, .2);
    background:#fff;
    border-radius: 10px;
    font-size: 18px;
    padding: 30px 10px !important;
}

.tab-backgroubnds ul {
    position: relative;
    border-bottom: 4px solid #e5e6e7;
    display: flex;
    flex-wrap: wrap;
}

.tab-backgroubnds ul li {
    margin-right: 30px;
    text-align: left;
    padding-left: 0;
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 18px;
    position: relative;
}

.tab-backgroubnds ul li a {
    color: #a7a9ac;
    text-align: left;
padding-left: 0;
padding-top: 0px;
padding-bottom: 0px;
font-size: 18px;
}

.tab-backgroubnds .nav-pills .nav-link.active, .tab-backgroubnds .nav-pills .show > .nav-link {
    color: #000;
    background-color:transparent;
}

.tab-backgroubnds ul li .active::after {
    content: '';
    height: 4px;
    background: #00aeef;
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
}


.tab-content .tab-pane label{
    font-size: 16px;
    color:#6d6e70;
    font-weight: normal;
    line-height: 22px;
    display: block;
    width: 100%;
}

.tab-content .tab-pane input, .tab-content .tab-pane textarea {
    border: none;
    background-color: #EFEFEF;
    padding: 8px;
    width: 100%;
    border-radius: 5px;
    font-size: 14px;
    margin: 0;
}

.btn.btn-red {
    font-weight: bold;
    font-size: 16px;
    border-radius: 0;
    padding: 10px 35px;
    background:#ec1c24;
    border-radius: 5px !important;
    color:white;
    line-height: 26px;
    border: 2px solid #ec1c24;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3) !important;
}

.title-goes img {
    width: 100%;
    height: 100%;
}

.border-raidus {
    border-radius: 5px;
}

.title_here {
    width: 100%;
    float: left;
    color:#000a12;
}

.title_pirce {
    color: 
    #000a12;
    font-weight: bold;
    font-size: 24px;
    margin: 10px 0 10px;
}

.rating {
    width: 100%;
    float: left;
}

.rating img {
    float: left;
}

.tab-content .tab-pane .title_here p {
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
}

.tab-content .tab-pane .title_here p {
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    color: #6d6e70;
}

.bottom-block {
    width: 100%;
    float: left;
}

 .bottom-block .title_price2 {
    color: 
    #000 !important;
    font-weight: bold !important;
    font-size: 24px !important;
    float: left;
    line-height: 41px;
    margin: 0 !important;
}

.bottom-block .button_edit {
    width: 180px;
    height: 40px;
    font-size: 18px;
    text-align: center;
    line-height: 36px;
    float: right;
    border-radius: 5px;
    padding: 4px 8px;
    text-transform: uppercase;
    color: #fff;
    background: #007dc4;
    border-color: #007dc4
}

#pills-mybooking p{
    margin: 0;
}

.prize-block-booked {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 32px 0 0;
    float: left;
    border-top: 1px solid #ddd;
    padding: 10px 0 0;
}

.block-action {
    width: 100%;
    float: right;
    border-left: 1px solid #ddd;
    min-height: 180px;
}

.block-action .rating {
    float: right;
    text-align: right;
}

.block-action .rating h5 {
    color: 
    #6d6e70;
    margin: 0;
    font-size: 18px;
}

.block-action .rating p {
    margin: 0;
    float: right;
}

.block-action .rating img {
    float: right;
}

.action-button {
    text-align: right;
    width: 100%;
    float: left;
    margin: 15px 0 0;
}

.block-action .action-button .btn-blue{
width: 140px;
text-align: center;
background:#007dc4;
border-radius: 5px !important;
font-size: 16px;
color:white;
border: 2px solid#007dc4;
padding:5px 5px;
float: right;
}

.block-action .action-button .btn{
    background:#ec1c24;
border-radius: 5px !important;
border: 2px solid #ec1c24;
color:white;
margin: 5px 0;
font-size: 16px;
float: none;
line-height: 13px;
float: right;
width: 140px;
text-align: center;
padding: 10px 5px;
}

.note-p {
    font-size: 14px !important;
    margin: 10px 0 0 !important;
    width: 100%;
    color: #6d6e70 !important;
    float: left;
}
.rating.reviewtab p {
    color: #00adee;
    margin: 0 13px 0 0;
    float: left;
    width: auto;
}

.rating.reviewtab img {
    float: left;
}

.bottom-block .readmore-red {
    float: left;
    border-radius: 5px;
    height: 40px;
    line-height: 35px;
    padding: 4px 8px;
    text-transform: uppercase;
    font-size: 18px;
    background: #ec1c24;
    color:#fff !important;
    width: 150px;
    text-align: center;
}

.input-group-prepend {
    margin-right: -1px;
    position: absolute;
}

.input-group > .input-group-prepend > .input-group-text{
    border-top-right-radius: 0;
border-bottom-right-radius: 0;
background: linear-gradient(to bottom, #ececec 0%, #d6d6d6 100%);
border: none;
}