@import "~react-image-gallery/styles/css/image-gallery.css";
body {
    font-family: 'Archivo', sans-serif;
    font-size: 14px;
    color: #34383B;
    background: #fff;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500 !important;
    color: #34383B;
}

.about-banner {
    background-image: url('img/about-banner.jpg');
}
.medium-banner {
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 270px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
}

.medium-banner::after {
    position: absolute;
    content: '';
    width: 100%;
    top: 0;
    bottom: 0;
    height: 100%;
    background:
            rgba(0, 0, 0, 0.3);
    z-index: -1;
}

.medium-banner h2 {
    color: #fff;
    margin: 0;
    font-size: 42px;
    text-transform: uppercase;
}


.blog-hd-main {
    box-shadow: -5px 0 5px -6px #333, 6px 0 5px -7px#333;
    padding: 40px;
    width: 100%;
    margin: 50px 0 70px 0;
    margin-top: 50px;
    background: url(img/vender-png.png);
    background-position-y: 0%;
    background-repeat: repeat;
    background-size: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: 12%;
    border-radius: 5px;
}

.blog-hd-main .best-family-txt {
    padding-bottom: 0;
}
.best-family-txt {
    font-size: 25px;
    color: #ec1c24;
    font-weight: bold;
    padding-bottom: 40px;
}
.before-u-txt {
    font-size: 19px;
    color: #6d6e71;
    margin-bottom: 10px;
}

.updated-date {
    color:#0094d9;
    font-size: 18px;
    font-weight: 600;
}

.eugait-txt {
    font-size: 17px;
    color: #6d6e70 ;
    margin-bottom: 10px;
    font-weight: 600;
}

.term-condi-scroll p {
    color:
            #6d6e70 !important;
}

.term-condition-bg {
    background: url(img/term-condition-bg.jpg);
    background-position-y: 0%;
    background-repeat: repeat;
    background-size: auto;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 0;
    background-position-y: center;
}

.how-work-txt h2 {
    font-size: 41px;
    color:
            #fff;
    font-weight: 600;
}

.buy-now {
    width: 100%;
    float: left;
    text-align: center;
    height: 40px;
    background:
            #ec1c24;
    color: #fff;
    border-radius: 6px;
    line-height: 40px;
    border: none;
}

.faq-top-img {
    background-image: url(img/Faqs-page-img-1.png);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 100px 0;
}

.banner-font {
    font-size: 41px;
    color: #fff;
    font-weight: 600;
}

.vender-background-image1 {
    background-image: url(img/y.png);
    width: 72%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: 30%;
}

.accodiyan_block {
    margin: 70px auto;
    box-shadow: -2px 3px 8px
    rgba(0, 0, 0, .2);
}

.main-loremclass {
    padding-top: 15px;
}

.lorem_heading {
    font-size: 18px;
    color:
            #00a4f0;
    font-family: Archivo;
    margin-bottom: 5px;
}

.paregraf {
    font-size: 15px;
    font-weight: 500;
    font-family: Archivo;
    color: #bbbcbd;
    margin-bottom: 10px;
}

.bg-gray{
    width: 100%;
    position: relative;
    background:
            #f5f5f5;
    float: left;
}

.blue-bg{
    background:  #007dc4;
}