.listing-section {
    width: 100%;
    float: left;
    background: #f5f5f5;
}
.img-form-text {
    margin: 30px 0px 0;
}

.icon-and-img {
    box-shadow: 0 0 10px rgba(0,0,0,0.4);
    border-radius: 13px;
    overflow: hidden;
}

.item-info-box {
    padding: 10px;
    background:#fff;
}

.item-info-box a{
    color:rgb(72, 72, 72);
}

.item-info-box a:hover{
    text-decoration: none;
}

.icon-and-img .jb-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-weight: 700;
    margin: 0;
}

.ettol-text {
    color: rgb(72, 72, 72);
    font-size: 11px;
    padding-top: 5px;
    margin: 0px;
}

.lad-text {
    font-size: 10px;
    margin: 0;
    color: rgb(72, 72, 72);
}

.lad-text i{
    color: #127EC2;
    font-size: 12px;
}

.price-ditel {
    font-size: 18px;
    color: #6d6e70;
    line-height: 15px;
    padding-top: 10px;
    margin: 0px;
}

.list-rating {
    float: left;
}
.list-rating > i {
    float: left;
    margin: 3px;
    color:#29abe2 !important;
}
.button-buy {
    float: right;
    padding: 2px 10px 0px;
    color:white !important;
    background-color:#29abe2 !important;
    border: 1px solid #29abe2;
    border-radius: 5px;
    margin-bottom: 5px;
    font-size: 11px;
}

.carousel {
    position: relative;
}

.carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transition: all 0.3s;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color:#fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    flex: 0 1 auto;
    box-sizing: content-box;
}

.carousel-indicators .active {
    opacity: 1;
    background-color: #26abe2;

}

.catagory-top-img {
    /*background: url('../img/listing-banner.jpg') no-repeat center;*/
    /*background-size: cover;*/
    /*background-repeat: no-repeat;*/
    padding: 180px 0;
    width: 100%;

}

.icon-and-img .item-share {
    cursor: pointer;
    position: absolute;
    left: 25px;
    top: 10px;
    height: 25px !important;
    width: 25px !important;
    z-index: 99;
}

.item-share a {
    color:#fff;
    font-size: 22px;
}

.item-share:hover .share-hover {
    display: block;
}

.share-hover {
    display: none;
    z-index: 99;
}
.share-hover ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.share-hover ul li {
    position: relative;
    z-index: 99;
}

.share-hover ul li img {
    width: 25px;
    border-radius: 0px !important;
    margin: 0 0 5px;
}

.icon-and-img .fav-btn {
    position: absolute;
    right: 25px;
    top: 10px;
    height: 25px !important;
    width: 25px !important;
    z-index: 99;;
}

.fav-btn a {
    color:#fff;
    font-size: 22px;
}

.fav-btn a:hover{
    color:#d72d31;
}

.icon-and-img .carousel-item  img {
    height: 185px !important;
}