@media
only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (-o-min-device-pixel-ratio: 3/2),
only screen and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-device-pixel-ratio: 1.5){

	html,
	body{
		width:100%;
		/*overflow-x:hidden;*/
	}

}

.bg-light {
	background-color: #fff !important;
	/*position: -webkit-sticky;*/
	/*position: sticky;*/
	/*top: 0;*/
	z-index: 999;
	box-shadow: 0px 1px 10px rgba(0,0,0,0.1);
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}


.search:hover, .shoping-cart:hover{
	border-bottom: 2px solid #00ADEE;
}

.right-nav .dropdown.nav-item{
	border-bottom: 2px solid transparent;
}
.list-rating-mui svg {font-size: 14px!important;}
.right-nav .dropdown.nav-item:hover {
	border-bottom: 2px solid #00ADEE;
}

/* Firefox */
input[type=number] {
	-moz-appearance:textfield;
}

.red-btn:hover, .red-btn:focus, .blue-btn:hover, .gray-btn:hover{
	color: #fff;
	border: none !important;
	box-shadow: none !important;
}
*, a, a:hover{
	text-decoration: none;
}
.red-btn {
	float: left;
	text-align: center !important;
	height: 40px;
	background: #ec1c24 !important;
	color:#fff !important;
	border-radius: 6px !important;
	line-height: 40px !important;
	border: none !important;
	font-weight: 600 !important;
	padding: 0 10px !important;
}


.for-web-custom{

	display: block;
}

.for-mobile-custom{

	display: none;
}


.for-mobile-custom .help-desk-bottom{

	border-top: 2px solid
	#0095da;
	padding-top: 32px;
}

.blue-btn {
	float: left;
	text-align: center;
	height: 40px;
	background: #00adee;
	color: #fff;
	border-radius: 6px;
	line-height: 40px;
	border: none;
	font-weight: 600;
	padding: 0 10px;
}


.price-range.active button{background: transparent!important;}

.gray-btn {
	float: left;
	text-align: center;
	height: 40px;
	background:#c4c4c5 !important;
	color: #fff;
	border-radius: 6px !important;
	line-height: 40px;
	border: none !important;
	font-weight: 600;
	padding: 0 10px !important;
}

input.form-control{
	background: #e5e6e7;
	border-radius: 5px;
	height: 44px;
	font-size: 14px;
	border:none;
}

.phone_no .input-group-text{
	background: -webkit-linear-gradient(top, #ececec 0%, #d6d6d6 100%);
	border: none;
}

.small, small {
	font-size: 80%;
	font-weight: 400;
}

.help-text {
	opacity: 0.5;
}

* {
	outline: none;
}

.heart:hover {
	text-decoration: none;
}

.fa-heart.favorite{
	color:#00ADEE;

}


#navbar{z-index: 1000000!important;}
.btn-heart{text-align: center!important;z-index:20000!important;}

.heart:hover .fa {
	display: block;
	text-decoration: none;
	color:#00ADEE;
}

.heart:hover .far {
	/* display: none; */
	text-decoration: none;
}

section{
	width: 100%;
	float: left;
	position: relative;
	padding: 70px 0;
}

@import "~react-image-gallery/styles/css/image-gallery.css";
body {
	font-family: 'Archivo', sans-serif;
	font-size: 14px;
	/* color: #34383B; */
	color: #6d6e70;
	background: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 600 !important;
	color: #34383b;
}

h3 {
	font-size: 24px;
	margin-bottom: 12px;
}

.about-banner {
	background-image: url('./img/about-banner.jpg');
}

.medium-banner {
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	min-height: 270px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 0;
	background-position: 50%;
}

.medium-banner::after {
	position: absolute;
	content: '';
	width: 100%;
	top: 0;
	bottom: 0;
	height: 100%;
	background: rgba(0, 0, 0, 0.3);
	z-index: -1;
}

.medium-banner h2 {
	color: #fff;
	margin: 0;
	font-size: 42px;
	text-transform: capitalize;
}

.blog-hd-main {
	box-shadow: none ;
	padding: 40px;
	width: 100%;
	margin: 0 0 70px 0;
	margin-top: 50px;
	background: url(img/vender-png.png);
	background-position-y: 0%;
	background-repeat: repeat;
	background-size: auto;
	background-size: contain;
	background-repeat: no-repeat;
	background-position-y: 12%;
	border-radius: 5px;
}

.blog-hd-main .best-family-txt {
	padding-bottom: 0;
}

.best-family-txt {
	font-size: 25px;
	color: #34383b;
	font-weight: 700 !important;
	padding-bottom: 40px;
}


.before-u-txt {
	font-size: 19px;
	color: #0E7DC2;
	margin-bottom: 10px;
}

.term-condi-scroll p a{
	color: #0E7DC2;
}

.updated-date {
	color: #0E7DC2;
	font-size: 18px;
	font-weight: 600;
}

.term-condi-scroll p.eugait-txt {
	font-size: 16px;
	color: #34383b !important;
	margin-bottom: 10px;
	font-weight: 600;
}

.eugait-txt {
	font-size: 17px;
	color: #6d6e70;
	margin-bottom: 10px;
	font-weight: 600;
}

.term-condi-scroll p {
	color: #6d6e70 !important;
	font-size: 16px;
}

.term-condi-scroll ul {
	list-style-type: lower-alpha;
	color: #6d6e70 !important;
}


.term-condi-scroll ul li{
	color: #6d6e70 !important;
	font-weight: normal;
	margin: 10px 0;
	font-size: 16px;
}

.privacy-policy-bg {
	background: url(img/privicy-policy-bg.jpg);
	background-position-y: 0%;
	background-repeat: repeat;
	background-size: auto;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 100px 0;
	background-position-y: center;
}

.term-condition-bg {
	background: url(img/term-condition-bg.jpg);
	background-position-y: 0%;
	background-repeat: repeat;
	background-size: auto;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 100px 0;
	background-position-y: center;
}

.how-work-txt h2 {
	font-size: 41px;
	color: #fff;
	font-weight: 600;
}

.buy-now {
	width: 100%;
	float: left;
	text-align: center;
	height: 40px;
	background: #ec1c24;
	color: #fff !important;
	border-radius: 6px;
	line-height: 40px;
	border: none;
}
.buy-now:hover{color: #fff;}

.faq-top-img {
	background-image: url(./img/Faqs-page-img-1.png);
	background-size: cover;
	background-repeat: no-repeat;
	padding: 100px 0;
}

.banner-font {
	font-size: 41px;
	color: #fff;
	font-weight: 600;
}

.vender-background-image1 {
	background-image: url(./img//y.png);
	background-size: contain;
	background-repeat: no-repeat;
	background-position-y: 30%;
}

.accodiyan_block {
	box-shadow: none;
}

.main-loremclass {
	padding-top: 15px;
}

.lorem_heading {
	font-size: 18px;
	color: #0E7DC2;
	font-family: Archivo;
	margin-bottom: 5px;
	font-weight: 700 !important;
}

.paregraf {
	font-size: 15px;
	font-weight: 500;
	font-family: Archivo;
	color:  #6d6e70 !important;
	margin-bottom: 10px;
}

.bg-gray {
	width: 100%;
	position: relative;
	background: #f5f5f5;
	float: left;
}

.blue-bg {
	background: #007dc4;
}


/*************************************** Comman CSS END  ******************************************/
#navbar{z-index: 10000;}
.navbar-light .navbar-brand img {
	width: 200px;
	-webkit-transition: width 1s;
	transition: width 1s;
}


.navstick .navbar-brand img {
	width: 150px;
}

.navbar-container-sticky {
	/*position: relative;*/

	top: 0px;
	z-index: 1000000 !important;
	height: 90px;
	width: 100%;
}


.navstick {
	position: fixed!important;
	width: 100%;
	top: 0;
	left: 0;
}


.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .nav-item{
	color: #000;
	font-size: 16px;
	padding: 0 .5rem;
	height: 40px;
	line-height: 40px;
	position: relative;
	pointer-events: all;
}
.navbar-light .navbar-nav .nav-item a, .navbar-light .navbar-nav .nav-item a:hover{
	color: #000000 ;
	text-decoration: none;
}

.navbar-light .navbar-nav .nav-item .dropdown-item{
	background: transparent;
}

.nav-link.signup:hover{
	color: #fff;
}

.modal-backdrop{
	z-index: 999999
}
.modal{z-index: 99999999;}

.navbar-light .navbar-nav .nav-item {
	border-bottom: 2px solid transparent;
	cursor: pointer;
}

.navbar-light .navbar-nav .nav-item:hover {
	border-bottom: 2px solid #00ADEE;
}

.navbar-light .navbar-nav .nav-item .button i{
	opacity:0.2;
}

.navbar-light .navbar-collapse {
	margin: 0;
}





.right-nav {
	flex-direction: row;
	display: flex;
	position: relative;
}

.search {
	padding: 0 1rem;
	height: 40px;
	line-height: 40px;
	background:transparent;
	border: none;
}

.search:hover .search-drop {
	display: block;
}

.search-body .search-drop .MuiPaper-root{
	right: 150px;
}

.search-drop {
	z-index: 9999999 !important;
	background-color: rgba(0,0,0,0.5);
}

.search-drop .MuiPaper-root {
	min-width: 700px;
	padding: .5rem .8rem;
	position: absolute;
	right:310px;
	box-shadow: 0px 0px 10px
	rgba(0, 0, 0, 0.1);
	background:#fff;
	left: auto !important;
}


.search-drop-mobile {

	display: none;

}

.nav-link {
	display: block;
	padding: .5rem 1rem;
	color: #34383b;
}

/*.search-drop:hover,*/
/*.search-drop.active {*/
/*background: #fff;*/
/*}*/

.search-drop input {
	width: 100%;
	padding: .1rem .8rem;
	height: 40px;
	background: #f5f5f5;
	border: none;
	border-radius: 5px;
}

.search-drop img {
	position: absolute;
	right: 24px;
	top: 18px;
	opacity: 0.4;
}

.nav-link.signup {
	background: #EC1C24 0% 0% no-repeat padding-box;
	border-radius: 4px;
	color: #fff;
	width: 119px;
	height: 40px;
	text-align: center;
	line-height: 40px;
	font-size: 16px;
	padding: 0 .5rem;
	position: relative;
	pointer-events: all;
}

.nav-link.login {
	width: 97px;
	height: 40px;
	background: #FFFFFF 0% 0% no-repeat padding-box;
	border: 1px solid #929497;
	border-radius: 4px;
	text-align: center;
	line-height: 40px;
	font-size: 16px;
	padding: 0 .5rem;
	position: relative;
	pointer-events: all;
	color:#34383b;
	text-decoration: none;
}

.navbar-light .navbar-nav .nav-item.bordernone:hover {
	border: none;
}

.nav-item .dropdown-menu {
	right: 0;
	left: auto;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	border: none;
}

.nav-item .dropdown-menu .dropdown-item span {
	width: 100%;
	display: flex;
}

.dropdown-item {
	line-height: 24px;
}


.nav-link:hover{
	color: #34383b;
}
.shoping-cart {
	padding: 0 1rem;
	height: 40px;
	line-height: 40px;
	background:transparent;
	border: none;
	position: relative;
}

.bag-close {
	opacity: 0.6;
	font-size: 8px;
	color:
			#212121;
}

.media-body .right .price-wrapper {
	float: right;
	margin: -3px 0 0;
	font-weight: 600;
}

.shoping-cart:hover .dropdown-content{
	display: block;
}


.dropdown-content {
	display: none;
	position: absolute;
	z-index: 1;
	right: 0;
	top: 39px;
	width: 300px;
	min-width: 160px;
	padding: 5px 0;
	margin: 2px 0 0;
	font-size: 14px;
	text-align: left;
	list-style: none;
	background-color: #fff;
	border-radius: 3px;
	box-shadow: 0 6px 10px rgba(0, 0, 0, 0.4);
}

.dropdown-content ul {
	float: left;
	list-style-type: none;
	padding: 0;
	width: 100%;
	margin: 0;
}
.dropdown-content ul a.cart-item {
	padding: 15px 10px;
	color: #212121;
	position: relative;
	border-bottom: 1px solid#ddd;
	width: 100%;
	float: left;
}
.dropdown-content ul a.cart-item.border-none {
	border-bottom: none;
}
.dropdown-content ul a.cart-item .media .media-body {
	align-items: flex-start;
}

.media-left img {
	width: 100px;
	height: 50px;
	margin: 0;
	border-radius: 3px;
}

.media-body {
	float: left;
	margin: 0 0 0 6px;
	display: flex;
	align-items: center;
	flex: 1;
}

.media-body .left {
	float: left;
	width: 129px;
	opacity: 0.6;
}
.media-heading {
	font-size: 13px;
	margin: 0;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	width: 100%;
}
.media-body .left .media-subheading {
	font-size: 9px;
	text-transform: capitalize;
	margin: 0;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	width: 100%;
	color: #ec1c24;
	font-weight: 700;
}

.media-body .right {
	float: right;
	width: 85px;
}
.media-body .right .price-wrapper {
	float: right;
	margin: -3px 0 0;
}
.dropdown-content ul a.cart-item .close-icon {
	position: absolute;
	top: 0px;
	right: 2px;
	display: none;
}

.dropdown-toggle::after {
	border: none;
	content: '\f078';
	font-family: "FontAwesome";
	color: #ccc;
	vertical-align: baseline;
}
/* Menu code */

.custom-dropdown .custom-dropdown {
	display: table;
	position: absolute;
	background: white;
	box-shadow: 0 5px 20px rgba(0,0,0,.1),inset 0 1px 10px 0 rgba(0,0,0,.1);
	border-radius: 10px;
	overflow: hidden;
	left: -21px;
	z-index:99 !important;
}

.MuiAppBar-positionStatic{
	z-index: 1 !important;
}

.sub-cat-block{
	padding: 0 0 0 20px;
}
.custom-dropdown ul {

	list-style: none;
	padding: 0;

}
.custom-dropdown ul > li img {
	width: 50px;
	padding-right: 10px;
}

.custom-dropdown .menu-level-1 {
	display:table-cell; min-width: 300px;
	padding: .5rem 0.5em;
	border-right: 1px solid #eee;
}



.custom-dropdown .menu-level-1>div>ul{
	padding-left:2px;
	padding: 2px;
}
.custom-dropdown .menu-level-1 > div > ul > li{
	cursor: pointer;
	position: relative;
}
.custom-dropdown .menu-level-1 > div > ul > li > a {
	text-align: left;
	text-decoration: none;
	font-size: 14px;
	cursor: pointer;
	display: inline-table;
	width: 96%;
	padding: 5px;
}
.custom-dropdown .menu-level-1>div>ul>li span{
	text-align: right!important;
	float: right;
	padding: 6px 0 0;
	color: #eee;
}
.custom-dropdown  a{
	color: black;
}

.custom-dropdown .menu-side-column.menu-column-img {
	vertical-align: top;
	padding: 23px 20px 0;
	width: 250px;
	min-width: 250px;
}

.custom-dropdown .menu-side-column.menu-column-img .sub-cat-block{
	padding-left: 0;
}

.custom-dropdown .menu-side-column.menu-column-img img{
	margin: 0 0 15px;
	width: 210px;
	height: 140px;
}

.custom-dropdown .menu-side-column {
	display:table-cell;
	min-width: 250px;
	width: 250px;
}
.custom-dropdown li:hover a, .custom-dropdown li:hover span>i{
	color: #ec1c24;
}

.cat-name{
	font-size: 18px;
	font-weight: 600 !important;
}

.sub-cat-block ul li a{
	font-size: 14px;
}

/************************************************** HEADER CSS END  **************************************************/

footer{
	/*background: #231F20;*/
	background: #151515;

	padding: 80px 0 57px;
	position: relative;
	float: left;
	width: 100%;
}

.footer-bottom-link{


	border-bottom: 1px solid #cccccc;

}

footer:before{
	position:absolute;
	content: '';
	background-image: url(./img/vender-png.png);
	background-repeat: no-repeat;
	top: 0;
	right: 0;
	width: 28%;
	height: 100%;
	background-size: cover;
	opacity: 0.1;
}

footer h3{
	font-size: 24px;
	color:#fff;
	margin:0 0 23px;
}

footer ul{
	padding: 0;
	margin: 0;
	list-style-type:none;
}

footer li{
	font-size: 14px;
	padding: 0 0 12px;
}

footer li a{
	color:#fff;
}

footer li a:hover{
	color: #ec1c24 !important;
	text-decoration: none;
}

footer li.social-icon a {
	background:transparent;
	width: 50px;
	height: 50px;
	float: left;
	border-radius: 50%;
	text-align: center;
	font-size: 16px;
	line-height: 40px;
	color:#34383b;
	margin: 0 5px;
}

.copyright{
	background: #F5F5F5;
	padding: 20px 0;
	float: left;
	width: 100%;
}

/************************************************** Footer CSS END **************************************************/
.perfect-weekened {
	margin: 70px 0;
}

.perfect-weekened h1 {
	font-size: 40px;
	line-height: 43px;
	color: #34383b;
	margin: 0 0 23px;
}

.perfect-weekened p {
	font-size: 21px;
	line-height: 22px;
	color: #34383b;
	margin: 0 0 29px;
}

.perfect-weekened h1 a {
	color: #34383b;
	position: relative;
	padding-left: 10px;
	text-decoration: underline;
}

.perfect-weekened h1 a img {
	color: #ccc;
	font-size: 30px;
	margin-left:10px ;
}

/*.perfect-weekened h1 a:hover .country-dropdown {*/
/*display: block;*/
/*}*/
.arrow-up {
	width: 0;
	height: 0;
	border-left: 15px solid transparent;
	border-right: 15px solid transparent;
	border-bottom: 15px solid #fff;
	top: -22px;
	position: relative;
}
.country-dropdown {
	/*display: none;*/
	position: absolute;
	right: -163px;
	width: 200px;
	background: #fff;
	box-shadow: 0 0 30px rgba(0,0,0,.1);
	z-index: 9;
	padding: 10px;
	top: 47px;
	border-radius: 5px;
}

.country-dropdown ul {
	float: left;
	list-style-type: none;
	text-align: left;
	padding: 0;
	margin: 0;
}

.country-dropdown ul li{
	line-height:22px;
}

.country-dropdown ul li a{
	font-size: 18px;
	color: #34383b;
	font-weight: 300;
	text-decoration: none;
}

.perfect-weekened .perfect-btn.btn.btn-primary:hover, .perfect-weekened .perfect-btn.btn.btn-primary:focus{
	background: #00ADEE!important;
	border-color: #00ADEE!important;
	box-shadow: none !important;
}



.play-weekend.btn.btn-primary:hover, .play-weekend.btn.btn-primary:focus{
	background: #00ADEE!important;
	border-color: #00ADEE!important;
	box-shadow: none !important;
}


.perfect-weekened .perfect-btn {
	background: #00ADEE;
	border-radius: 4px;
	color: #fff;
	max-width: 327px;
	width: 100%;
	height: 48px;
	display: inline-block;
	line-height: 28px;
	border: 0px;
	font-size: 18px;
}

.perfect-weekened a.perfect-btn:hover {
	text-decoration: none;
}

.dropdown {
	width: auto;
}

#dropdown-basic {
	font-size: 40px;
	line-height: 43px;
	background: transparent;
	color: #34383B;
	font-weight: 500;
	border: none;
	padding: 0;
	margin: -12px 0 0;
}

.PersonalisedWeekends .trend-item .image {
	height: 180px;
}

.PersonalisedWeekends .trend-item .image img {
	height:100%;
	width: 201px;
	object-fit: cover;
}

.PersonalisedWeekends .trend-item .item-share{
	left: 16px;
	top: 10px;
	height: 35px !important;
	width: 42px !important;
}

.PersonalisedWeekends .trend-item .heart {
	right: 16px;
	top: 10px;
}

.PersonalisedWeekends .trend-item .share-hover ul li img{
	width: 25px;
	height: auto;
}

.trend-item  .wl-info-box {
	position: absolute;
	bottom: 18px !important;
	left: 18px;
	width: 83% !important;
}

/*.trend-item .wl-info-box .item-heading {*/
	/*font-size: 14px !important;*/
	/*margin-bottom:0;*/
	/*line-height: 17px;*/
	/*font-weight: 400;*/
	/*color: #fff;*/
/*}*/


.trend-item .wl-info-box .item-heading {

	font-size: 21px !important;
	margin-bottom: 0px;
	text-align: left;
	color: #ffffff;
	font-weight: 600;
	line-height: 25px;
}

/************************************************** Home CSS END  **************************************************/

.tranding-section {
	background: #F5F5F5;
}

.tranding-section h2 {
	font-size: 24px;
	line-height: 26px;
	color: #34383b;
	font-weight: 400 !important;
}

.trend-item {
	position: relative;
	width: 100%;
	display: flex;
	margin: 0 0 30px;
}

.trend-item .image {
	transition: all 0.5s;
	overflow: hidden;
	border-radius: 10px;
	height: 240px;

}

.trend-item .image img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.seeall {
	font-size: 18px;
	text-transform: uppercase;
}

.seeall img{
	margin: -4px 0 0 15px;
}

.seeall .fas, .activity-text a .fas,  a.seetext .fas{
	color:#007dc4;
}

.trend-item .image::after {
	display: block;
	position: relative;
	background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #000 150%);
	margin-top: -150px;
	height: 150px;
	width: 100%;
	content: '';
	border-radius: 11px;
}

.lastleft {
	display: none;
	position: absolute;
	left: 20px;
	top: 18px;
	color: #fff;
	background:#F48120;
	padding: 3px 10px;
	border-radius: 3px;
	font-size: 12px;

}
.wl-info-box {
	position: absolute;
	bottom: 18px !important;
	left: 20px;
	width: 89% !important;
}

.wl-info-box > ul  {
	padding: 0 !important;
	margin: 0;
	list-style: none;
}

.wl-info-box > ul li {
	width: 100%;
	float: left;
	padding:0;
}

.tranding-section .wl-info-box .item-heading {
	font-size: 21px !important;
	margin-bottom: 0px;
	text-align: left;
	color: #ffffff;
	font-weight: 600;
	line-height: 25px;
}

.wl-info-box .MuiSvgIcon-fontSizeSmall{
	font-size: 15px;
}

.wl-info-box .item-subtext {
	padding: 0 !important;
	margin-top: 0px !important;
	font-size: 12px;
	text-align: left;
	color: #ffffff !important;
	margin: 0;
}

.wl-price-box > ul p {
	padding: 0 !important;
	margin: 0;
	color: #ffffff !important;
	font-weight:500 !important;
	font-size: 18px;
	line-height: 20px;

}

.wl-price-box > ul p.price-main{
	text-decoration: line-through;
	font-size: 14px;
	text-align: right;
}

.rating {
	border: none;
	float: left;
	line-height: 18px;
}

.rating>input {
	display: none;
}

.rating>label::before {
	margin: 0 1px;
	font-size: 13px;
	font-family: FontAwesome;
	display: inline-block;
	content: "\f005";
}

.rating>.half:before {
	content: "\f089";
	position: absolute;
}

.rating>label {
	color: #fff;
	float: right;
	margin: 0;
}

.tranding-section.happy-home .owl-carousel .owl-stage{
	max-height: 306px !important;
	height: 100% !important;
	overflow: hidden;
}
/***** CSS Magic to Highlight Stars on Hover *****/

.rating>input:checked~label,

	/* show gold star when clicked */

.rating:not(:checked)>label:hover,

	/* hover current star */

.rating:not(:checked)>label:hover~label {
	color: #FFD700;
}


/* hover previous stars in list */

.rating>input:checked+label:hover,

	/* hover current star when changing rating */

.rating>input:checked~label:hover,
.rating>label:hover~input:checked~label,

	/* lighten current selection */

.rating>input:checked~label:hover~label {
	color: #FFED85;
}

.wl-price-box {
	position: absolute;
	bottom: 18px;
	right: 20px;
	width: auto;
	display: block;
	margin: 0;
	padding: 0;
}

.wl-price-box>ul {
	padding: 0 !important;
	margin: 0;
	list-style: none;
}

.wl-price-box>ul p {
	padding: 0 !important;
	margin: 0;
	color: #ffffff !important;
	font-weight: 600;
}

.heart {

	right: 20px;
	top: 18px;
	position: absolute;
	color:  #fff;
	z-index: 9;
	padding: 0;

}

.heart i {
	font-size: 20px;
}

.happy-home .owl-theme .owl-nav {
	top: 29%;
	width: 100%;
	z-index: -1;
}

.happy-home .owl-theme .owl-nav .owl-next {
	margin-right: -18px !important;
}

.work-banner {
	background: url('./img/work-banner.jpg') no-repeat center;
}

.how-work-section {
	padding: 90px 0 50px;
	position: relative;
	z-index: 9;
	width: 100%;
	float: left;
;
}

.red-heading {
	color: #ed1c24;
	text-transform: uppercase;
}

.work-decription {
	padding-left: 90px;
}

.work-decription .blue-heading {
	font-size: 27px;
	color: #00aeef;
}

.work-decription p {
	font-size: 13px;
	color: #808184;
	line-height: 24px;
}

.custom-container {
	max-width: 920px;
	margin: auto;
	flex-wrap: wrap;
}

.work-left-padding .work-decription {
	padding-left: 0;
	padding-right: 90px;
}

.blog-section::before {
	position: absolute;
	content: '';
	background: url('./img/vender-png.png') no-repeat right top;
	background-size: auto;
	width: 100%;
	top: 45px;
	height: 100%;
	right: 60px;
	background-size: contain;
	z-index: -1;
	opacity: 0.7;
}
.carousel-inner {
	position: relative;
	width: 100%;
	overflow: hidden;
	height: 100%;
}

.owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next, .owl-carousel button.owl-dot{
	color: #00ADEE;
}
/*************************************************** CAREER CSS START ***************************************************/

.career-bg {
	background: url(./img/caareer-bg.png);
	background-repeat: repeat;
	background-size: auto;
	background-size: cover;
	background-repeat: no-repeat;
	padding: 100px 0;
}

.backlistlink{
	color:#ec1c24;
	text-decoration: underline;
}

.how-work-txt h2 {
	font-size: 41px;
	color: #fff;
	font-weight: 600;
}
.empower-future-main {
	width: 100%;
	background-position-y: 0%;
	background-repeat: repeat;
	background-size: auto;
	background-size: contain;
	background-repeat: no-repeat;
	background-position-y: 12%;
}

.empower-hd {
	padding-bottom: 0;
}


.discover-pg {
    font-size: 18px;
    color:#6d6e70;
    line-height: 25px;
    padding-top: 10px;
    margin-bottom: 0;
}

.job-listing {
	background: #007dc4;
	color:#fff;
}

.job-listing p, .job-listing h2{
	color:#fff;
}

.job-listing .items {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid rgba(255,255,255,0.2);
	align-items: center;
}

.job-listing a.view-job {
    background: #00adee;
	border: none;
	color:#fff;
	width: 119px;
    height: 40px;
    border-radius: 5px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
}


.career-detail .wl_share p {
    margin: 4px 15px 0 0;
}

.career-detail .wl_share a img {
    width: 28px;
}

.career-detail h4{
	font-size: 20px;
}

.career-detail .font-18{
	font-size: 18px;
	color: #000000;
}

.dot-ul{
    /*margin: 0 0 0 20px;*/
    padding: 0;
    list-style-type: none;
}
.dot-ul li {
    padding: 0 0 7px 18px;
    color:#6d6e70;
    font-size: 16px !important;
}

.dot-ul li{
    background-image: url(./img/dot.png);
    background-repeat: no-repeat;
    background-position: left 8px;
    list-style-type: none;
    width: 100%;
    float: left;
}


.careerform {
    background: #fff;
	border-radius: 10px;
	box-shadow: 0 0 30px
    rgba(0,0,0,.1);
    width: 100%;
    float: left;
    padding: 30px;
}

.careerform input {
    display: block;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
	background-color:#efefef;
    border-radius: 5px;
    padding: .375rem .75rem;
    width: 100%;
    height: initial;
    height: initial;
    min-height: 44px;
}

.bordergroup{
	padding: 20px 0;
	border-top: 1px solid #ddd;
}

.careerform .check-career input{
	padding: 0;
	min-height: auto;
	display: inherit;
	width: auto
}

.careerform textarea {
    display: block;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
	background-color:#efefef;
    border-radius: 5px;
    padding: .375rem .75rem;
    width: 100%;
}

/**************************************************** CONTACT US CSS START ****************************************************/

.contact-banner {
	background: url(./img/contact-banner.jpg) no-repeat !important;
	background-position-x: 0%;
	background-position-y: 0%;
	background-attachment: scroll;
	background-size: auto;
	background-size: cover !important;
	background-attachment: fixed !important;
	background-position: top left !important;
	padding: 0 0 80px !important;
}

.contact-blue-banner {
	background:rgba(0, 173, 238, 0.4);
	padding: 87px 0;
	margin-bottom: 70px;
}

.contact-blue-banner h2 {
	color: #fff;
	text-transform: capitalize;
	font-size: 41px;
	margin: 0;
	text-align: center;
}

.border-div {
	box-shadow: 0 0 0 16px
	rgba(255, 255, 255, 0.5);
	border-radius: 8px;
	margin: 0 auto;
}

.contact-form {
	padding: 35px;
	background:#00adee;
	border-radius: 8px;
	width: 100%;
	margin: 0 auto;
	position: relative;
	z-index: 9;
}

.help-desk-col {
	border-bottom: 2px solid
	#0095da;
	padding-bottom: 32px;
}


.help-desk-col p, .help-desk-col a{
	color: #ffffff!important;
}

.help-desk-col p, .help-desk-col a{
	color: #ffffff!important;
}

.help-desk-col h2 {
	font-size: 30px;
	color:
			#fff;
	margin: 0 0 24px;
}

.help-desk-col h4 {
	font-size: 18px;
	color: #fff;
}

.help-desk-col h4 a{
	color: #fff;
}

.help-desk-bottom {
	margin: 32px 0 0;
	color:#fff !important;
}

.help-desk-bottom h2 {
	font-size: 24px;
	color:
			#fff;
	font-weight: normal;
	margin: 0 0 14px;
}

.help-desk-bottom p {
	margin: 0 0 18px;
}

.contact-from-right .btn{
	min-width: 110px;
	height: 40px;
}

.btn.btn-primary:hover, .btn.btn-primary:focus, .btn:hover, .btn:focus {
	background: #ec1c24 !important;
	border-color:#ec1c24 !important;
	box-shadow: none !important;
}

.contact-from-right label, .join-form-col label {
	color:
			#fff;
	font-size: 12px;
	margin: 0;
}

.contact-from-right .submit-btn .red-btn {
	background-color: #ec1c24;
	color:#fff;
	font-size: 12px;
	width: 140px;
	padding: 5px 0px;
	line-height: 26px;
	border: 2px solid#ec1c24;
	border-radius: 5px;
}

.contact-from-right input.form-control, .contact-from-right textarea.form-control{
	background-color: #fff !important;
}

/**************************************************** CORPORATE CSS START ****************************************************/

.corporate-stay-together {
	width: 100%;
	float: left;
	position: relative;
	padding: 100px 0 50px;
}

.corporate-stay-content {
	width: 100%;
	float: left;
}

.corporate-stay-content h3 {
	font-size: 50px;
	font-weight: bold !important;
}
.corporate-stay-content p {
	color:#6d6e70 !important
}

.corporate-landing-2 {
	width: 100%;
	float: left;
	position: relative;
}

.corporate-what {
	width: 100%;
	float: left;
	position: relative;
	margin: 0 0 100px;
}

.corporate-what .block-img {
	width: 100%;
}

.corporate-what h3 {
	font-size: 24px;
	margin: 0 0 15px;
}

.corporate-what p {
	font-size: 16px;
	margin: 0;
	color: #6d6e70 !important;
}

.corporate-welcome {
	width: 100%;
	float: left;
	position: relative;
	background-color: #007dc4;
	color:#fff;
	padding: 100px 0 0;
}

.corporate-welcome-bg {
	background-image: url(./img/corporate-welcome-bg.jpg);
	height: 320px;
	background-size: cover;
	width: 100%;
	background-position: center center;
	background-repeat: no-repeat;
}

.corporate-welcome h3 {
	font-size: 34px;
	font-weight: bold;
	margin: 0 0 15px;
	color: #fff;
}

.lets-spread-content h3, .lets-spread-content p, .corporate-welcome p {
	color:
			#fff !important;
}

.keep-emp-happy {
	width: 100%;
	float: left;
	position: relative;
	padding: 100px 0 0;
}


.keep-emp-happy h3 {
	font-size: 34px;
	margin: 0 0 15px;
	color: #34383b;
}
.keep-emp-happy p{
	color: #6d6e70 !important;
}


.keep-emp-block h4 {
	font-size: 20px;
	margin: 10px 0 15px;
}

.lets-spread {
	width: 100%;
	float: left;
	position: relative;
	margin: 100px 0 15px;
	background: #007dc4;
	padding: 0 0 30px;
}

.lets-spread-content {
	color:#fff;
	width: 100%;
	float: left;
}

.corporate-touch {
	width: 100%;
	float: left;
	position: relative;
	padding: 100px 0;
}

.corporate-touch h3 {
	font-weight: bold;
	font-size: 38px;
	margin: 0 0 25px;
	color:#34383b;
}

.corporate-touch p{
	color:  #6d6e70 !important;
}

.get-touch {
	background: #fff;
	border-radius: 10px;
	box-shadow: 0 0 30px rgba(0,0,0,.1);
	width: 100%;
	float: left;
	padding: 30px;
}

.get-touch .form-group {
	margin-bottom: 1rem;
}

.form-group label {
	position: relative;
	cursor: pointer;
	font-size: 14px;
	font-weight: 400;
	color: #212121;
	margin-bottom: 0;

}

.get-touch input {
	display: block;
	box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
	background-color:#efefef;
	border-radius: 5px;
	padding: .375rem .75rem;
	width: 100%;
	height: initial;
	height: initial;
	min-height: 44px;
}

.get-touch textarea {
	display: block;
	box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
	background-color: #efefef;
	border-radius: 5px;
	padding: .375rem .75rem;
	width: 100%;
}

.forgot-col a {
	/*color: #808184;*/
}

.forgot-col a:hover{
	text-decoration: none;
}

.btn:hover, .btn:focus{
	color: #fff;
	box-shadow: none;
}

.btn {
	background: #ec1c24;
	border-radius: 5px !important;
	border-left: 0;
	font-size: 15px;
	color: white;
	line-height: 26px;
	border: 2px solid#ec1c24;
	padding: 5px 18px 5px;
	font-weight: bold;
	display: inline-block;
	text-align: center;
}

.getapp {
	background: #fef3f4;
	padding-bottom: 0;
}

.getapp h2 {
	color:#34383b;
	font-size: 32px;
	margin-bottom: 15px;
}

.getapp p {
	color:#34383b;
	font-size: 18px;
	margin-bottom: 20px;
}

.launching-btn {
	background:  #007dc4;
	color: #ffffff;
	text-transform: uppercase;
	width: 270px;
	height: 50px;
	line-height: 54px;
	font-size: 18px;
	float: left;
	text-align: center;
	border-radius: 3px;
	margin-top: 20px;
}

.launching-btn:hover{
	color: #fff;
	text-decoration: none;
}

/**************************************************** HOW ITS WORK CSS START ****************************************************/

.work-banner {
	background: url('./img/work-banner.jpg') no-repeat center;
}

.how-work-section {
	padding: 90px 0 50px;
	position: relative;
	z-index: 9;
	width: 100%;
	float: left;;
}

.how-work-section .red-heading {
	font-weight: bold !important;
	margin-bottom: 30px !important;
}

.red-heading {
	color:
			#ed1c24;
	text-transform: uppercase;
}

.work-decription {
	padding-left: 90px;
}

.work-decription .blue-heading {
	font-size: 27px;
	color: #00aeef;
	font-weight: bold !important;
}

.work-decription p {
	font-size: 13px;
	color: #808184;
	line-height: 24px;
}

.custom-container {
	max-width: 920px;
	margin: auto;
	flex-wrap: wrap;
}

.work-left-padding .work-decription {
	padding-left: 0;
	padding-right: 90px;
}


.blog-section::before {
	position: absolute;
	content: '';
	background: url('./img/vender-png.png') no-repeat right top;
	background-size: auto;
	width: 100%;
	top: 45px;
	height: 100%;
	right: 60px;
	background-size: contain;
	z-index: -1;
	opacity: 0.7;
}


/**************************************************** ABOUT US PAGE CSS START ****************************************************/

.bg-gray{
	background: #f5f5f5;
}

.weekend-made-right-text h4 {
	color:#34383b;
	font-size: 25px;
}
.hometesti{
	font-weight: 400 !important;
}
.weekend-made-right-text p {
	color: #6d6e70 !important;
	font-size: 16px;
}
.custom-container {
	max-width: 920px;
	margin: auto;
	flex-wrap: wrap;
}
.weekend-made-right-img img {
	/*width: 100%;*/
	border-radius: 5px;
}

.experience-the-heart img {
	width: 100%;
	border-radius: 10px;
}

.making-your h5 {
	font-size: 25px;
	color: #34383b;
	line-height: 30px;
}

.experience-the-heart-text p {
	color: #6d6e70;
	font-size: 16px;
}

.center-banner {
	background: url(./img/banner-3.jpg) no-repeat center;
	background-size: auto;
	background-size: cover;
	width: 100%;
	min-height: 388px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 9;
	margin: 50px 0 0;
}

.center-banner::before {
	content: '';
	background-color: rgba(0, 0, 0, 0.3);
	width: 100%;
	height: 100%;
	top: 0;
	position: absolute;
}
.user-test-detial {
	display: flex;
	justify-content: center;
}



/**************************************************** LETS PLAN CSS START ****************************************************/

.letsplan{
	width:100%;
	background: #F5F5F5;
	padding: 70px 0;
}

.row.planday.planday-mobile {

	margin-bottom: 0 !important;

}

.lets-plan-title{
	margin-bottom: 50px;
}

.letsplan .text-center h2 {
	font-size: 32px;
	color: #34383b;
	margin-bottom: 20px;
}

.letsplan .text-center p {
	font-size: 18px;
	color: #34383b;
	margin:0;
}

.letsplan .white-box {
	background:#fff;
	width: 100%;
	padding: 32px 30px;
	box-shadow: 0 0 30px rgba(0, 0, 0, .1);
	border-radius: 10px;
}

.white-box{
	background: #fff;
	width: 100%;
	padding: 32px 50px;
	box-shadow: 3px 0 30px rgba(0,0,0,0.1);
}

.planday h2 {
	font-size: 24px;
	font-weight: 700 !important;
	color: #34383b;
	margin-bottom: 20px;
}

.plan-col{
	margin-bottom: 40px;
}

.btn-days .btn:first-child {
	margin-left: 0 ;
}

.btn-days .btn {
	margin: 0 5px;
	background-color: transparent !important;
	color: #34383b;
	font-weight: 300;
	border-radius: 50px !important;
	padding: .375rem 1.75rem;
	font-size: 16px;
	cursor: pointer;
	border-color: #6c757d;
	border-width:1px;
}

.btn-days .btn-secondary:hover{
	color: #EC1C24 ;
	border-color:#EC1C24;
}

.btn-days .btn-secondary:not(:disabled):not(.disabled).active{
	background-color: #EC1C24 !important;
	border-color:#EC1C24;
}

.btn-days .btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-days .btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-days .show > .btn-secondary.dropdown-toggle:focus{
	box-shadow: none !important;
}

.mood-ul {
	padding: 0 5px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	list-style-type: none;
	width: 87%;
}

.mood-ul .item {
	position: relative;
	color: #231F20;
	font-size: 14px;
	text-align: center;
	width: 112px;
	min-height: 171px;
	border: 1px solid#ddd;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 10px;
	justify-content: center;
	cursor: pointer;
	padding: 10px;
}

/* .mood-ul .owl-item {
	margin: 0 7px !important;
	width: auto !important;
}
 */
/* .mood-ul .owl-carousel .owl-stage-outer{
	width: 82%;
margin: 0 auto;
} */

.listing-slider .owl-carousel .owl-stage-outer{
	min-height: 200px !important;
	height: 200px !important;
}
.mood-ul .owl-theme .owl-nav {

	top: -50%;
	z-index: 9;
	position: relative;

}

.mood-ul .item img {
	margin: 45px 0 0px;
	width: 50% !important;
}

.mood-ul .item p {
	margin: 15px 0 0;
	line-height: 19px;
}

.mood-ul li input {
	position: absolute;
	top: 10px;
	left: 10px;
}

.mood-ul .item:hover{
	border-color: #EC1C24;
	box-shadow: 0px 8px 11px rgba(0,0,0,0.1);
}

.mood-ul .item img:hover {
	border-color: #EC1C24;
}

.mood-ul .item .check-mark {
	position: absolute;
	top: 10px;
	left: 10px;
	border:1px solid #ddd;
	border-radius: 50%;
	width: 23px;
	height: 23px;
	color: #fff;
}

.mood-ul .item .check-mark .fas{
	color: #fff;
}

.mood-ul .item:hover .check-mark {
	color: #fff;
	background: transparent;
	border-color: #EC1C24;
}



.mood-ul .item.active .check-mark {
	color: #fff;
	background: #EC1C24;
	border-color: #EC1C24;
}

.play-weekend {
	background: #00ADEE;
	max-width: 327px;
	width: 100%;
	height: 48px;
	display: flex;
	margin: 0 auto;
	color:#fff !important;
	line-height: 28px;
	justify-content: center;
	font-size: 18px;
	border-radius: 4px;
	border:none;
}

/**************************************************** Filter CSS START ****************************************************/
.listing-page-title {
	padding: 15px 0;
	background: #f5f5f5;
}
.listing-page-title ul{
	list-style-type:none;
	padding: 0;
	margin: 0;
}

.listing-page-title ul li:first-child{
	padding-left: 0;
}

.listing-page-title ul li {
	float: left;
	padding: 0 0 0 5px;
	color:#858688;
}

.listing-page-title ul li a{
	color:#00adee;
}

.listing-page-title h2 {
	font-size: 30px;
	font-weight: 700 !important;
	color: #34383b;
	margin: 10px 0;
}

.listing-page-title p{
	margin: 0;
	font-size: 14px;
	color:#858688;
}

.MuiMenuItem-root{
	font-size: 14px;
}
.price-range .active.btn {
	color: rgba(0,0,0,0.84);
	font-size: 13px;
}

.filters-bg {
	width: 100%;
	float: left;
	background:#f5f5f5;
	padding:20px 0;
	border-bottom: 1px solid #ddd;
	border-top: 1px solid #ddd;
}
.fillter-sec .blue-btn.btn.btn-primary {
	float: left;
	margin: 0 0 0 6px;
	border-radius: 20px !important;
}

.fillter-sec div.MuiFormControl-root {
	margin:0 3px;
	float: left;
}

.fillter-sec .MuiFormLabel-root.MuiFormLabel-filled{
	display: none;
}
.fillter-sec .MuiFormLabel-root {
	font-size: 14px !important;
	margin: 0 0 0;
	line-height: 7px;
	position: absolute;
	transform: inherit;
	top: 12px;
	left: 10px;
	z-index: 1;
}

.fillter-sec div.MuiFormControl-root.active .MuiInput-formControl{
	border:none;
	background: #fff;
}



.fillter-sec label + .MuiInput-formControl{
	margin: 0;
	/* border: 1px solid#ddd; */
	border-radius: 20px;

}

.price-text .input-group-text {
	font-size: 14px;
	color: rgba(0, 0, 0, 0.54);
	border-radius: 3px !important;
}

.price-text .input-group {
	margin-bottom: 0 !important;
}

.fillter-sec label + .MuiInput-formControl .MuiSelect-selectMenu{
	z-index: 99;
	font-size: 14px;
	padding-left: 12px;
}

.fillter-sec label + .MuiInput-formControl .MuiSelect-selectMenu:focus{
	background: transparent;
}

.fillter-sec label + .MuiInput-formControl .MuiSelect-selectMenu:after{
	position: absolute;
	content: '';
	background-image: url(./img/select-arrow.png);
	background-repeat: no-repeat;
	top: 12px;
	right: 5px;
	z-index: 99999999;
	width: 13px;
	height: 30px;
}

.fillter-sec label + .MuiInput-formControl::before, .fillter-sec label + .MuiInput-formControl:after{
	display: none;
}




/*.fillter-sec div.MuiFormControl-root:nth-child(1){*/
	/*min-width: 130px;*/
	/*max-width: 130px;*/
	/*margin-left: 0;*/
/*}*/

/*.fillter-sec div.MuiFormControl-root:nth-child(2),*/
/*.fillter-sec div.MuiFormControl-root:nth-child(3){*/
	/*min-width: 110px;*/
	/*max-width: 110px;*/
/*}*/

/*.fillter-sec div.MuiFormControl-root:nth-child(4){*/
	/*min-width: 115px;*/
	/*max-width: 115px;*/
/*}*/

/*.fillter-sec div.MuiFormControl-root:nth-child(5){*/
	/*min-width: 165px;*/
	/*max-width: 165px;*/
/*}*/

/*.fillter-sec div.MuiFormControl-root:nth-child(6),*/
/*.fillter-sec div.MuiFormControl-root:nth-child(7){*/
	/*min-width: 100px;*/
	/*max-width: 100px;*/
/*}*/

.fillter-sec div.MuiFormControl-root:nth-child(7) .MuiSelect-selectMenu{
	text-overflow: clip;
}


.MuiListItem-root {
	padding-bottom: 0 !important;
}

.MuiCheckbox-root {
	color:rgba(0, 0, 0, 0.54);
	padding: 0 9px 0 0 !important;
}

.MuiListItemText-root .MuiTypography-root{
	font-size:14px;
}


.fillter-sec .MuiSvgIcon-root{
	font-size: 14px;
}

.fillter-sec .MuiSvgIcon-root.MuiSelect-icon {
	display: none !important;
}

.fillter-sec .MuiInput-underline:hover:not(.Mui-disabled)::before {
	border-bottom: 2px solid #42abc8;
}



.price-range{
	width: auto;
	display: inline-flex;
	margin: 0 0 0 3px;
	float: left;
}

.price-range.active{
	background: #fff;
	border-radius: 20px;
}

.price-range .btn {
	position: relative;
	background:transparent!important;
	border-width: 0;
	color:rgba(0, 0, 0, 0.54);
	font-size: 14px;
	font-weight: normal;
	text-align: left;
	padding: 3px 15px 5px 9px;
	width: 145px;
	height: 34px;
	margin:  0;
}

.price-range .btn:hover{
	border-color: transparent;
	border-width: 0;
}


.price-range .btn:focus{
	box-shadow: none;
}

.price-range .btn:after{
	position: absolute;
	content: '';
	background-image: url(./img/select-arrow.png);
	background-repeat: no-repeat;
	top: 12px;
	right: 5px;
	z-index:99;
	width: 13px;
	height: 30px;
}

.price-range .btn svg{
	display: none;
}

.blue-btn.btn.btn-primary {

	display: inline-flex;
	background: #00adee;
	color:#fff;
	border: none;
	height: 33px;
	margin: 23px 0 0;
	line-height: 30px;
	float: left;

}
.MuiCheckbox-colorSecondary.Mui-checked {
	color: #00adee !important;
}

.fliter-mobile{
	display: none;
}

/**************************************************** LISTING CSS START ****************************************************/

.listing-section {
	width: 100%;
	float: left;
	background: #f5f5f5;
	padding:10px 0 40px 0 !important;
	position: relative;
}



.img-form-text {
	margin: 30px 0px 0;
}

.icon-and-img {
	border-radius: 10px;
	overflow: hidden;
	box-shadow: none!important;
}
.listing-img {
	position: relative;
}

.item-info-box {
	padding:20px;
	background:  #fff;
	width: 100%;
	float: left;
}
.item-info-box a{
	color:rgb(72, 72, 72);
}
.img-form-text a:hover {
	text-decoration: none;
}
.item-info-box a:hover{
	text-decoration: none;
	color: rgb(72, 72, 72);
}

.promobox button.btn.btn-primary:hover, .promobox button.btn.btn-primary:focus{
	background: #00adee;
	border-color:#00adee;
	box-shadow: none !important;
}

.icon-and-img .jb-text {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	font-weight:600;
	font-size: 21px;
	margin: 0;
	float: left;
	color: #34383B;
}

.order-total {
    float: left;
    padding: 20px 15px 0px;
    width: 100%;
    margin-left: 0;
}

.bj-text {
    width: 100%;
    float: left;
}

.pett-text-1 {
	width: 100%;
	float: left;
	display: flex;
	justify-content: space-between;
	margin: 10px 0 0;
}

.ettol-text {
	color: #858688;
	font-size: 12px;
	padding-top: 0;
	margin: 0px;
	font-weight: 600;
	width: 100%;
	float: left;
}

.ettol-text i{
	color: #29abe2;
	font-size: 12px;
}
.lad-text {
	font-size: 10px;
	margin: 0;
	color: rgb(72, 72, 72);
}

.lad-text i{
	color: #127EC2;
	font-size: 12px;
}

.price-ditel {
	font-size: 18px;
	color: #6d6e70;
	line-height: 15px;
	padding-top: 10px;
	margin: 0px;
}

.list-rating {
	float: left;
	height: 18px;
	width: 100%;
}
.list-rating .MuiSvgIcon-fontSizeSmall {
	font-size: 13px;
	height: auto;
	line-height: 16px;
	vertical-align: top;
}
.list-rating > i {
	float: left;
	margin: 3px;
	color:#29abe2 !important;
}
.button-buy {

	padding: 2px 10px 0px;
	color:white !important;
	background-color:#00ADEE !important;
	border: 1px solid#00ADEE;
	border-radius: 5px;
	margin-bottom: 5px;
	font-size: 14px;
	width: auto;
	height: 35px;
	text-align: center;
	line-height: 30px;

}

.listing-img .item-share{
	left: 16px;
}

.listing-img .carousel-indicators li{
	width: 10px;
	height: 10px;
	background-color: #818282;
}

.listing-img .carousel-indicators li.active{
	background-color:#26abe2;
}

.listing-img .carousel-control-next-icon{
	background-image:url(./img/sliderarrow-right.png);
	width: 17px;
	height: 27px;
}

.listing-img .carousel-control-prev-icon{
	background-image:url(./img/sliderarrow-left.png);
	width: 17px;
	height: 27px;
}
.carousel {
	position: relative;
	margin: 0px 0 0;
}
.home-slideshow-top .carousel-control-next-icon, .home-slideshow-top .carousel-control-prev-icon{
	margin-top: 0px;
}


.home-slideshow-top .carousel-control-next-icon{
	background-image:url(./img/sliderarrow-right.png);
	width: 42px;
	height: 56px;
}

.home-slideshow-top .carousel-control-prev-icon{
	background-image:url(./img/sliderarrow-left.png);
	width: 42px;
	height: 56px;
}

.abotslider .carousel-control-next-icon{
	background-image:url(./img/arrow-black.png);
	width: 26px;
	height: 42px;
	opacity: 9 !important;
	background-repeat: no-repeat;
	background-size: 16px;
}

.testimonial .carousel-control-next, .carousel-control-prev{
	opacity: 0.9 !important;
}

.abotslider .carousel-control-prev-icon{
	background-image:url(./img/arrow-black-2.png);
	width:26px;
	height: 42px;
	opacity: 9 !important;
	background-repeat: no-repeat;
	background-size: 16px;
}

.carousel-indicators li {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	transition: all 0.3s;
	margin-right: 3px;
	margin-left: 3px;
	text-indent: -999px;
	cursor: pointer;
	background-color:#fff;
	background-clip: padding-box;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	opacity: .5;
	flex: 0 1 auto;
	box-sizing: content-box;
}

.carousel-indicators .active {
	opacity: 1;
	background-color: #26abe2;

}

.search-sec-img {
	/*background: url('./img/work-banner.jpg') no-repeat center;*/
	background-color: rgb(0, 125, 196);
	background-size: cover;
	background-repeat: no-repeat;
	padding: 50px 0;
	width: 100%;
	position: relative;
	z-index: 0;
}

.search-sec-img:after {
	position: absolute;
	content: '';
	width: 100%;
	top: 0;
	bottom: 0;
	height: 100%;
	background: rgba(0, 0, 0, 0.3);
	z-index: -1;
}

.catagory-top-img {
	background-size: 100%;
	background-repeat: no-repeat;
	padding: 162px 0;
	width: 100%;
	position: relative;
	z-index: 0;
    background-position-y: center ;
}

.catagory-top-img:after {
	position: absolute;
	content: '';
	width: 100%;
	top: 0;
	bottom: 0;
	height: 100%;
	/* background: rgba(0, 0, 0, 0.3); */
	z-index: -1;
}

.item-share {
	cursor: pointer;
	position: absolute;
	left: 20px;
	top: 18px;
	height: 25px !important;
	width: 25px !important;
	z-index: 99;

}

.item-share a svg, .share-icon svg, .item-share i svg {
	height: 24px !important;
}

.item-share button {
	color:#fff;
	font-size: 22px;
	background: transparent;
	border: none;
	padding: 0;
}

.item-share:hover .share-hover {
	display: block;

}

.detail-share-icon:hover .detail-share-hover {
	display: block!important;

}

.detail-share-hover {

	position: absolute;padding: 0!important;margin: 0!important;top: 100%!important;left: 0;
}

.share-hover {
	display: none;
	z-index: 10000;
}
.share-hover ul {
	padding: 0;
	margin: 0;
	list-style-type: none;
}

.share-hover ul li {
	position: relative;
	z-index: 10000;
}

.share-hover ul li button{
	background: transparent;
	border: none;
	z-index: 10000;

}

.share-hover ul li img {
	width: 25px;
	border-radius: 0px !important;
	margin: 0 0 5px;
	z-index: 10000;

}

.icon-and-img .fav-btn {
	position: absolute;
	right: 25px;
	top: 10px;
	height: 25px !important;
	width: 25px !important;
	z-index: 99;;
}

.fav-btn a {
	color:#fff;
	font-size: 22px;
}

.fav-btn a:hover{
	color:#d72d31;
}

.icon-and-img .carousel-item img {
	height:100%!important;
	object-fit: cover;
}

.icon-and-img .carousel-item, .icon-and-img .listing-img{
	height: 253px;
	overflow: hidden;
	position: relative;
}
.icon-and-img .listing-img .carousel {
	position: relative;
	margin: 0;
}

.keep-revisitng{
	position: relative;
}

.keep-revisitng h4{
	font-size: 22px;
	color: #007dc4;
}

.keep-revisitng{
	/*background-image: url(./img/more-listing-bg.png);*/
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center bottom;
	min-height: 200px;
}



/**************************************************** NEWSLETTER CSS START ****************************************************/

.newsletter{
	background: #00adee;
}

.newsletter h2 {
	color:#fff;
	font-size: 32px;
	margin: 0 0 20px;
}

.newsletter p{
	color:#fff;
	font-size: 21px;
	opacity: 0.85;
	margin: 0;
}

.newsletter-form{
	text-align: center;
}

.newsletter-form input{
	background: transparent;
	border-width:0 0 1px 0;
	border-style: solid;
	border-color:#fff;
	color: #fff;
	text-align: center;
	width: 100%;
	height: 40px;
}

.newsletter-form button{
	background: #FFFFFF;
	border-radius: 4px;
	border:none;
	color: #EC1C24;
	font-size: 16px;
	width: 100%;
	height: 46px;
	margin: 18px 0 0;
}

/**************************************************** HOME PAGE PERSONALISEDWEEKEND CSS START ****************************************************/

.block-title h2{
	color:#EC1C24;
	font-weight: 700 !important;
	margin-bottom: 20px;
}

.block-title p {
	font-size: 21px;
	margin:0;
}

.activites {
	position: relative;
	width: 100%;
	display: flex;
	margin: 6px 0 0;
}

.activites .image {
	transition: all 0.5s;
	overflow: hidden;
	border-radius:0;
	height: 301px;
	width: 100%;
}

.owl-theme .owl-nav .owl-prev {
	float: left;
	margin-left: -0px !important;
}

.owl-theme .owl-nav .owl-next{
	margin-right:-0px !important;
}

.mood-ul .owl-theme .owl-nav .owl-prev {
	float: left;
	margin-left: -55px !important;
}

.mood-ul .owl-theme .owl-nav .owl-next{
	margin-right:-55px !important;
}

.tranding-section .owl-theme .owl-nav .owl-prev {
	float: left;
	margin-left: -60px !important;
}

.tranding-section .owl-theme .owl-nav .owl-next{
	margin-right: -60px !important;
}
.seetext {
	text-transform: uppercase;
	float: right;
	font-weight: 600;
	color: #00adee;
}

.activites .image img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 10px;
}

.newsletter-form input:-webkit-input-placeholder { /* Edge */
	color: #fff;
}

.newsletter-form  input:-ms-input-placeholder { /* Internet Explorer */
	color: #fff;
}

.newsletter-form input::placeholder {
	color: #fff;
}

.PersonalisedWeekends .owl-theme .owl-nav{
	top: 29%;
	z-index: -1;
}

.activites .image::after {
	display: block;
	position: relative;
	background-image: linear-gradient(to bottom,
	rgba(255, 255, 255, 0) 0,
	#000 150%);
	margin-top: -150px;
	height: 150px;
	width: 100%;
	content: '';
	border-radius: 11px;
}

.activity-text {

	position: absolute;
	bottom: 30px;
	left: 30px;

}
.activity-text h4 {

	color: #fff;
	font-size: 30px;
	font-weight: 500 !important;
	margin-bottom: 20px;

}

.activity-text a {
	width: 140px;
	height: 50px;
	background: #fff;
	border-radius: 25px;
	display: inline-block;
	color: #00ADEE;
	font-size: 18px;
	line-height: 52px;
	text-align: center;
}

.activity-text a img{
	margin-top: -4px;
}

.activity-listing {
	width: 100%;
	position: relative;
	margin: 0 0 41px;
}

.activity-listing h3 {
	color: #34383b;
	font-size: 24px;
	margin-bottom: 12px;
}

.activity-listing p {
	opacity: 0.5;
	font-size: 18px;
	line-height: 22px;
}


.listing-slider {
	padding: 0 60px;
}

.listing-slider .owl-theme .owl-nav .owl-prev {
	float: left;
	margin-left: -55px !important;
}

.listing-slider .owl-theme .owl-nav .owl-next {
	margin-right: -55px !important;
}

.owl-dots{
	display: none;
}

.owl-theme .owl-nav {
	margin-top: 0;
	text-align: center;
	position: absolute;
	top: 17%;
	width: 100%;
}


.owl-theme .owl-nav button{
	background:#FFFFFF !important;
	box-shadow: 0px 0 20px rgba(0,0,0,0.1) !important;
	opacity: 1;
	width: 48px;
	height: 48px;
	border-radius: 50% !important;

}

.owl-theme .owl-nav button:hover{
	color: #34383b !important;
}
.owl-theme .owl-nav .owl-prev{
	float: left;
}
.owl-theme .owl-nav .owl-next{
	float: right;
}

.owl-theme .owl-nav .owl-prev span{
	background-image: url(./img/owl-arrow-left.png);
	font-size: 0px;
	width: 11px;
	height: 18px;
	margin: 0 auto;
	display: inherit;
}

.owl-theme .owl-nav .owl-next span{
	background-image: url(./img/owl-arrow-right.png);
	font-size: 0px;
	width: 11px;
	height: 18px;
	margin: 0 auto;
	display: inherit;
}



.owl-theme .owl-nav .disabled {
	opacity: 0 !important;
	cursor: default;
}

.learn-more{
	background: #ec1c24;
	border-radius: 4px;
	height: 48px;
	width: 100%;
	line-height: 48px;
	text-align: center;
	display: flex;
	justify-content: center;
	color:#fff;
	margin:10px 0 0;
	font-size: 18px;
	font-weight: 600;
}

/**************************************************** SLIDER HOME ****************************************************/


.carousel-indicators li {
	width: 12px;
	height: 12px;
	border-radius: 50%;
	padding: 0;
}

.carousel-caption {
	top: 50%;
	transform: translate(-50%, -50%);
	left: 50%;
	width: 100%;
	padding: 0;
	display: flex !important;
	align-items: center;
	height: calc(100% - 160px);
}

.banner-image {
	text-align: left;
}

.banner-image img {
	max-width: 30%;
}
.carousel-control-next, .carousel-control-prev {
	z-index: 99;
}


/**************************************************** BOOKING DETAIL PAGE CSS START ****************************************************/
.product-title-booking h2{
	font-size: 21px;
	color:#34383b;
	font-weight: 600;
}

.product-title-booking .price {
	color:#34383b;
	text-align: right;
	font-weight: 600;
	font-size: 21px;
}


.checkout-section {
	color:#858688;
	font-weight: 300;
}
.checkout-section .product-img {
	border-radius: 5px;
	overflow: hidden;
	height: 110px;
}


.checkout-section div{
	font-weight: 300;
}

.checkout-section i {
	font-size: 18px;
	margin: 0 6px 0 0;
	color: #858688;
}

.checkout-section .product-img img {
    width: 100%;
    height: 110px;
    object-fit: cover;
}

.line-through{
	text-decoration: line-through;
}

.or-price .line-through{
	color: #858688;
	font-weight: 500;
}

.or-price{
	color:#007dc4;
	text-align: right;
}

.final-price p{
	margin:0 0 5px;
}

.final-price p.font-21{
	font-size: 21px;
	font-weight: 600;
}

.text-blue{
	color: #00adee;

}

.promobox .text-blue{
	color: #00adee;
	text-decoration: underline;
	margin: 0 0 15px;
}

.promobox .form-group{
	margin: 0;
}

.promobox input{
	background: #fff;
	border-radius:5px;
	height: 40px;
	border:1px solid #ddd;
	width: 100%;
	max-width: 290px;
	padding: 0 5px;
}
.promobox button.remove {
	background: transparent;
	color:#dc3545;
	padding: 0;
	width: auto;
	height: auto;
}

.promobox.promobox-apply p img {
	vertical-align: top;
	margin-top: -2px;
}

.promobox button{
	background: #00adee;
	border: none;
	border-radius: 5px;
	color: #fff;
	height: 39px;
	width: 100px;
	margin: 0 0 0 21px;
	font-weight: 500;
}

.primaryguestform form {
	padding: 0;
	border-bottom: 1px solid #ddd;
	margin: 0 0 24px;
	width: 100%;
	float: left;
}

.primaryguestform .hw-checkbox .checkbox label {
	font-size: 16px;
}

.primaryguestform label {
	margin: 0 0 5px;
	color:  #34383b;
	font-weight: 400;
	width: 100%;
}

.primaryguestform .form-control{
	background:#efefef;
	height: 45px;
	border: none;
}

.phone-block .custom-select {
	width: 100px;
	color:  #ccc;
	position: relative;
}

.phone-block .custom-select:before{
	content: '\f107';
	position: absolute;
	top:0;
	right: 0;
	font-family: 'FontAwesome';
}

.phone-block input{
	width: 100%;
	max-width: 220px;
}

.custom-select{
	background-image: url(./img/select-arrow.png) !important;
	background-repeat: no-repeat !important;
	background-color:#efefef;
	height: 45px;
	border: none;
	background-position-x: 95% !important;
	background-position-y: center !important;
	background-size: 13px;
	padding: 0.205rem 1.75rem .375rem .75rem;
}


.primaryguestform h4 {
	font-size: 22px;
	height: 48px;
	background: #007dc4;
	text-align: center;
	line-height: 47px;
	color: #fff;
	border-radius: 5px 5px 0 0;
	margin: 0 0 15px;
}

.product-dis-list span {
	margin: 4px 0 0 5px;
	float: left;
}
.product-dis-list p {
	margin: 4px 0 0 5px;
}
.product-dis-list img {
	float: left;
}

.checkbox label {
	cursor: pointer;
	display: inline-block;
	position: relative;
	font-size: 14px;
	padding-left: 25px; /* 10 px padding right + 15px input type*/
	margin-right: 10px; /* margin between inputs types */
	color: #34383b;
}

.checkbox label span {
	width: 100%;
	font-size: 12px;
	float: left;
}
.checkbox label:before {
	content: "";
	width: 15px;
	height: 15px;
	position: absolute;
	left: 0;
}

.checkbox input[type=checkbox] {
	display: none;
}

.checkbox label:before {
	width: 20px;
	height: 20px;
	border: 1px solid #ddd;
	border-radius: 2px;
	margin-top: 2px;
}

.checkbox input[type=checkbox]:checked + label:before {
	background-size: 18px !important;
	background: url('./img/check.png') left center no-repeat;
}

.address-userinfo {
	padding: 10px 15px 20px;
	color:#34383b;
	font-weight: 400 !important;
	border-bottom: 1px solid #ddd;
}

.address-userinfo div i {
	color: #34383b;
	padding: 2px 0 0;
	float: left;
}

.address{
	padding: 10px 15px 20px;
}

.address-info {
	background: #f5f5f5;
	border: 1px solid#ddd;
	border-radius: 5px;
	padding: 0;
	color: #34383b;
	float: left;
	width: 100%;
	position: relative;
}

.address-delete {
	background:transparent;
	border: none;
	position: absolute;
	right: -10px;
	color:#8A8B8D;
	top: 4px;
	z-index: 99;
	font-size: 13px;
}

.address-delete i{
	color:#8A8B8D;
	font-size: 14px;
}

.address-info .form-check{
	padding: 11px 11px 8px 30px;
	cursor: pointer;
}

.font-16{
	font-size: 16px;
}
A
.add-address i {
	color: #00adee;
	font-size: 10px;
	border: 1px solid#00adee;
	border-radius: 50%;
	padding: 2px 3px;
	margin-top: 5px;
	float: left;
}
.add-address img {
	margin: -2px 5px 0 0;
}
.add-address {
	color: #00adee;
	font-size: 16px;
	margin: 10px 0;
	width: 100%;
	float: left;
	text-align: left;
	padding: 0;
}
.newaddress {
	background: #f9f9f9;
	width: 100%;
	float: left;
	padding: 15px 0;
}
.newaddress .blue-btn.btn.btn-primary {
	margin: 0;
	height: 40px;
}
.procedd-block {
	width: 100%;
	float: left;
	padding: 15px;
	border-top: 1px solid #ddd;
	margin: 20px 0 0;
}

.primaryguestform .red-btn{
	width: 100%;
	height: 50px;
	font-size: 18px;
}

/***************************************************     ORDER SUCCESS PAGE START      ***************************************************/

.success-order {
	width: 100%;
	position: relative;
	padding:80px 0;
	background:#f5f5f5;
}

.order-summary.white-box, .user-address.white-bg {
	box-shadow: 3px 2px 10px rgba(0, 0, 0, 0.2);
	border: 1px solid#ddd;
}
.user-detail p {
	font-size: 13px;
	color: #6d6e70;
	font-weight: 300;
}
.share span {
	float: left;
	margin: 2px 12px 0 0;
}

.success-order h2 {
	font-size: 18px;
}

/* LOGIN/SIGNUP CSS START */

.signup-banner, .signin-banner{
	background-attachment: scroll;
	background-repeat: no-repeat;
	background-size: auto;
	background-attachment: fixed;
	background-size: cover;
	min-height: 700px;
	width: 100%;
	display: inline-block;
	padding: 100px 0 80px;
}

.signup-banner.host-new{
	background-image: url('./img/signin-banner.jpg');
}

.signup-banner{
	background-image: url('./img/signup2-banner.jpg');
}

.signin-banner {
	background: url('./img/signin-banner.jpg') no-repeat center;
}

.signup-banner .form-heading.text-center {
	max-width: 100%;
	text-align: center;
}

.partner-form .custom-file .custom-file-label {
	position: absolute !important;
}


.btn.signup-link:hover {
	color: #ec1c24!important;
	padding: 0;
	background: transparent!important;
	border: none;
	line-height: 18px;
}


.btn.signup-link:hover {
	color: #ec1c24!important;
	padding: 0;
	background: transparent!important;
	border: none;
	line-height: 18px;
}
.price-range button:hover{


	background: transparent!important;
}
.signup-banner textarea.form-control {
	resize: none;
	-webkit-resize: none;
	height: auto;
	background-color: #e5e6e7;
}

.signup-form {
	background-color: #fff;
	border-radius: 10px;
	padding: 24px 16px;
	box-shadow: 0 0 17px #212121;
	float: left;
}

.signup-banner .form-col-banner {
	background: url('./img/signup-dark-banner.jpg') no-repeat -110px;
	background-size: cover;
}

.form-col-banner {
	width: 50%;
	background: url('./img/dark-banner.jpg') no-repeat 0px center;
	background-size: cover;
}

.signin-banner .form-col-left, .signup-banner .form-col-left{
	width: 50%;
	background-color:#fff;
	padding: 35px 20px 40px;
	min-height: 629px;
}

.signin-banner .form-heading, .signup-banner .form-heading {
	margin-bottom: 30px;
	max-width: 100%;
	margin-right: 0;
	color: #00adee;
	font-size: 22px;
	line-height: 20px;
}

.login .btn.btn-signup:hover {
	background:
			#007dc4!important;
	border-color:
			#007dc4!important;
}

.login .btn.btn-signup {
	background: #007dc4;
	border-color:#007dc4;
	margin: 0 10px 0px 0px;
}

.btn.btn-signup {
	background: #007dc4;
	border-color:#007dc4;
	margin: 0 10px 0px 0px;
}

.login.signin-banner  .btn.btn-signup {
	margin: 0 0 0px 10px;
}


.signin-banner .forgot-col, .signup-banner .forgot-col {
	flex-direction: column;
	align-items: center;
}

.or {
	color: #808184;
	font-size: 14px;
	text-align: center;
	margin-top: 10px;
}

.fb-login-btn {
	margin-top: 15px;
}

.fb-login-btn .fb-btn{
	background:  #3a559e!important;
	color:#fff;
	font-size: 16px;
	border-radius: 5px;
	max-width: 247px;
	width: 100%;
	padding: 6px 10px 6px 22px;
	border: none;
	margin: 0;
	font-weight: 300;
	background-size: 11px;
	position: relative;
}


.fb-login-btn .fb-btn:hover {
	background:  #3a559e!important;
	color:#fff;

}

.email-input-spinner{position: absolute;top: 50%;left: 88%;}

.fb-login-btn .fb-btn::before {
	content: "";
	box-sizing: border-box;
	position: absolute;
	top: 0;
	left: 0;
	width: 44px;
	height: 100%;
	/*border-right:#000 1px solid;*/
	background: url('./img/fb-btn-icon.png') no-repeat ;
	background-position: 14px 6px;
}

.loginBtn {
	box-sizing: border-box;
	position: relative;
	padding: 0 15px 0 46px;
	border: none;
	text-align: left;
	line-height: 34px;
	white-space: nowrap;
	font-size: 16px;
	color:#FFF;
	font-family: "Roboto", Roboto, arial, sans-serif;
	background:#DD4B39;
	padding: 2px 58px;
	border-radius: 5px;
	max-width: 247px;
	width: 100%;
	margin: 0 auto;
}


.loginBtn::before {
	content: "";
	box-sizing: border-box;
	position: absolute;
	top: 0;
	left: 0;
	width: 44px;
	height: 100%;
	border-right:#BB3F30 1px solid;
	background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png') no-repeat;
	background-position: 8px 8px ;
}
.partner-form h4 {
	font-size: 14px;
}
.signup-form label {
	margin-bottom: 0px;
	position: relative;
	opacity: 0.5;
}

button.close {
    position: absolute;
    right:0;
    top: 0;
    z-index: 99;
    width: 30px;
    height: 30px;
}

.input-group-prepend {
	margin-right: -1px;
	position: absolute;
	z-index: 9;
	height: 44px;
}

.input-group.phone_no input {
	padding-left: 68px;
}

input.form-control {
	border-radius: 5px !important;
}

.form-control:focus {
	color: #495057;
	background-color:#e5e6e7;
	outline: 0;
	box-shadow: none;
}

.res-row .form-group label {
	color: #212121;
}

.res-row .form-group .checkbox label:before {
	opacity: 0.9;
}

.signup-two .form-col-left div {
	position: relative;
	border-bottom: 1px dotted black;
	display: inline;
}

.tooltips, .tooltips i{
	font-size: 16px;
	position: relative;
	display: inline;
}

.tooltips .tooltiptext {
	display: none;
	visibility: visible;
	width: 400px;
	background-color: white;
	color:#000;
	text-align: center;
	border-radius: 6px;
	padding: 5px;
	position: absolute;
	z-index: 1;
	bottom: 100%;
	right: -350%;
	margin-left: 0;
	opacity: 0;
	transition: opacity 1s;
	z-index: 9999;

}

.tooltips:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
	display: block;
}
.signup-two .form-col-left div {
	position: relative;
}

.custom-radio label {
	width: auto;
	padding: 0 24px;
}

.custom-control {
	padding-left: 0;
	display: block;
	min-height: 1.5rem;
}



.custom-radio [type="radio"]:checked, .custom-radio [type="radio"]:not(:checked) {
	position: absolute;
	left: -9999px;
}
.custom-radio [type="radio"]:checked + label, .custom-radio [type="radio"]:not(:checked) + label {
	position: relative;
	cursor: pointer;
	line-height: 20px;
	display: inline-block;
	color: #666;
	margin-bottom: 0px;
	margin-right: 20px;
	padding-left: 22px;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before {
	background-color: transparent !important;
	border-color: rgb(220, 53, 69);
}
.custom-radio [type="radio"]:checked + label:before, .custom-radio [type="radio"]:not(:checked) + label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 18px;
	height: 18px;
	border: 1px solid #ddd;
	border-radius: 100%;
	background: #fff;
	margin-bottom: 0px;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
	background-color: transparent !important;
}
.custom-radio [type="radio"]:checked + label:after, .custom-radio [type="radio"]:not(:checked) + label:after {
	content: '';
	width: 10px;
	height: 10px;
	background: #007bff;
	position: absolute;
	top: 4px;
	left: 4px;
	border-radius: 100%;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
	background-image: none !important;
}
.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
	color: #34383b;
}
.custom-radio [type="radio"]:not(:checked) + label:after {
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}
.custom-radio [type="radio"]:checked + label:after {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}



/******************************************     BLOG LISTING PAGE START     ******************************************/

.blog-bg{
	background: url('./img/blog-banner.jpg') no-repeat center;
	background-size: auto;
	background-size: cover;
	width: 100%;
	min-height: 298px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 0;
}

.blog-section {
	padding: 100px 0 50px;
	position: relative;
	z-index: 9;
}

.blog-section::before {
	position: absolute;
	content: '';
	background: url(./img/body-overlay.png) no-repeat right top;
	background-size: auto;
	width: 100%;
	top: 45px;
	height: 100%;
	right: 60px;
	background-size: contain;
	z-index: -1;
	opacity: 0.7;
}

.first-post {
	width: 100%;
	float: left;
	background: #efefef;
	border-radius: 5px;
	overflow: hidden;
}

.first-post .blog-img {
	width: 100%;
}

.first-post .blog-heading {
	padding: 50px 50px 50px 40px;
}


.blog-heading ul {
	padding: 0;
	margin: 0 0 20px 0;
	float: left;
	width: 100%;
	list-style-type: none;
}
.blog-heading ul li:first-child {
	padding-left: 0px;
	list-style-type: none;
}
.blog-heading ul li {
	float: left;
	color: #6d6e70;
	padding: 0 35px 0 0;
	list-style-type: disc;
}
.first-post .blog-heading p {
	float: left;
	width: 100%;
	font-size: 16px;
}

.first-post .blog-heading h5, .first-post .blog-heading h5 a {
	font-size: 24px;
	color: #34383b;
	/*-webkit-line-clamp: 2;*/
	/*-webkit-box-orient: vertical;*/
	/*overflow: hidden;*/
	/*text-overflow: ellipsis;*/
	/*height: 55px;*/
	float: left;
}

.first-post .blog-heading .read-more a {
	font-size: 14px;
	width: 120px;
	height: 30px;
	line-height: 26px;
	text-transform: uppercase;
	padding: 0;
	font-weight: 400;
}

.first-post .blog-heading .read-more a:hover{
	color:#fff;
}

.blog-column {
	box-shadow: none;
	padding: 0;
	border-radius: 5px;
	margin: 0 0 50px;
	width: 100%;
	float: left;
}

.blog-column h5 a {
	color:#34383b !important;
	font-size: 30px;
	line-height: 30px;
	margin: 0 0 15PX;

	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	height: 60px;
	float: left;
}


.blog-column .blog-img {
	border-radius: 6px;
	width: 100%;
	float: left;
	border-radius: 5px;
	overflow: hidden;
	margin: 0 0 15px;
}

.blog-column .blog-img img {
	max-width: 100%;
}

.blog-column .read-more {
	width: 100%;
	float: left;
}

.blog-column .read-more a {
	font-size: 14px;
	width: 120px;
	height: 30px;
	line-height: 26px;
	text-transform: uppercase;
	padding: 0;
}

.most-popular {
	width: 100%;
	float: left;
}

.confirm-btn-grp button{margin : 10px;}
.most-popular h5 {
	font-size: 24px;
	margin: 0 0 25px;
}

.popular-lisitng .card {
	background: transparent;
	padding: 0;
	border: none;
}

.popular-lisitng .card .card-img {
	width: 80px;
	border-radius: 50%;
	height: 80px;
}

.btn-send-email{

	background: #fff!important;
	border: 1px solid #929497;
	border-radius: 4px;
	color: #34383b;
}
.btn-send-email:hover{

	background: #fff!important;
	border: 1px solid #929497;
	border-radius: 4px;
	color: #34383b;
}

.popular-lisitng .card .card-body {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	padding: 0 0 0 15px;
}

.popular-lisitng .card .card-body .card-text {
	color: #6d6e70;
	font-size: 14px;
	margin: 0 0 7px;
}

.popular-lisitng .card .card-body .card-title {
	margin-bottom: .75rem;
	font-size: 17px;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	height: 43px;
	float: left;
}

.suscribe-us {
	width: 100%;
	float: left;
	background:#fff;
	border: 1px solid #ddd;
	border-radius:10px;
	padding: 50px 25px;
}

.suscribe-us h5 {
	font-size: 21px;
}

.suscribe-us .form-control {
	width: 100%;
	border: 1px solid #ddd;
	border-radius: 3px;
	background:#efefef !important;
}

.suscribe-us .btn {
	width: 110px;
	height: 40px;
	margin: 20px 0 0;
}

.blog-detail-section {
	padding:80px 0 0;
	margin-bottom: 0;
}

.sub-heading-section {
	width: 100%;
	justify-content: space-between;
}

.blog-section h4 {
	font-size: 19px;
	color: #939598;
}

.center-banner h4 {
	font-size: 19px;
}

.sub-heading-section ul {
	padding: 0;
	list-style-type: disc;
	margin: 0;
	display: flex;
	align-items: center;
}

.sub-heading-section ul li:first-child {
	padding-left: 0px;
	list-style-type: none;
}
.sub-heading-section ul li {
	float: left;
	color: #6d6e70;
	padding: 0 35px 0 0;
}

.sub-heading-section ul.shareul li {
	float: left;
	color: #6d6e70;
	padding: 0 5px;
	list-style-type: none;
}

.blog-detail-img {
	width: 100%;
	float: left;
	margin-right: 20px;
}

.blog-detail-img img {
	width: 100%;
	border-radius: 22px;
}

.blog-detail-content-right {
	text-align: justify;
	padding-top: 20px;
	padding-bottom: 20px;
	color: #808184;
	word-spacing: 0.3em;
	font-size: 16px;
}

.related-blog-list {
	float: left;
	width: 100%;
}

.related-experience {
	width: 100%;
	float: left;
	margin: 0 0 100px;
}

.related-experience h5 {
	font-size: 24px;
	margin: 0 0 25px;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.related-experience .card {
	background:#efefef;
	border-radius: 5px;
}


.related-experience .card h5 a {
	color: #34383b;
	font-size: 21px;
}

.blog-detail-related .tranding-section {
	background:transparent;
}

/*.tranding-section .MuiRating-iconEmpty svg{*/
/*color: rgba(255, 255, 255, 0.9);*/
/*}*/

/*.tranding-section .MuiRating-iconEmpty {*/
/*color: rgba(255, 255, 255, 0.9);*/
/*}*/


/*.PersonalisedWeekends .MuiRating-iconEmpty svg{*/
/*color: rgba(255, 255, 255, 0.9);*/
/*}*/

/*.PersonalisedWeekends .MuiRating-iconEmpty {*/
/*color: rgba(255, 255, 255, 0.9);*/
/*}*/


.blog-detail-related .tranding-section h2{
	text-align:center;
	color: #fff;
	margin: 0 0 1rem;
	font-size: 32px;
}

.blog-detail-related .tranding-section .seeall{
	display: none;
}

/* *****************************         TESTIMONIAL CSS START HERE                     ******************************* */


.testimonial{
	background: #fde6d2;
}

.row.blog {
	margin-top: 40px;
}

.testimonialabout, .testimonial.testimonialabout .carousel .testimonial{
	background: transparent;
}


.testimonial h2{
	color:#34383b;
	font-size: 32px;
	font-weight: 600;
	margin-bottom: 15px;
}
.testi-title{
	margin-bottom: 40px;
}
.testimonial p {
	color: #34383b;
	margin: 0;
	font-size: 18px;
}
.testimonial .user-info {
	background:#fff;
	border-radius: 5px;
	padding: 20px 15px 20px;
	margin: 0;
	height: 200px;
}
.testimonial .user-info img {
	float: left;
	width: 32px;
	height: 32px;
	border-radius: 50%;
	margin: 0 10px 0 auto;
	display: none;
}

.testimonial .user-info p.testi-text{

	height: 50px;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 15px;

}

.testimonial .user-info h3 {
	font-size: 14px;
	font-weight: 600 !important;
	margin: 0;
}

.testimonial .user-info .testi-contenttitle p {
	font-size: 12px;
}

.testimonial .user-info p {
	font-size: 14px;
	margin: 0;
}

.testi-contenttitle {
	margin-bottom: 10px;
}

.testimonial .user-info .rating {
	width: auto;
	float: right;

}

.testimonial .user-info .rating .MuiSvgIcon-fontSizeSmall{
	font-size:12px;
}

.testimonial .user-info .rating > label{
	font-size: 8px;
	float: right;
}

.testimonial .rating > input ~ label {
	color:  #FFD700;
}

.testimonial .user-info .user-info-left p{
	font-size: 12px;
	width: 100%;
	text-align: right;
	color: #34383B;
	float: right;
	line-height: 6px;
}

.testimonial .user-info .rating > label:last-child::before{
	margin-right: 0;
}

.testimonial a.clcik {
	background: rgba(255,255,255,0.8) !important;
	box-shadow: 0px 3px 6px #000029 !important;
	opacity: 1;
	width: 48px;
	height: 48px;
	border-radius: 50% !important;
}

.testimonial .carousel-control-next, .carousel-control-prev {
	color:#34383b;
	text-align: center;
	top: 0;
}

.testimonial .carousel-control-next:hover, .carousel-control-prev:hover{
	color:#000;
}

.testimonial .carousel-control-next, .testimonial .carousel-control-prev {
	width: 30px;
}


.testimonial .carousel-control i {
	font-size: 26px;
	line-height: 42px;
	position: absolute;
	display: inline-block;
	color:rgba(0, 0, 0, 0.8);
	text-shadow: 0 3px 3px#e6e6e6, 0 0 0#000;
}


.testimonial .carousel .overview {
	float: left;
	text-align: left;
	margin: 14px 0 0 15px;
}

.testimonial .carousel .overview span {
	font-size: 14px;
	display: block;
	color: #6d6e70;
}

.testimonial .carousel .overview b {
	text-transform: uppercase;
	font-size: 18px;
	color:#00adee;
}

.testimonial .carousel {
	margin:0 auto 45px;
	padding: 0 70px;
}

.testimonial .carousel-indicators {
	bottom: -56px;
}

.testimonial .carousel .carousel-item {
	color:#999;
	font-size: 14px;
	text-align: center;
	overflow: hidden;
	min-height: 245px;
}

.testimonial .carousel .testimonial {
	padding: 30px 0 0;
	font-size: 21px;
	color: #6d6e70;
}
.testimonial .carousel .item .img-box {
	width: 70px;
	height: 71px;
	padding: 5px;
	border-radius: 50%;
}

.testimonial .carousel .img-box img {
	width: 100%;
	height: 100%;
	display: block;
	border-radius: 50%;

}

.testimonial .carousel-indicators li {
	background: #999;
	border-color: transparent;
	box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.2);
	border: 1px solid transparent;
}

.testimonial .carousel-indicators li.active {
	background:transparent;
	box-shadow: none;
	border: 1px solid#000;
}

.home-testi .owl-theme .owl-nav {
	top: 30%!important;
	width:100%;
	display: none;
}

.owl-carousel .owl-stage-outer{
	z-index: 9;
}


.home-testi .owl-theme .owl-nav .owl-prev {
	margin-left: -47px !important;
	z-index: 99999999;
	position: absolute;
	left: 0;
}

.home-testi .owl-theme .owl-nav .owl-next {
	margin-right: -47px !important;
	z-index: 99999999;
	position: absolute;
	right: 0;
}


/********************************    GIVE BACK CSS START        **************************************/
.giveback-banner {
	background: url('./img/giveback-hedaer-bg.png') no-repeat center;
	background-size: auto;
	background-size: contain;
	background-size: cover;
	width: 100%;
	min-height: 330px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 127px 0 0;
	position: relative;
	z-index: 0;
	margin: 0 0 50px;
}

.giveback-section ul {
	list-style-type: none;
	padding: 0;
}
.giveback-section ul li {
	list-style-type: none;
	font-size: 14px;
	font-family: 'Archivo', sans-serif;
	position: relative;
	padding: 0 0px 0 65px;
	margin: 0 0 20px;
}
.giveback-section ul li:nth-child(1) span,
.giveback-section ul li:nth-child(3) span {
	color: #f79318;
}
.giveback-section ul li:nth-child(2) span,
.giveback-section ul li:nth-child(4) span {
	color: #7c2427;
}
.giveback-section ul li span {
	display: block;
	font-weight: bold;
}
.giveback-section ul li::before {
	background-image: url(./img/giveback-icon.png);
	position: absolute;
	content: '';
	width: 60px;
	height: 60px;
	left: 0;
	top: 0;
	background-position: center top;
}
.giveback-section ul li:nth-child(2)::before {
	background-position: center -215px;
}
.giveback-section ul li:nth-child(3)::before {
	background-position: center -336px;
}
.giveback-section ul li:nth-child(4)::before {
	background-position: center -511px;
}
.giveback-section ul li:nth-child(1)::after {
	background-image: url(./img/give-1.png);
	position: absolute;
	content: '';
	width: 148px;
	height: 130px;
	left: -79px;
	top: 77px;
	background-position: center top;
	background-size: contain;
	background-repeat: no-repeat;
}
.giveback-section ul li:nth-child(2) {
	padding-right: 72px;
}
.giveback-section ul li:nth-child(2)::after {
	background-image: url(./img/give-2.png);
	position: absolute;
	content: '';
	width: 148px;
	height: 130px;
	right: -44px;
	top: -15px;
	background-position: center top;
	background-size: contain;
	background-repeat: no-repeat;
}
.giveback-section ul li:nth-child(3)::after {
	background-image: url(./img/give-3.png);
	position: absolute;
	content: '';
	width: 86px;
	height: 130px;
	left: -25px;
	top: 77px;
	background-position: center top;
	background-size: contain;
	background-repeat: no-repeat;
}
.giveback-section ul li:nth-child(4)::after {
	background-image: url(./img/give-4.png);
	position: absolute;
	content: '';
	width: 86px;
	height: 130px;
	right: -68px;
	bottom: 75px;
	background-position: center top;
	background-size: contain;
	background-repeat: no-repeat;
}
.giveback-section ul li ol {
	padding: 0;
	margin: 0 0 0 18px;
}
.giveback-section ul li ol li:after,
.giveback-section ul li ol li:before {
	display: none;
}
.giveback-section ul li ol li {
	list-style-type: decimal;
	padding: 0;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.giveback-section ul li ol li::before {
	display: none;
}
.giveback-form {
	background-image: url(./img/giveback-form-bg.png);
	width: 100%;
	float: left;
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 530px;
	background-position: center top;
}
.give-form {
	padding: 20px;
}
.giveback-form h4 {
	color: #fff;
	text-transform: uppercase;
	font-size: 18px;
	font-weight: bold;
	margin: 245px 0 10px 3px;
}
.give-form .form-row {
	margin: 0 0 5px;
}
.give-form .form-group label {
	padding-bottom: 0;
	font-size: 12px;
	line-height: 12px;
	color:#fff;
}
.give-form .form-group label::before {
	display: none;
}
.give-form .form-group input {
	border-radius: 0px!important;
	border: 1px solid #ddd !important;
	width: 100%;
	min-height: 14px;
	padding: 3px 10px 0;
	height: 24px;
	line-height: 27px;
	font-size: 12px;
	display: block;
	background: #fff;
}

.give-form .form-group {
	display: block;
	margin-bottom: 0px;
}

.invite-friend {
	background:  #7c2427;
	color: #fff;
	padding: 7px 10px 5px;
	margin: 20px 0 0;
	margin-left: 0px;
	float: left;
}

/**********************     SPORTS WEEKEND PAGE CSS START             ****************************/

.sportsheader-banner {
	background: url('./img/sports-hedaer-bg.png') no-repeat center;
	background-size: auto;
	background-size: auto;
	background-size: cover;
	width: 100%;
	min-height: 388px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 0 0;
	position: relative;
	z-index: 0;
}

.sports-section {
	padding: 120px 0;
	margin-bottom: 90px;
	z-index: 9;
	position: relative;
	background: #fff;
}
.sports-section::before {
	position: absolute;
	content: '';
	background: url(./img/listing-page-bg.png) no-repeat left center;
	background-size: auto;
	background-size: auto;
	width: 572px;
	top: 25px;
	height: 100%;
	left: 10%;
	background-size: contain;
	z-index: -1;
	opacity: 0.7;
}
.sports-listing {
	width: 100%;
	float: left;
}
.sports-listing ul {
	list-style-type: none;
	padding: 0;
}
.sports-listing ul li {
	list-style-type: none;
	font-size: 14px;
	font-family: 'Archivo', sans-serif;
	position: relative;
	padding: 0 0px 0 65px;
	margin: 0 0 20px;
}
.sports-listing ul li span {
	display: block;
	font-weight: bold;
}
.sports-listing ul li::before {
	background-image: url(./img/sports-list-icon.png);
	position: absolute;
	content: '';
	width: 60px;
	height: 60px;
	left: 0;
	top: 0;
	background-position: center top;
}
.sports-listing ul li:nth-child(1) span {
	color: #d6de27;
}
.sports-listing ul li:nth-child(2) span,
.sports-listing ul li:nth-child(4) span {
	color: #00adef;
}
.sports-listing ul li:nth-child(2) {
	padding-right: 15px;
}
.sports-listing ul li:nth-child(2)::before {
	background-position: center -112px;
}
.sports-listing ul li:nth-child(3) span {
	color: #d6de27;
}
.sports-listing ul li:nth-child(3)::before {
	background-position: center -221px;
}
.sports-listing ul li:nth-child(4)::before {
	background-position: center -350px;
}
.sports-form {
	width: 100%;
	float: left;
	position: relative;
	z-index: 9;
	margin: 24px 0 0;
}
.sports-form::before {
	position: absolute;
	content: '';
	background: url(./img/sports-form-bg.png) no-repeat left center;
	background-size: auto;
	width: 665px;
	top: -51px;
	height: 418px;
	left: -77px;
	background-size: cover;
	z-index: -1;
}
.sports-form h3 {
	color: #00aeef;
	text-transform: uppercase;
	font-size: 18px;
	font-weight: bold;
	margin: 147px 0 0 39px;
}
.sports-form form {
	padding: 4px 0 0 39px;
}
.sports-form .form-group {
	margin: 0 0 5px;
}
.sports-form .form-group label {
	padding-bottom: 0;
	font-size: 12px;
	line-height: 12px;
	color: #000;
}
.sports-form .form-group label:before {
	display: none;
}
.sports-form input {
	border-radius: 0px !important;
	border: 1px solid #ddd !important;
	width: 100%;
	min-height: 14px;
	padding: 3px 10px 0;
	height: 24px;
	line-height: 27px;
	font-size: 12px;
	display: block;
	background: #fff;
}
.sports-form .phone_no .input-group-text {
	padding: 3px 5px 0 5px;
	font-size: 12px;
	height: 23px;
	border-radius: 0px !important;
	vertical-align: middle;
}
.sports-form .phone_no input.field_full_width {
	width: 81% !important;
	background-color: #fff !important;
	border-radius: 0px !important;
}
.give-form .phone_no .input-group-text {
	padding: 3px 5px 0 5px;
	font-size: 12px;
	height: 23px;
	border-radius: 0px !important;
	vertical-align: middle;
}
.give-form .phone_no input.field_full_width {
	width: 82.1% !important;
	background-color: #fff !important;
	border-radius: 0px !important;
	height: 23px !important;
}
.sports-form a {
	color: #000;
	margin: 7px 0 0;
	float: left;
}
.sports-form a img {
	width: 23px;
	transform: rotate(-90deg);
}
.form-drop {
	background: #00AEEF;
	width: 96%;
	float: left;
	z-index: -9;
	margin: -27px 0 0 0;
	padding: 20px;
}
.col-form-label .small-text {
	font-size: 9px !important;
	opacity: 1 !important;
	width: 100%;
	float: left;
}
.form-drop form {
	padding: 80px 0 0;
}
.form-drop form .form-row {
	margin: 0 0 5px;
}
.form-drop form label {
	padding-bottom: 0;
	font-size: 12px;
	line-height: 12px;
	color: #fff;
}
.form-drop form input {
	border-radius: 0px;
	border: 1px solid #ddd !important;
	width: 100%;
	min-height: 14px;
	padding: 3px 10px 0;
	height: 24px;
	line-height: 27px;
	font-size: 12px;
}

.form-drop select {
	width: 100%;
	border: none;
	height: 30px;
	background-color: #fff;
}

/************************************      PARTNER NEW PAGE CSS START               *******************************************/

.become-partner-bg {
	/*background: url(./img/become-the-partner.jpg);*/
    background: url(./img/become-the-partner-new.jpg);

    background-position-x: 0%;
	background-position-y: 0%;
	background-repeat: repeat;
	background-size: auto;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	padding: 100px 0;
}
.become-partner-bg::before {
	position: absolute;
	content: '';
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.3);
	top: 0;
}

.become-partner-txt {
	width: 100%;
}
.best-family-main, .joining-us-sec {
	width: 100%;
	padding: 70px 0;
	background: url(./img/vender-png.png);
	background-position-y: 0%;
	background-repeat: repeat;
	background-size: auto;
	background-size: contain;
	background-repeat: no-repeat;
	background-position-y: 12%;
}
.become-partner-txt h2 {
	font-size: 41px;
	color: #000;
	font-weight: 600;
}
.join-txt {
	font-size: 18px;
	color: #000;
}
.partnership-form-main label {
	display: block;
	margin-bottom: 0px;
	font-size: 16px;
	color: #000;
	margin-top: 10px;
	width: 100%;
	float: left;
}
.partnership-form-main .phone_no .input-group-text {
	border-radius: 5px 0 0 5px !important;
}
.partnership-form-main .phone_no .field_full_width {
	width: 100% !important;
	background-color: #fff !important;
	border-radius: 5px !important;
	border:none;
}
.partnership-form-main input {
	width: 100%;
	padding: 10px 15px;
	border-radius: 5px;
	border:none;
	background: #fff;
}
.partnership-form-main textarea {
	width: 100%;
	height: 150px;
	padding: 10px 15px;
	border:none;
	background: #fff;
}
.partnership-form-main img {
	width: 100%;
	margin: 15px 0 15px 0;
}
.online-booking-sec {
	margin-bottom: 40px;
	margin-top: 30px;
}
.online-booking-img {
	width: 100%;
	height: 100%;
	border-radius: 10px;
}
.online-booking-hd {
	font-size: 22px;
	color:#34383b ;
	font-weight: bold;
}

.partnership-img {
	border-radius: 10px;
}
.online-pg {
	font-size: 16px;
	color: #6d6e70 !important;
	text-align: justify;
}
.online-bottom {
	padding-top: 50px;
}
.online-btm h2 {
	font-size: 20px;
	color: #ec1c24;
	font-weight: bold;
}
.trusted-pg {
	font-size: 16px;
	color: #6d6e70 !important
}
.scale-txt {
	font-size: 25px;
	color: #ec1c24;
	font-weight: 600;
	padding-top: 10px;
}
.scale-pg {
	font-size: 16px;
	color: #6d6e71;
}
.million-img {
	width: 100%;
	height: auto;
	position: relative;
	display: none;
}
.million-txt {
	position: absolute;
	color: #fff;
	top: 90px;
	right: 110px;
}
.million-txt h2 {
	font-size: 75px;
	font-weight: bold;
	line-height: 75px;
	margin-bottom: 0;
}
.million-txt h3 {
	font-size: 40px;
	line-height: 40px;
	margin-bottom: 0;
}
.million-txt h4 {
	font-size: 15px;
	line-height: 15px;
	margin-bottom: 0;
}
.million-txt h5 {
	line-height: 15px;
	font-size: 15px;
	margin-bottom: 3px;
}
.million-txt h6 {
	font-size: 15px;
	margin-bottom: 0;
	line-height: 15px;
}
.scal-main {
	padding-top: 20px;
}

/* ****************************        SAVE WEEKEND SECTION CSS START            *********************************** */



.saveweekend{
	width:100%;
	background: #fff;
}



.saveweekend-info {
	background-image: url(./img/coming-sson.png);
	background-repeat: no-repeat;
	width: 100%;
	min-height: 386px;
	background-size: contain;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 50px;
	border-radius: 10px;
	color: #fff;
}

.saveweekend-info h2 {
	font-size: 36px;
	margin-bottom:20px;
}

.saveweekend-info p {
	font-size: 21px;
	margin: 0 0 25px;
}
.learn-more {
	background: #ec1c24;
	border-radius: 4px;
	height: 48px;
	width: 100%;
	line-height: 48px;
	text-align: center;
	display: flex;
	justify-content: center;
	color:#fff;
	font-size: 18px;
	max-width: 300px;
	margin: 0;
}

.learn-more:hover {
	color: #fff;
}

.activites .activity-listing{
	display: none;
}


.custom-select .MuiInput-underline::before, .custom-select .MuiInput-underline::after{
	display:none;
}

.checkout-section .for-web{
	display: block;
}
.for-web{
	display: flex;
}

.for-mobile{
	display: none;
}

.promobox.promobox-apply.justify-content-between {

	display: flex;

}

.row.planday.popup-mood-slider {
	text-align: left;
}
.modal-content .white-box{
	box-shadow: none;
	padding: 0 20px 32px !important;
}

.modal-content .white-box .play-weekend {
	line-height: 38px;
}

.modal-content .row.title-row .col-lg-12.text-center{
	padding: 0 30px;
}

.letsplan .play-weekend {
	line-height: 38px;
}

a, a:hover {
	color: #00adee;
}

.promobox input.promoinvalid{
	border: 1px solid #dc3545;
}

.promobox .p-relative {
	position: relative;
	float: left;
	max-width: 290px;
	width: 100%;
}
.invalid-promocode {
	position: absolute;
	color:#dc3545;
	font-size: 12px;
	top: 16px;
	right: 0;
}
.invalid-promocode i{
	color:#dc3545;
}

.popup-form .modal-dialog {
	max-width: 370px;
	width: auto;
}
.popup-form .fb-btn {
	height: 44px;
	width: 44px;
	background-position: center center;
}
.social.fb-login-btn {
	float: left;
	margin: 0;
}
.popup-form .loginBtn, .popup-form .fb-btn{
	float: left;
	margin-top: 0;
	border-radius: 50% !important;
}
.popup-form .loginBtn{
	height: 44px;
	text-align: center;
	line-height: 23px;
	white-space: nowrap;
	font-size: 16px;
	color:#FFF;
	font-family: "Roboto", Roboto, arial, sans-serif;
	padding: 10px 18px 8px;
	border-radius: 5px;
	max-width: initial;
	width: 44px;
	background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png') center no-repeat #DD4B39;
}

.popup-form .loginBtn::before{
	display: none;
}

.back-btn-popupform {
	font-size: 16px;
	margin-bottom: 15px;
}
.btn.signup-link {
	color: #ec1c24;
	padding: 0;
	background:transparent;
	border: none;
	line-height: 18px;
}

.user-address img {
	border-radius: 10px;
}

.success-order .user-detail .product-dis-list p {
	margin: 0 !important;
	float: left;

}

.back-btn-popupform .btn {
	width: 30px;
	height: 30px;
	padding: 0;
	margin: 0 10px 0 0;
}

.procedd-block.border-none {
	border-top: 0px;
	margin: 0;
}

.not-found h1 {
	font-weight: 900 !important;
	font-size: 110px;
}

.email-confirm h1 {
	font-weight: 900 !important;
	font-size: 80px;
	color: #0E7DC2;
}



.not-found p{
	text-transform: uppercase;
	font-size: 14px;
}

.email-confirm .white-box{
	float: left;
}

.email-confirm .red-btn{
	float: none;
	display: inline-block;
}

.product-dis-list .value-button img{
	float: none;
}

.add-delete{
	float: left;
	margin: 10px 0 0;
	width: 100%;
}

.ticket-price{
	float: left;
}

.add-delete p {
	float: right;
	margin: 2px 0 0 5px;
}

.add-delete input.number {
	font-size: 14px;
	color: #858688;
}

.modal-header.delete-item-cart .close{
	position: absolute;
right: 22px;
}

.close-btn-popup {
	position: absolute;
	right: 12px;
	top: 9px;
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 1;
	color:#000;
	text-shadow: 0 1px 0#fff;
	opacity: .5;
	padding: 10px;
	background-color:transparent;
	border: 0;
	cursor: pointer;
	z-index: 99;
}

.mobile-nav{
	display: none;
}

.body-overflow{
	overflow: hidden;
}
.edit-cart-block .block {
    width: 100%;
    float: left;
    padding: 18px 0;
}

.edit-cart-block .block .form-group {
    margin: 0;
}

.edit-cart-block .block .date-label {
    font-size: 18px;
    font-weight: 600;
    color: 
	#34383b;
	float: left;
margin-right: 15px;
}

.edit-cart-block .block .custom-select {
    border: 1px solid #dbdbdc;
	background-color:transparent;
    height: 40px;
    cursor: pointer;
    font-size: 16px !important;
    font-weight: 600;
	padding-right: 0;
	float: right;
width: 70%;
}

.edit-cart-block .offer-data-right .text-line {
    float: right;
	width: 100%;
	text-decoration: line-through;
	margin: 0;
}
.edit-cart-block .offer-data-right p.totaloff {
    font-size: 10px;
    margin: 0 0 5px;
}

.edit-cart-block .top-block {
    width: 100%;
    float: left;
}

.edit-cart-block .top-block p {
    font-size: 14px;
    color:#6d6e70;
    float: left;
    line-height: 27px;
}

.edit-cart-block h2 {
    font-size: 18px;
    color: #34383b;
    font-weight: 600;
}

.edit-cart-block p {
    float: left;
    width: 100%;
    font-size: 11px;
    line-height: 18px;
    color:#34383b;
    margin: 5px 0;
}

.edit-cart-block .product-img {
    border-radius: 5px;
    overflow: hidden;
    float: left;
    max-width: 90px;
    width: 100%;
    margin-right: 10px;
}

.edit-cart-block .product-img img{
	width:90px;
	height:67px;
}

.edit-cart-block .order-total {
    float: left;
    padding: 10px 0;
    width: 100%;
    margin-left: 0;
}

.click-edit{
	float: left;
cursor: pointer;
}

.edit-cart-block .promo-block .buy-now {
    height: 50px;
    font-weight: 700;
}

.edit-cart-block .top-block h4 {
    float: left;
    font-size: 16px;
    margin:0 0 15px;
    width: 100%;
    color:#34383b;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.edit-cart-block .block .left-side {
    width: 72%;
    float: left;
}

.edit-cart-block .block .right-side {
    float: right;
    width: 28%;
    align-self: end;
    text-align: right;
}

.edit-cart-block .offer-data-right h4 {
    font-size: 16px;
    margin: 0;
    float: right;
    color:#34383b;
    font-weight: 700 !important;
}

.edit-cart-block form {
    width: 100%;
    float: left;
}

@media (max-width:1170px) {
	.fillter-sec div.MuiFormControl-root{
		margin-bottom:10px;
	}
}

@media (max-width:1024px) {
	section{
		padding: 50px 0;
	}
	.perfect-weekened{
		margin: 50px 0;
	}
	.perfect-weekened h1{
		font-size: 24px;
		margin: 0 0 15px;
		line-height: 30px;
	}
	.country-dropdown {
		left: 72px;
		width: 128px;
		top: 38px;
	}
	.tranding-section h2{
		font-weight: 600 !important;
	}

	.tranding-section .row .text-center{
		text-align: left !important;
	}

	.block-title p{
		color:#34383b;
		font-weight: 600;
	}
	.saveweekend .col-lg-9 {
		max-width: 100% !important;
		flex: 0 0 100% !important;
	}
	.saveweekend-info{
		text-align: center;
		background-size: cover;
		min-height: 313px;
	}

	.saveweekend-info h2 {
		font-size: 32px;
		margin-bottom: 20px;
	}

	.learn-more{
		margin: 0 auto;
	}
	.getapp h2 {
		font-size: 24px;
		margin-bottom: 10px;
	}
	.getapp p{
		margin-bottom: 18px;
	}
	footer {
		padding: 50px 0;
	}

	footer::before{
		width: 100%;
		background-position: right;
		background-size: contain;
	}
	footer .col-lg-3 {
		max-width: 100% !important;
		flex: 0 0 100%;
	}

	footer h3 {
		margin: 0 0 15px;
	}

	footer ul {
		margin: 0 0 30px;
		width: 100%;
		float: left;
	}

	footer li::after {
		width: 1px;
		position: absolute;
		content: '';
		height: 17px;
		top: 2px;
		background: #fff;
		right: 0;
	}

	footer ul.customer-support-ul{
		margin: 0;
	}

	footer li:first-child{
		padding-left: 0;
	}

	footer li:last-child:after{
		display: none;
	}

	footer li {
		font-size: 14px;
		padding: 0 7px 0;
		float: left;
		position: relative;
	}

	footer li.social-icon {
		width: 100%;
		padding: 20px 0 0;
	}
	footer li.social-icon a:first-child{
		margin-left: 0;
	}
	.home-testi .owl-theme .owl-nav{
		top: 34% !important;
		z-index: 9;
	}
	.home-testi .owl-theme .owl-nav .owl-prev{
		margin-left: -13px !important;
	}
	.home-testi .owl-theme .owl-nav .owl-next{
		margin-right: -13px !important;
	}
	/*.testimonial .user-info .rating, .testimonial .user-info .rating > label, .testimonial .user-info .user-info-left p{*/
		/*text-align: left;*/
		/*width: auto;*/
	/*}*/


}



@media (max-width:990px){


	.modal-content {
		padding-top: 0px!important;
	}

	.address-info{margin-bottom: 20px;}

	.job-listing .items {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
	}
	.giveback-banner{
		min-height: auto;
		padding: 50px 0;
		margin: 0;
	}
	.giveback-section ul li:nth-child(1)::after {
		width: 55px;
		height: 105px;
		left: -3px;
	}

	.giveback-section ul li:nth-child(3)::after {
		width: 73px;
		height: 84px;
		left: -8px;
	}

	.giveback-section ul li:nth-child(4)::after{
		width: 69px;
		height: 130px;
		left: -13px;
		right: auto;
	}

	.giveback-form{
		background-image: none;
		background-color: #f7931e;
	}

	.giveback-form h4{
		margin: 0 0 10px 3px;
	}
	.give-form .phone_no input.field_full_width{
		padding-left: 50px;
		width: 100% !important;
	}

	.give-form .for-mobile {
		display: block;
	}

	.give-form .form-group label{
		float: left;
	}

	.sportsheader-banner{
		min-height: auto;
		padding: 20px 0;
	}

	.sportsheader-banner col img{
		width: 50%;
	}

	.sports-section {
		padding: 30px 0;
	}

	.sports-form {
		padding: 0 20px;
		background:#d6df27;
	}

	.sports-form:before{
		display: none;
	}

	.sports-form h3 {
		margin: 15px 0 0 0;
	}

	.sports-form form {
		padding: 4px 0 0 0;
	}

	.sports-form .phone_no input.field_full_width {
		width: 100% !important;
	}

	.sports-section::before{
		display: none;
	}

	.title_here {
		margin: 20px 0 auto;
	}

	.custom-control.mobile-view {
		padding-left: 0;
		display: block;
		min-height: 1.5rem;
		float: left;
		width: auto;
	}

	.manage-ul .nav-link{
		margin: 0 5px 14px;
	}

	.act-booking-block{
		margin-bottom: 15px;
	}

	.act-booking-block .act-booking-block{
		border: none !important;
	}

	.form-drop{
		width: 100%;
	}

	.form-drop form {
		padding: 10px 0 0;
	}

	.form-drop select{
		margin: 5px 0;
	}

	.form-col-left.pl-3.pr-3 {
		padding: 0 !important;
	}
	.form-heading{
		margin-bottom: 10px;
	}
	
	.title-top{
		margin-top: 15px;
	}

	.vendor-bottom-button .blue-btn{
		line-height: 34px;
		height: 35px;
	}

	.vendor-bottom-block {
		margin: 0 0 0;
	}

	.title_price2{
		margin: 0;
	}

	.activity-status_info {
		float: left;
		color: #fff;
		margin: 20px 0 0;
	}
	.search-drop .MuiPaper-root {
		min-width: auto;
		padding: .5rem .8rem;
		position: absolute;
		right: 15px;
		box-shadow: 0px 0px 10px
		rgba(0, 0, 0, 0.1);
		background:#fff;
		left: auto !important;
		width: 100%;
		top: 67px !important;
	}
	.formobile-reverse{
		flex-direction: column-reverse;
	}
	.modal-content .white-box .play-weekend {
		line-height: 28px;
	}

	.online-booking-hd{
		margin-top: 15px;
	}

	.less-link{
		font-weight: 400;
	}

	.formobile-reverse .col-md-6.pr-0{
		padding-right: 15px !important;
	}
	.joining-us-sec{
		padding-top: 0;
	}
	.letsplan .play-weekend {
		line-height:28px;
	}
	.contact-blue-banner{
		padding: 50px 0;
	}
	.contact-blue-banner h2{
		font-size: 32px;
	}
	.user-icon.for.login-option.nav-item .dropdown-menu {
		padding: 0;
		overflow: hidden;
	}
	.signup-mobile{
		color:#ec1c24;
		width: 100%;
	}
	.login-mobile a{
		color: #000;
	}



	.checkout-section .for-web {

		display: none;

	}
	.home-slideshow-top .carousel-control-next-icon, .home-slideshow-top .carousel-control-prev-icon {
		margin-top: 0;
		width: 20px;
		height: 30px;
	}
	.carousel-caption {
		top: 50%;
	}
	.mobile-nav{
		display: block;
	}
	.web-nav{
		display: none;
	}

	.mobile-menu-row {
		margin: 0;
	}

	.mobile-menu-row .col.mobile-menu{
		padding: 0;
	}

	.MuiDialog-root.mobile-menu-popup {
		z-index: 99999 !important;
	}

	.mobile-nav .navbar{
		z-index: 999;
	}

	.mobile-nav .navbar{
		position: fixed;
		width: 100%;
		top:0;
		left: 0;
	}

	.navbar-container-sticky{
		height: 71px;
	}
	.navbar-light .navbar-brand img {
		width: 41%;
		transition: width 1s;
	}

	.navbar-toggler {
		position: absolute;
		top: 14px;
		right: 15px;
	}
	.mobile-nav .nav-item .nav-link.dropdown-toggle::after{
		right: 0;
		position: absolute;
	}
	.right-nav {
		flex-direction: row;
		display: flex;
		position: absolute;
		right: 5px;
		top: 15px;
	}

	.menu-icon{
		width:24px;
	}

	.mobile-menu-popup .MuiBackdrop-root {
		opacity: 0 !important;
	}

	.mobile-menu-popup .MuiDialog-paper{
		margin: 62px 22px 22px 22px;
		width: calc(100% - 22px);
	}

	.mobile-menu .MuiExpansionPanel-root{
		background-repeat: no-repeat;
		background-size: 39px;
		min-height: 44px !important;
		color:#221f20;
		font-weight: 600;
		box-shadow: none;
	}

	.mobile-menu .MuiExpansionPanel-root::before{
		display: none;
	}

	.mobile-menu .MuiExpansionPanel-root a{
		color:#221f20;
		font-size: 16px;
		font-weight: 500;
	}

	.mobile-menu .MuiExpansionPanel-root a img{
		margin-right: 20px;
	}
	.mobile-menu .MuiIconButton-label{
		font-size: 16px;
		color:#ccc;
	}

	.navbar-light .navbar-nav .nav-item.show .nav-link, .navbar-light .navbar-nav .nav-item.show .nav-link:first-child{
		color:#cf2c2a;
	}
	.mobile-menu .MuiExpansionPanelSummary-content.Mui-expanded a{
		color:#cf2c2a;
	}

	.mobile-menu .MuiExpansionPanelSummary-expandIcon.Mui-expanded .MuiIconButton-label{
		color:#cf2c2a;
	}

	.submenu-mobile {
		padding: 0 0 0 12px;
		margin: 0;
		list-style-type: none;
		text-align: left;
		width: 100%;
	}

	.submenu-mobile li {
		padding: 15px 0;
		font-weight: 400;
		font-size: 16px;
		width: 100%;
		float: left;
	}

	.submenu-mobile li:first-child{
		padding-top: 0;
	}

	.mobile-menu .MuiExpansionPanel-root .submenu-mobile li a{
		font-weight: 400;
		font-size: 16px;
		width: 100%;
		float: left;
	}

	.shoping-cart, .search{
		padding: 0 10px;
	}

	.user-icon.dropdown.nav-item {
		margin-right: 8px;
	}

	.user-icon .nav-link {
		padding: .5rem 20px .5rem 10px;
	}

	.search-drop {
		width: 100%;
		min-width: 320px;
		right: 0;
	}

	.dropdown-content{
		right: -50px;
	}

	.bottom-block .button_edit{
		width: 109px;
		height: 35px;
		font-size: 14px;
		line-height: 28px;
	}
	.action-button {
		display: flex;
		justify-content: space-between;
	}

	.block-action{
		border-left: none;
		min-height: auto;
	}
	.block-action .action-button .btn-blue {
		width: auto;
		text-align: center;
		background: #007dc4;
		border-radius: 5px !important;
		font-size: 14px;
		color:white;
		border: 2px solid #007dc4;
		padding: 5px 5px;
		float: right;
		line-height:23px;
		height: 37px;
	}
	.block-action .action-button .btn{
		margin: 0;
		width: 106px;
		font-size: 14px;
	}
	.bottom-block .readmore-red {
		height: 37px;
		line-height: 29px;
		font-size: 16px;
		width: 121px;
	}

}
@media (max-width:990px) {



	.p-0{
		padding-right: 15px !important;
		padding-left: 15px !important;
	}
	.mt-5, .my-5 {
		margin-top:30px !important;
	}
	.carousel{
		margin: 0;
	}

	.perfect-weekened p {
		font-size: 18px;
		margin-bottom: 18px;
	}

	.wl-info-box .MuiSvgIcon-fontSizeSmall {
		font-size: 12px;
	}
	.seeall {
		font-weight: 700;
	}

	.activity-text {
		position: absolute;
		bottom: 30px;
		left: 20px;
		width: 90%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.activity-text h4 {
		font-size: 21px;
		margin-bottom: 0;
	}
	.activity-text a {
		width: 140px;
		height: 40px;
		line-height: 44px;
	}

	.activites .activity-listing {
		display: block;
		position: absolute;
		left: 20px;
		top: 20px;
		color:#fff;
		width: 90%;
	}

	.activites .activity-listing h3 {
		color:#fff;
	}

	.activites .activity-listing p {
		opacity: 0.9;
	}
	.activites .image::after {
		display: block;
		position: absolute;
		background-color:
				rgba(0,0,0,0.5);
		margin-top: 0;
		height: 100%;
		width: 100%;
		content: '';
		border-radius: 15px;
		top: 0;
	}
	.block-title h2 {
		font-size: 24px;
	}

	.block-title p {
		font-size: 18px;
	}

	.listing-slider {
		padding: 0 15px;
	}

	.activity-listing {
		display: none;
	}

	.PersonalisedWeekends .owl-theme .owl-nav {
		top: 27%;
		z-index: initial;
	}


	.listing-slider .owl-theme .owl-nav .owl-prev {
		margin-left: -9px !important;z-index: 999;
		position: absolute;
		left: 0;
	}
	.listing-slider .owl-theme .owl-nav .owl-next {
		margin-right: -9px !important;
		z-index: 999;
		position: absolute;
		right: 0;
	}
	.activites .image {
		height: 200px;
	}

	.activites .image img {
		height: 100%;
	}
	.saveweekend-info{
		padding:90px 33px;
	}
	.saveweekend-info p {
		font-size: 18px;
		margin: 0 0 25px;
	}

	.row.title-row{
		flex-direction: column-reverse;
		text-align: center !important;
	}

	.row.title-row .col-lg-4.text-right{
		text-align: center !important;
	}

	.launching-btn{
		margin: 20px auto;
		float: none;
		display: inherit;
	}

	.mood-ul .owl-item .item{
		width: 100%;
		min-height: 100%;
	}

	.trend-item .image {
		width: 100%;

	}
	.PersonalisedWeekends .trend-item .image img {
		width: 100%;
	}
	.tranding-section.happy-home .owl-theme .owl-nav{
		z-index: 9;
	}

	.listing-slider .owl-carousel .owl-stage-outer{
		min-height: 200px !important;
		height: 200px !important;
	}

	.tranding-section .owl-theme .owl-nav .owl-prev{
		margin-left: -12px !important;
	}
	.tranding-section .owl-theme .owl-nav .owl-next {
		margin-right: -12px !important;
	}

	.newsletter {
		text-align: center;
	}
	.newsletter h2 {
		font-size: 24px;
		font-weight: 700 !important;
	}
	.newsletter p {
		font-size: 18px;
	}
	.newsletter-form {
		text-align: center;
		margin: 20px 0 0;
	}
	.lets-plan-title {
		margin-bottom: 30px;
	}
	.letsplan .text-center h2 {
		font-size: 24px;
		font-weight: 700 !important;
	}
	.plan-col {
		margin-bottom: 20px;
	}

	.btn-days .btn {
		margin-bottom: 10px;
	}
	.row.planday {
		margin-bottom: 10px !important;
	}
	.play-weekend{
		margin-bottom: 0 !important;
	}

	.testimonial h2{
		font-size: 24px;
		margin-bottom: 10px;
		font-weight: 700 !important;
	}
	.tranding-section.happy-home h2 {
		text-align: center;
	}
	.tranding-section.happy-home .owl-carousel .owl-stage {
		max-height: 100% !important;
	}

	.tranding-section.happy-home .owl-carousel .owl-stage-outer{
		max-height: 100% !important;
		min-height: 100% !important;
		height: 100% !important;
	}


	.for-web{
		display: none;
	}

	.for-mobile{
		display: flex;
	}

	.col-lg-4.width-for-mobile {
		width: 40%;
	}

	.checkout-section .product-img {
		border-radius: 5px;
		overflow: hidden;
		height: 90px;
	}

	.col-lg-8.width-for-mobile {
		width: 60%;
		padding-left: 0px;
	}
	.promobox.promobox-apply.justify-content-between {

		display: flex;
		align-items: center;
		margin: 0;

	}

	.promobox.promobox-apply.justify-content-between p{
		margin: 0;
		line-height: 26px;
	}
	.checkout-section {
		padding: 25px 0 !important;
	}

	.primaryguestform {
		margin: 25px 0 0;
		padding: 0 !important;
	}

	.checkout-section .product-title-booking div p.address-dis {
		display: none;
	}

	.promobox.promobox-apply p {
		margin: 0;
	}

	.promobox .p-relative{
		max-width: 61%;
	}
	.promobox input{
		max-width: 100%;
		height: 40px;
	}

	.invalid-promocode{
		top:11px;
	}

	.promobox button {
		height: 40px;
		float: right;
		line-height: 9px;
		margin-left: 0;
	}

	.mobile-reverse{
		flex-direction: column-reverse;
	}

	.how-work-txt h2, .banner-font {
		font-size: 32px;
	}
	.best-family-txt {
		font-size: 22px;
	}

	.section-block.become-partner-bg {
		padding: 50px 0;
	}
	.become-partner-txt h2{
		font-size: 32px;
	}
	.checkout-section .promobox-block {
		padding-bottom: 10px !important;
		margin-top: 10px !important;
	}

	.checkout-section .product-title-booking div p{
		margin-bottom: 0;
	}

	.row.mt-4.final-price {
		margin-top: 10px !important;
	}

	.view-cart-button-mobile {
		width: 100%;
		text-align: left;
		border: none;
		background: transparent;
		padding: 0;
		color:	#00adee;
		background-image: url(./img/arrow-down.png);
		background-repeat: no-repeat;
		background-position-x: 100% !important;
		background-position-y: center !important;
		background-size: 13px;
	}
	.view-cart-detail-mobile {
		/*display: none;*/
		z-index: 999;
		background:#fff;
		border-radius: 0 0 10px 10px;
	}

	.view-cart-button-mobile:hover .view-cart-detail-mobile{
		display: block;
	}

	.blog-bg{
		min-height: 150px;
	}
	.medium-banner h2{
		font-size: 32px;
	}

	.sub-heading-section{
		display: block !important;
	}
	.sub-heading-section ul{
		align-items: start;
	}

	.blog-section{
		padding: 50px 0 50px;
	}
	.first-post .blog-heading {
		padding: 20px;
	}

	.first-post .blog-heading h5, .first-post .blog-heading h5 a, .blog-column h5 a{
		font-size: 22px;
	}

	.related-experience .card {
		margin-bottom: 20px;
	}

	.related-experience .card .card-body p{
		margin-bottom: 10px;
		font-size: 16px;
	}

	.related-experience .card .card-body .card-title{
		font-size: 18px;
		margin: 0;
	}

	.popular-lisitng .col-lg-3{
		width: 25%;
	}

	.popular-lisitng .col-lg-9{
		width: 75%;
	}

	.blue-bg.blog-detail-related {
		padding: 0 !important;
	}

	.testimonial.testimonialabout .carousel .testimonial{
		font-size: 14px;
		padding: 20px 0 0;
	}

	.testimonial.testimonialabout .carousel .img-box img {
		width: 100%;
		height: auto;
		margin: 14px 0 0;
	}
	.testimonial.testimonialabout .carousel .overview {
		margin: 14px 0 0 15px;
		line-height: 16px;
	}

	.testimonial.testimonialabout .carousel .overview b {
		font-size: 14px;
		line-height: 16px;
	}

	.testimonial .carousel .overview span{
		font-size: 12px;
	}

	.weekend-made-easy.my-5 {
		margin: 0 !important;
		padding-top: 0 !important;
	}

	.center-banner{
		min-height: 220px;
	}
	.career-bg{
		padding: 50px 0;
	}

	.empower-future-main{
		padding: 0;
	}

	.empower-blog-main {
		width: auto;
	}

	.value-img-main {
		padding-top: 20px;
		display: flex;
		justify-content: center;
		word-wrap: normal;
		flex-wrap: wrap;
		float: left;
	}

	.value-img {
		width: auto;
		float: left;
		margin: 5px;
	}


	.keep-emp-happy .row.flex-nowrap{
		display: block !important;
	}

	.lets-spread{
		padding: 30px 0 30px;
	}

	.keep-emp-happy .row.flex-nowrap .col{
		flex-basis: revert;
	}

	.faq-top-img{
		padding: 50px 0;
	}

	.accodiyan_block {
		box-shadow: none;
		margin: 20px auto;
	}

	.vender-background-image1{
		width: 90%;
	}
	.promobox.promobox-apply.justify-content-between{
		margin-bottom: 7px;
	}
	.catagory-top-img{
		padding: 50px 0;
		display: none;
	}

	.mood-ul .owl-theme .owl-nav .owl-prev {
		margin-left: -45px !important;
	}

	.mood-ul .owl-theme .owl-nav .owl-next {
		margin-right: -45px !important;
	}


	.signup-form-col .res-row {
		flex-direction: column;
	}
	.form-col-banner {
		width: 100%;
	}
	.signin-banner .form-col-left, .signup-banner .form-col-left {
		width: 100%;
	}

	.sub-heading-section {
		width: 100%;
		flex-direction: column;
	}
	.sub-heading-section ul.shareul li {
		float: left;
		color: #6d6e70;
		padding: 10px 10px 0 0;
	}
	.sub-heading-section ul.shareul li img {
		width: 30px;
	}

	.success-order .user-address .img-fluid{
		width: 100%;
		margin-bottom: 15px;
	}

	.order-summary .pt-5{
		padding-top: 15px !important;
	}

	.order-summary .font-weight-bold {
		color: #34383b;
	}

	.planday-mobile .mood-ul .item p {
		height: 27px;
		font-size: 11px;
		line-height: 13px;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	.row.planday.popup-mood-slider h2 {
		margin-bottom: 15px !important;
	}

	.row.planday.popup-mood-slider .btn-days .btn{
		padding: .300rem 1.25rem;
	}

	.planday-mobile .mood-ul .item img {
		margin: 30px 0 0px;
		width: 50% !important;
		height: 37px;
	}
	.custom-modal-popup{
		height:85%;
		width: 95%;
		left: 2.5%;
		top: 68px;

	}



	.rating-p{
		background:transparent;
		float: left;
		padding: 0;
	}

	.login-signup-option .btn {
		border-radius: .3rem 0 0 0 !important;
		height: 50px;
	}

	.login-signup-option .btn + .btn {
		border-radius: 0 .3rem 0 0 !important;
		margin: 0 0 0 1px;
	}

	.login-signup-option .btn:hover{
		background: #ec1c24;
		border: none;
	}
	.corporate-welcome{
		padding: 50px 0 0;
	}

	.medium-banner{
		min-height: 150px;
	}

	.experience-the-heart {
		margin: 0 0 15px;
	}

	.corporate-stay-together{
		padding: 50px 0;
	}

	.corporate-stay-content h3 {
		font-size: 37px;
	}
	.weekend-made-right-text.side-col {
		margin: 20px 0 0;
	}
	.our-value {
		padding-top: 20px;
	}

	.term-condition-bg, .privacy-policy-bg{
		padding: 20px 0;
	}

	.blog-hd-main{
		margin-top: 0;
	}

	.corporate-touch{
		padding: 20px 0;
	}

	.checkout-section h2 {
		font-size: 18px;
		margin: 0;
	}
	.product-img-block .actions {
		margin-bottom: 10px;
	}

}


@media (max-width:768px) {


	.help-desk-col{

		border: none;
	}
	.for-web-custom{

		display: none;
	}


	.for-mobile-custom{

		display: block;
	}


	.btn{
		padding: 10px 18px 9px;
	}


	.icon-and-img .carousel-item, .icon-and-img .listing-img {
		max-width: 100%;
	}

	.listing-page-title {
		padding: 20px 0 10px;
		background:#f5f5f5;
	}

	.filter-web{
		display: none;
	}

	.fliter-mobile{
		display: block;
	}

	.filter-mobile-btn{
		background: transparent;
		border:none;
		font-size: 16px;
		color: #34383b;
		font-weight: 600 !important;
		padding: 0;
	}
	.filter-mobile-btn i{
		color:#858688
	}

	.filter-mobile-open {
		position: fixed;
		top: 0;
		left: 0;
		background: #fff;
		z-index: 999;
		padding: 20px;
		width:100%;
		overflow: auto;
		height: 100%;
	}

	.filter-mobile-open ul{
		padding: 0;
		margin: 0;
		list-style-type: none;
	}

	.filter-mobile-open ul li{
		width: 100%;
		float:left;
		margin:0 0 10px;
	}

	.filter-mobile-open ul li.mobilefilter-title{
		margin-bottom: 25px;
		font-size: 24px;
		color: #34383b;
		font-weight: 600 !important;
		cursor: pointer;
	}

	.filter-mobile-open ul li.mobilefilter-title i{
		color:#858688
	}

	.fillter-sec .filter-mobile-open ul li label + .MuiInput-formControl{
		border: 1px solid #ddd !important;
	}

	.fillter-sec .filter-mobile-open ul li:nth-child(2) div.MuiFormControl-root {
		min-width: 135px;
		max-width: 135px;
		margin-left: 0;
	}

	.fillter-sec .filter-mobile-open ul li:nth-child(3) div.MuiFormControl-root,
	.fillter-sec .filter-mobile-open ul li:nth-child(4) div.MuiFormControl-root {
		min-width: 110px;
		max-width: 110px;
		margin-left: 0;
	}

	.fillter-sec .filter-mobile-open ul li:nth-child(5) div.MuiFormControl-root {
		min-width: 120px;
		max-width: 120px;
		margin-left: 0;
	}

	.fillter-sec .filter-mobile-open ul li:nth-child(6) div.MuiFormControl-root {
		min-width: 165px;
		max-width: 165px;
		margin-left: 0;
	}

	.fillter-sec .filter-mobile-open ul li:nth-child(7) div.MuiFormControl-root {
		min-width: 100px;
		max-width: 100px;
		margin-left: 0;
	}

	.fillter-sec .filter-mobile-open ul li:nth-child(8) div.MuiFormControl-root {
		min-width: 110px;
		max-width: 110px;
		margin-left: 0;
	}

	.fillter-sec .filter-mobile-open ul li:nth-child(9) div.MuiFormControl-root {
		min-width: 130px;
		max-width: 130px;
		margin-left: 0;
	}

	.price-range{
		margin: 0;
	}

	.price-range .btn {

		border: 1px solid #ddd;
		border-radius: 20px !important;
		width: 122px;
		padding-left: 9px;

	}



	.fillter-sec .filter-mobile-open ul li:last-child{
		border-top: 1px solid#ddd;
		margin: 30px 0 0;
		padding: 20px 0;
	}

	.fillter-sec .filter-mobile-open ul li .blue-btn.btn.btn-primary{
		display: block;
		margin: 0;
		min-width: 98px;
		height: 41px;
		font-size: 14px;
	}


	.btn-blog-newsletter-submit,.contact-us-submit{padding: 5px 18px!important; }


}
@media (max-width:500px) {
	.checkout-section .product-img {
		border-radius: 5px;
		overflow: hidden;
		height: 60px;
	}


	.wl_share button{padding: 6px;}

}

.confirm-mail-popup h1 {
    text-align: center;
}

.confirm-mail-popup h1 i {
    background: #0080c1;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	color:#fff !important;
    font-size: 47px !important;
    padding: 17px 0 0;
}

.confirm-mail-popup .form-heading {
    color:#34383b;
    padding-top: 15px;
}

.btn.refresh.btn-primary, .btn.refresh.btn-primary:hover {
    background: transparent !important;
	border: none !important;
	color:#00adee !important;
}

.email-confirm p {

    text-transform: initial;

}

.reset-block .or {
    margin: 10px 0 !important;
}



.reset-block .text-link {
	margin: 0 10px;
	text-decoration: underline;
}
.success-order img{

	height:160px;width:230px;
object-fit: cover;
}

.reset-block .text-link:first-child {
    margin: 0 10px 0 0;
}

.res-row.d-flex.justify-content-between {
    min-height: 400px;
}

.custom-input-otp input.form-control {
    width: 12%;
    display: inline-block;
    margin: 0 10px;
}

.custom-input-otp input.form-control:first-child{
	margin-left: 0;
}


.btn-newsletter-submit{

	background: white!important;


	color: #EC1C24;
	font-size: 16px;
	width: 100%;
	height: 46px;
	margin: 18px 0 0;
	font-weight: 400;
}

.btn-newsletter-submit:hover{

	background: white!important;


	color: #EC1C24;
	font-size: 16px;
	width: 100%;
	height: 46px;
	margin: 18px 0 0;
	font-weight: 400;
}
.newsletter-form input:focus{background: transparent;color:white;}

.newsletter-form input:disabled{background-color: #00000020;
color:#cccccc;
}
.pro-detail .detail-info img{width: 22px!important;height: 22px!important;}
/*.modal-content{background: #fff!important;}*/


#covid-strip{z-index: 998; height: auto; min-height:55px;text-align: center;background-color :#f37521;color:white; margin: auto;position: relative; }

#covid-strip span, #covid-strip a{color: white!important; font-weight: bold;}
#covid-strip img{margin-right: 7px;}

@media (max-width:990px) {

	#covid-strip{
		padding-left: 1rem!important;
		padding-right: 1rem!important;
	}

}


/* become partner */

.custom-file-input-control {
	display: none;
}
.custom-file-upload {
	/*border: 1px solid #ccc;*/
	display: inline-block;
	padding: 6px 12px;
	cursor: pointer;
}


.upload-img-thumbnail{
	width: 60px;height: auto;border-radius: 10%;
	max-height: 60px!important;
	margin-left:15px;
}



/*  Hide bootstrap green correct sign for input fields */

/*.form-control.is-valid, .was-validated .form-control:valid{*/

	/*background-image: none!important;*/
/*}*/


.my-small-text {
	font-size: 12px !important;
	opacity: .6;
	margin: 8px !important;
}

.perfect-weekened h1 .country-dropdown-a {
	color: #34383b;
	position: relative;
	padding-left: 10px;
	text-decoration: underline;
	cursor: pointer;
}
.country-dropdown .country-dropdown-a-list-item {
	font-size: 18px;
	color: #34383b;
	font-weight: 300;
	text-decoration: none;
	cursor: pointer;
}

.form-control.is-valid, .was-validated .form-control:valid{
	background-image: none;
}





.status-button{

	margin: 10px;background-color:#efefef;;
	border : #efefef;
	color:#34383b;
	font-weight:normal;

}

.status-button.isActive{

	margin: 10px;
	background-color:#ec1c24;;
	border : #ec1c24;
	color:white;
	font-weight:normal;

}

.status-button.isActive:after{

	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 10px solid #ec1c24;
	position: absolute;
	content: '';
	left: 49%;
	bottom: -7px;
	right: 0;
	text-align: center;
	transform: translateX(-49%);


}


.rating-total{

	vertical-align: top;
}



/* vendor dashboard */

.add-activity-block table>tr:last-child>td{border: none!important;}
.add-activity-block .tab-content{

	padding: 15px;
	border-width: 1px 1px 1px 1px;
	border-color: #ddd;
	border-style: solid;

}

.add-activity-block .nav-tabs .nav-item{

	margin: 1px 1px -1px 0px!important;
}

.add-activity-block .btn-days>.btn {padding: 5px 14px!important; font-size:16px;font-weight:400;margin-bottom:20px;float:left;}

.loginBtn--google{
	cursor: pointer;
}
.fb-btn{cursor: pointer;}

.react-tel-input .form-control{
	height: 44px!important;
	width: 100%!important;
	border: 1px solid #CACACA!important;
	background: #e5e6e7!important;

}
.bundle-name-heading{background: #ec1c24;color: white;padding-right:5px;padding-left:5px;border-radius:5px;}
.letsplan{
	overflow-x: hidden;
}

.react-tel-input .is-invalid{

	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e")!important;
	background-repeat: no-repeat!important;
	background-position: right calc(.375em + .1875rem) center!important;
	background-size: calc(.75em + .375rem) calc(.75em + .375rem) !important;
}

.invalid-feedback-phone {
	display: block;
	width: 100%;
	margin-top: .25rem;
	font-size: 80%;
	color: #dc3545;
}

.online-booking-sec .react-tel-input .flag-dropdown {
	position: absolute;
	top: auto!important;
	bottom: 0;
	padding: 0;
	background-color: #f5f5f5;
	border: 1px solid #cacaca;
	border-radius: 3px 0 0 3px;
	height: 44px!important;
}

.detailtabs .MuiTabs-scrollButtons{
	width: 0px!important;
}


.item-online {
	color: white;
	background: #EC1C24;
	width: auto;
	padding: 2px 5px;
	border-radius: 5px;
	max-width: 70px;
	text-align: center;
	font-weight: 600;
	margin-bottom: 5px;
	font-size: 14px;
	display: block;
}

.PersonalisedWeekends .item-online{

	font-size: 12px;
	max-width: 60px;

}

.product-head .item-online{

	margin-top:30px;
}

.pro-detail .MuiTab-root {
	padding: 6px 0px 6px 0px;
	margin-right: 30px
}

.footer-bottom-link{padding: 40px 00px  40px 0px; backgrund:white;}
.footer-bottom-link:before{display: none;}
.footer-bottom-link div{color: white;}


.footer-bottom-link{

	background: #151515;
}

.footer-bottom-link>div>div>div>ul>li{margin-bottom: 10px;}

@media (max-width:990px) {

	.footer-bottom-link
	li::after {
		display: none;
	}

	.footer-bottom-link li {

		font-size: 14px;
		padding: 0 0px 0;
		float: left;
		position: relative;

	}

	.footer-bottom-link{
		padding: 40px 0px 40px 0px;
	}


}

.bundle-or-price{display: inline-flex!important;}
.bundle-right-column{padding-left: 0;}
.trend-item-small .wl-info-box .item-heading{
	font-size: 14px!important;
	line-height:18px!important;
}

.trend-item-small .wl-info-box .item-heading{
	font-size: 14px!important;
	line-height:18px!important;

}

.trend-item-small .wl-info-box{

	margin-bottom: -10px;
}

.slide-link{
	z-index: 1;
}

.edit-activity-popup-form .modal-dialog{
	width: 80%!important;
	max-width: 80%!important;

}


 .tab-content .tab-pane textarea {
	border: none;
	background-color: #EFEFEF;
	padding: 8px;
	width: 100%;
	border-radius: 5px;
	font-size: 14px;
	margin: 0;
	min-height: 150px;
}

.priceoption-table td, .priceoption-table th{

	padding: .75rem .75rem .75rem 0!important;
}
