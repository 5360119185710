.pro-detail {
    width: 100%;
    position: relative;
    background:#f5f5f5;
    float: left;
    padding: 30px 0;
}
#rightCol {
    margin-bottom: 50px;
    padding-right: 0 !important;
    padding-left: 0 !important;
}
#sticky-box {
    position: sticky !important;
    top: 93px;
    overflow-y: hidden !important;
    padding-right: 12px;
    padding-left: 12px;
}
.pro-detail .right-section {
    float: left;
    width: 100%;
    margin: 0;
    display: block;
}
.pro-detail .offers {
    width: 100%;
    float: left;
    background: #fff;
    border-radius: 10px;
    min-height: 20px;
    margin: 0 0 20px;
}
.pro-detail .top-block {
    width: 100%;
    float: left;
}
.pro-detail .top-block p {
    font-size: 14px;
    color:#6d6e70;
    float: left;
    line-height: 27px;
}
.pro-detail .right-section .block {
    width: 100%;
    float: left;
    padding: 18px 0;
}
.pro-detail .right-section .block .date-label{
    font-size: 18px;
    font-weight: 600;
    color:#34383b;
}
.pro-detail .right-section .block .custom-select{
    border:1px solid #dbdbdc;
    background-color: transparent;
    height: 40px;
    cursor: pointer;
    font-size: 16px !important;
    font-weight: 600;
    padding-right: 0;
}
.pro-detail .right-section .block .custom-select .MuiInputBase-root{
    font-size: 16px !important;
    font-weight: 500;
}
.pro-detail .right-section .block .form-group{
    margin: 0;
}
.pro-detail .block .left-side {
    width: 72%;
    float: left;
}
.pro-detail .block .right-side {
    float: right;
    width: 28%;
    align-self: end;
    text-align: right;
}
.pro-detail .top-block {
    width: 100%;
    float: left;
}
.pro-detail .top-block h4 {
    float: left;
    font-size: 16px;
    margin: 6px 0 15px;
    width: 100%;
    color:#34383b;
}
.value-button {
    display: inline-block;
    border: 1px solid #ddd;
    margin: 0px 0 0;
    width: 30px;
    height: 30px;
    text-align: center;
    vertical-align: middle;
    padding: 0;
    background:transparent;
    user-select: none;
    line-height: 28px;
    border-radius: 50%;
}
input.number {
    text-align: center;
    border: none;
    margin: 0px;
    width: 30px;
    height: 30px;
    font-size: 18px;
}
.pro-detail .right-side p.totaloff {
    font-size: 11px;
    color: #6a8e06;
    background:#e4f0c3;
    border: 1px solid #6a8e06;
    width: 82% !important;
    height: 27px;
    line-height: 27px;
    float: right;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 5px;
}
p.totaloff {
    color:#6a8e06 !important;
    background:#e4f0c3 !important;
    border: 1px solid#6a8e06;
    width: 53px !important;
    height: 27px;
    line-height: 27px;
    float: right !important;
    text-align: center;
    border-radius: 5px;
}
.pro-detail .offer-data-right .text-line {
    text-decoration: line-through;
    font-size: 14px;
    margin: 0;
    color:#858688;
}
.pro-detail .offer-data-right h4 {
    font-size: 16px;
    margin: 0;
    float: right;
    color:#34383b;
    font-weight: 700 !important;
}
.pro-detail .order-total {
    float: left;
    padding: 10px 0;
    width: 100%;
    margin-left: 0;
}
.pro-detail .order-total p {
    font-size: 18px;
}
.pro-detail .order-total p.small-text {
    font-size: 14px !important;
    margin: 0 !important;
    color:#858688;
    opacity: 1;
}
.pro-detail .order-total h5 {
    font-size: 18px;
}
.pro-detail .promo-block {
    width: 100%;
    float: left;
    padding: 0 0 15px;
}
.pro-detail .buy-now {
    width: 100%;
    float: left;
    text-align: center;
    height: 50px;
    background:#ec1c24;
    color:#fff;
    border-radius: 6px;
    line-height: 50px;
    font-size: 18px;
    font-weight: 600;
}
.pro-detail .sold-out {
    width: 100%;
    float: left;
    text-align: center;
    height: 50px;
    background:transparent;
    border: 1px solid #ec1c24;
    color:#ec1c24;
    border-radius: 6px;
    line-height: 50px;
    font-size: 18px;
    font-weight: 600;
    opacity: 0.25;
    cursor: not-allowed;
    margin: 10px 0;
}
.pro-detail .submit-interest {
    width: 100%;
    float: left;
    text-align: center;
    height: 50px;
    background:#00adee;
    color:#fff;
    border-radius: 6px;
    line-height: 53px;
    font-size: 18px;
    font-weight: 600;
    border: none;
    position: relative;
}
.pro-detail .submit-interest img {
    margin: -2px 0 0;
    display: inline-block;
}
.pro-detail .submit-interest:hover .interest-tooltip{
    display: block;
}
.interest-tooltip {
    padding: 10px;
    position: absolute;
    width: 340px;
    word-wrap: break-word;
    white-space: break-spaces;
    text-align: left;
    box-shadow: 1px 1px 20px #aaa;
    border-radius: 5px;
    background-color: #fff;
    bottom: 40px;
    left: 50%;
    transform: translate(-50%);
    transform-style: preserve-3d;
    z-index: 200;
    display: none;
}
.interest-tooltip p{
    color:#484a4d !important;
    font-size: 14px !important;
    line-height: 24px;
    margin: 0 !important;
    padding: 0 30px 0 0;
}
.pro-detail .submit-interest .interest-tooltip img {
    position: absolute;
    right: 9px;
    top: 11px;
}
.interest-tooltip::after {
    content: "";
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    transform-origin: 50% 50%;
    transform: rotate(45deg) translateX(-68.5%);
    background-color: #fff;
    left: 68.5%;
    bottom: -8px;
    z-index: 400;
}
.promo-box {
    border: 1px dashed #dddddd;
    margin-left: 0;
    position: relative;
    font-weight: normal;
    width: 100%;
    padding: 10px;
    background:#fff;
    border-radius: 10px;
    float: left;
}
.promo-box p {
    font-size: 12px;
    margin: 0;
    opacity: 0.6;
}
.promo-box p span.text-red {
    color: #ec1c24;
}
.pro-detail .productinfo {
    background: #fff;
    float: left;
    border-radius: 10px;
    padding: 0;
    width: 100%;
    box-shadow: 3px 0 30px rgba(0,0,0,0.1);
    margin-bottom: 15px !important;
}
.pro-detail .productinfo .image-gallery-slide img{
    border-radius: 5px;
}
.pro-detail .nav-pills {
    padding-left: 15px !important;
}
.productinfo .tab-pane .row {
    margin: 0 !important;
}
.detailtabs{
    border-bottom: 1px solid #dee2e6;
    box-shadow: none !important;
    background: transparent !important;
}

.detailtabs .MuiTab-textColorPrimary {
    color:#00adee;
    font-size: 15px;
    font-weight: 600;
    min-width: auto;
}


.detailtabs .MuiTab-textColorPrimary.Mui-selected{
    color:#34383b;
}

.detailtabs .MuiTabs-scrollButtons{
    width:25px;
}

.detailtabs .PrivateTabIndicator-colorPrimary-74{
    background-color:#007dc4;
    height: 3px;
    bottom: 0px;
}
.pro-detail .text-block {
    width: 100%;
    float: left;
    padding:40px 0 40px;
    border-bottom: 1px solid #ddd;
}
.pro-detail .text-block p{
    color:#6d6e70;
    font-size: 16px;
    margin: 0 0 10px;
}
.pro-detail .text-block ul{
    margin: 0;
    padding: 0;
    list-style-type: none;
}
.pro-detail .text-block ul li {
    padding: 0 0 15px 36px;
    color:#6d6e70;
    font-size: 16px !important;
}
.pro-detail .text-block ul li:last-child{
    padding-bottom: 0;
}
/*.pro-detail .text-block ul.include li{*/
    /*background-image: url(../img/tick.png);*/
    /*background-repeat: no-repeat;*/
    /*background-position: left 3px;*/
    /*list-style-type: none;*/
/*}*/
/*.pro-detail .text-block ul.exclude li{*/
    /*background-image: url(../img/cross.png);*/
    /*background-repeat: no-repeat;*/
    /*background-position: left 3px;*/
    /*list-style-type: none;*/
/*}*/

 .text-block ul.include li{
    background-image: url(../img/tick.png);
    background-repeat: no-repeat;
    background-position: left 3px;
    list-style-type: none;
}
 .text-block ul.exclude li{
    background-image: url(../img/cross.png);
    background-repeat: no-repeat;
    background-position: left 3px;
    list-style-type: none;
}

.pro-detail .text-block.dot-ul li{
    background-image: url(../img/dot.png);
    background-repeat: no-repeat;
    background-position: left 8px;
    padding: 0 0 13px 30px;
    list-style-type: none;
    width: 100%;
    float: left;
}
.pro-detail ul.where-ul li {
    padding: 0 0 13px 0;
}
.pro-detail ul.where-ul li span{
    font-weight: 700;
    color: #34383b;
}
.pro-detail ul.where-ul li img {
    margin: -5px 5px 0 0;
}
.pro-detail ul.where-ul li button {
    position: relative;
    font-size: 14px;
    background: #fff;
    color:#6d6e70;
    padding: 2px 10px 2px 10px;
    display: inline-block;
    margin-left: 7px;
    border-radius: 3px;
    border: 1px solid #dbdbdc;
}
.pro-detail .text-block ul.where-ul li button img {
    width: 13px;
    margin: 0;
}
.pro-detail .text-block ul.where-ul li button:hover .shedual-drop {
    display: block;
}
.shedual-drop {
    position: absolute;
    width: 441px;
    background: #fff;
    border: 1px solid#dbdbdc;
    border-radius: 5px;
    left: 0;
    top: 100%;
    overflow: hidden;
    z-index: 9;
    display: none;
}
.title {
    background: #007dc4;
    text-align: center;
    color:#fff;
    font-size: 18px;
    height: 40px;
    line-height: 40px;
    margin: 0;
}
.shedual-drop-content{
    padding: 18px;
    width: 100%;
    float: left;
}
.pro-detail .content-tabs .tab-content .text-block .shedual-drop-content p {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    font-weight: 600;
}
.pro-detail .content-tabs .tab-content .text-block .shedual-drop-content h3{
    color:#6d6e70;
    margin:18px 0 9px;
    width: 100%;
    float: left;
    text-align: left;
    font-size: 18px;
}
.days-shedual {
    width: 100%;
    float: left;
    text-align: left;
    border: 1px solid #dbdbdc;
    border-radius: 5px;
}
.days-shedual .col{
    border-right: 1px solid #dbdbdc;
}
.pro-detail .content-tabs .tab-content .text-block .shedual-drop-content .days-shedual .col:last-child{
    border: none;
}
.pro-detail .content-tabs .tab-content .text-block .shedual-drop-content .days-shedual .col h4{
    padding: 10px 15px;
    border-bottom: 1px solid#dbdbdc;
    margin: 0 !important;
    color:#34383b;
    text-transform: uppercase;
}
.see-reviews {
    font-weight: 600;
    color:#00adee;
}
.text-block p{
    color:#6d6e70 !important;
    font-size: 16px !important;
    margin: 0;
    font-family: 'Archivo', sans-serif;
}
.pro-detail .content-tabs .tab-content .text-block h5{
    color:#6d6e70;
    font-weight: 700 !important;
    text-transform: uppercase;
    font-size: 16px;
}
.pro-detail .content-tabs .tab-content .text-block .shedual-drop-content .days-shedual .col p{
    padding: 10px 15px;
    color:#6d6e70;
    font-size: 14px;
}
.map-block {
    width: 100%;
    float: left;
    border-radius: 5px;
    margin: 15px 0 0;
    overflow: hidden;
}

.map-block div{
    position: relative;
}
.less-link {
    color: #00adee;
    background:transparent;
    border: none;
    padding: 0;
    /*font-weight: 600;*/
}
.less-link img{
    margin: -1px 0 0 5px;
}
.product-head {
    width: 100%;
    float: left;
    margin: 0 0 20px;
}
.detail-info {
    width: 100%;
    float: left;
    padding: 20px 0 5px;
    border-width: 1px 0 1px 0;
    border-color:#ddd;
    border-style: solid;
    margin: 20px 0;
}
.detail-info ul{
    padding: 0;
    margin: 0;
    list-style-type: none;
}
.detail-info ul li {
    float: left;
    margin: 0 12px 15px;
    font-size: 16px;
    color:#858688;
}
.pro-detail .breadcum {
    float: left;
    list-style-type: none;
    width: 100%;
    margin: 0;
    padding: 0;
}
.pro-detail .breadcum li {
    float: left;
    padding: 0 3px;
    font-size: 14px;
}
.pro-detail .breadcum li:first-child{
    padding-left: 0;
}
.pro-detail .breadcum li img {
    width: 6px;
}
.pro-detail .image-gallery-thumbnail.active {
    border: 3px solid #34383b;
    border-radius: 5px;
}
.pro-detail h1 {
    font-size: 30px;
    width: 100%;
    float: left;
    margin: 8px 0;
    color: #34383b;
}
.pro-detail p {
    color: #858688;
    font-size: 18px;
    width: 100%;
    float: left;
    margin-bottom: 20px;
}
.pro-detail .rating {
    width: 100%;
    float: left;
}
.pro-detail .rating .detail-head-r {
    float: left;
}
.pro-detail .rating p {
    margin: 0px 0 0 13px;
    float: left;
    width: auto;
    color: #00adee;
    line-height: 20px;
    font-weight: 600;
    font-size: 14px;
}
.pro-detail .rating p span{
    color: #858688;
}
.slide-link {
    position: absolute;
    right: 33px;
    top: 20px;
}
.slide-link .heart, .share-icon {
    position: relative;
    top: 0;
    right: 0;
    float: left;
    background-color: transparent;
    border: none;
}
.share-icon {
    top: -2px;
    right: 10px;
    z-index: 9;
}
.pro-detail .content-tabs {
    width: 100%;
    float: left;
}
.tabs-bottom{
    width: 100%;
    float: left;
}
.pro-detail .nav-tabs .nav-item.show .nav-link, .pro-detail .nav-tabs .nav-link.active, .pro-detail .nav-tabs .nav-link:hover {
    color: #34383b;
    background-color:transparent;
    border-color:#007dc4;
    border-width: 0 0 3px;
    border-style: solid;
}
.pro-detail .nav-tabs .nav-link{
    color: #00adee;
    background-color:transparent;
    border-color:transparent;
    font-weight: 600 !important;
    border-color:transparent;
    border-width: 0 0 3px;
    border-style: solid;
    text-transform: uppercase;
    padding: .5rem 10px;
    font-size: 15px;
}
.pro-detail .detailtab-dis h4 {
    font-size: 21px;
    margin: 0 0 15px;
    font-weight: 700 !important;
    color: #34383b;
}
.pro-detail .review-section {
    width: 100%;
    float: left;
    margin: 40px 0 20px;
}
.pro-detail .review-section .rating p{
    font-size: 18px;
}
.pro-detail .review-section h3{
    font-size: 21px;
    color:#34383b;
    font-weight: 700 !important;
}
.pro-detail .rating img {
    float: left;
}
.pro-detail .review-verify {
    background: #fff;
    padding: 20px;
    width: 100%;
    float: left;
    border-radius: 10px;
    margin: 30px 0 0;
}
.pro-detail .review-verify img {
    float: left;
    margin: -5px 20px 0 0;
}
.pro-detail .verifycontent {
    float: left;
}
.pro-detail .verifycontent h5 {
    font-size: 16px;
    margin: 0;
    color: #34383b;
}
.pro-detail .verifycontent p {
    font-size: 16px;
    margin: 0;
    color: #6d6e70;
}
.pro-detail .reviews {
    width: 100%;
    float: left;
    margin: 20px 0 0;
}
.pro-detail .block {
    width: 100%;
    float: left;
}
.pro-detail .reviews img.user {
    float: left;
    margin: 0 20px 0 0;
    border-radius: 50%;
}

.pro-detail .reviews i.user {
    font-size: 30px;
    float: left;
    margin: 0 20px 0 0;
    border-radius: 50%;
    width: 46px;
    height: 46px;
    background:#ddd;
    text-align: center;
    line-height: 44px;
}
.pro-detail .reviewcontent {
    float: left;
    width: 90%;
}
.pro-detail .reviews .reviewcontent h5 {
    margin: 14px 0;
    font-size: 18px;
    font-weight: 700 !important;
    color:#34383b;
}
.pro-detail .reviews .reviewcontent p {
    font-size: 14px !important;
    color:#858688;
    margin-bottom: 9px !important;
    opacity: 0.9 !important;
}
.review-section .review-box {
    width: 100%;
    min-height: 440px;
    background: #fff;
    border-radius:15px;
    padding: 20px;
    float: left;
    margin: 20px 0 0;
}
.form-control {
    background:#efefef;
    height: 45px;
    border: none;
}
.review-section .review-box .form-row .form-group{
    margin-bottom:25px;
}
.review-section .review-box label{
    color:#34383b;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
    width: 100%;
}
.review-section .review-box textarea{
    height: 120px;
    resize: none;
}
.pro-detail .wl_share{
    margin: 40px 0;
}
.pro-detail .wl_share p {
    padding: 9px 20px 0 0;
    width: auto;
    margin: 0;
}
.pro-detail .reviews .reviewcontent p span {
    font-size: 24px;
    color: #34383b;
    font-weight: 600 !important;
    float: left;
    margin-right:0;
    line-height: 24px;
}

.pro-detail .reviews .reviewcontent p span.rating-total {
    margin-right: 10px;
}

.pro-detail .reviews .reviewcontent p span svg {
    font-size: 14px;
    margin:9px 0 0 0;
}

.pro-detail .reviews .reviewcontent p.ratings img {
    margin: 16px 1px 0;
    float: left;
    width: 11px;
}
.pro-detail .wl_share a {
    margin: 0 10px 0 0;
}
.buy-now-error{
    border: 1px dashed #ec1c24!important;
}
.pro-detail .product-head p.rating-p {
    background: transparent;
    padding: 0;
}

.pro-detail .product-head p.rating-p a{
    color:#34383b;
}
.pro-detail .product-head p.rating-p a i{
    color:#007bff;
}
.pro-detail .product-head p {
    font-size: 16px;
    background: url(../img/map.png);
    background-repeat: no-repeat;
    padding-left: 21px;
    margin-bottom: 8px;
}
.product-head .for-mobile p.totaloff{
    margin: 10px 0 5px !important;
}
.image-gallery-thumbnails {
    overflow: hidden;
    padding: 10px 0;
}


.pro-detail .image-gallery-image{
    height: 430px;
    overflow: hidden;
}
.image-gallery-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.pro-detail .image-gallery-thumbnail {
    width: 120px;
    height: 80px;
    overflow: hidden;
}
.pro-detail .image-gallery-thumbnail img{
    height: 100%;
    object-fit: cover;
}
.pro-detail .image-gallery-thumbnail .image-gallery-thumbnail-inner {
    position: relative;
    width: 100%;
    height:100%;
    overflow: hidden;
}
.image-gallery-play-button, .image-gallery-fullscreen-button {
    display: none;
}
.for-web{
    display: flex;
}
.for-mobile{
    display: none;
}

.mobile-price{
    display: none;
}
@media (max-width:1024px) {
    .pro-detail .nav-tabs .nav-link{
        width: 25%;
        text-align: center;
    }
    .pro-detail .nav-tabs .nav-item.show .nav-link,
    .pro-detail .nav-tabs .nav-link.active,
    .pro-detail .nav-tabs .nav-link:hover{
        background: #fff;
    }
    .pro-detail .text-block{
        padding: 30px 0;
    }
    .pro-detail .verifycontent {
        width: 85%;
    }
}


.detailtabs .MuiTabs-scrollButtons{width: 0;}

.pro-detail .MuiBox-root{padding: 0px;}


@media (max-width:990px) {



    .detailtabs .MuiTabs-scrollButtons{width: 25px;}



    .for-web{
        display: none;
    }
    .for-mobile{
        display: flex;
    }
    .for-mobile .col-sm-9.col-xs-9 {
        width: 70%;
    }
    .for-mobile .col-sm-3.col-xs-3 {
        width: 30%;
    }
    .product-head{
        margin-bottom:15px;
    }
    .product-head p.totaloff {
        color:#6a8e06 !important;
        background:#e4f0c3 !important;
        border: 1px solid#6a8e06;
        width: 53px !important;
        height: 27px;
        line-height: 27px;
        float: right !important;
        text-align: center;
        border-radius: 5px;
        padding: 0 !important;
        font-size: 12px !important;
        margin: 0 0 5px !important;
    }
    .for-mobile .price {
        color:#34383b;
        text-align: right;
        font-weight: 600;
        font-size: 16px;
        width: 100%;
        float: right;
    }
    .or-price, .or-price .line-through{
        font-size: 14px;
        width: 100%;
        float: right;
    }
    .pro-detail h1 {
        font-size: 18px;
        margin: 10px 0;
    }
    .pro-detail .product-head p{
        font-size: 16px;
        background: url(../img/map.png);
        background-repeat: no-repeat;
        padding-left: 21px;
        margin-bottom:0;
    }
    .pro-detail .nav-tabs .nav-link {
        width: 100%;
        text-align: center;
    }
    .pro-detail .text-block li {
        padding: 0 0 20px 36px;
    }
    .pro-detail .text-block ul {
        margin: 20px 0 0;
    }
    .pro-detail .text-block ul.where-ul li {
        padding: 0 0 10px 0;
    }
    .pro-detail .text-block ul.where-ul li button {
        margin-left: 24px;
    }

    .pro-detail .review-section{
        margin-top: 0;
    }

    .review-section .review-box{
        padding: 20px 0;
    }

    .pro-detail .wl_share p{
        width: 100%;
    }

    .pro-detail .wl_share {
        margin: 20px 0 0;
    }
    .pro-detail .text-block:last-child{
        border-bottom: 0px;
    }
    .order-total .col-md-7 {
        width: 70%;
    }
    .order-total .col-md-5 {
        width: 30%;
    }
    #rightCol{
        margin-bottom: 0;
    }
    .productinfo .tab-content{
        margin-bottom: 5px;
    }

    .image-gallery-slides{
        border-radius: 5px;
    }
    .pro-detail .image-gallery-image {
        height:230px;
        overflow: hidden;
    }
    .pro-detail .image-gallery-thumbnail {
        width: 90px;
        height: 60px;
        overflow: hidden;
    }
    .pro-detail .image-gallery-thumbnail img{
        height: 60px;
    }
    .detailtabs .MuiSvgIcon-fontSizeSmall{
        font-size: 22px;
    }

    #rightColrightCol{
        display: none   ;
    }

    .primaryguestform .order-total {
        display: none;
    }

    .procedd-block.border-none.web-price {
        display: none;
    }

    .mobile-price {
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        z-index: 999;
        display: block;
    }

    .Show.Option {
        display: flex;
        justify-content: space-between;
        background:#fff;
        align-items: center;
        padding: 8px 10px;
        border-radius: 0;
    }

    .Show.Option .red-btn{
        height: 50px;
        margin: 10px 0 0;
    }

    .Show.Option h1{
        font-size: 22px;
        margin: 0;
        display: none;
    }

    .options-show {
        width: 100%;
        text-align: center;
        height: 50px;
        background: #ec1c24;
        color:#fff;
        border-radius: 6px;
        line-height: 50px;
        font-size: 18px;
        font-weight: 600;
        border: none;
    }

    .mobile-pricing-view {
        position: fixed;
        width: 100%;
        height: 100%;
        background: #fff;
        z-index: 9999;
        left: 0;
        top: 0;
        overflow:auto;

    }

    .mobile-pricing-view  .custom-select {
        border: 1px solid #dbdbdc;
        background-color:transparent;
        height: 40px;
        cursor: pointer;
        font-size: 16px !important;
        font-weight: 600;
        padding-right: 0;
    }

    .cross-btn{
        border: none;
        background:
                transparent;
        font-size: 22px;
        float: right;
    }

    .pro-detail .block .right-side{
        width: 24%;
    }

    .pro-detail .block{
        padding:20px 0;
    }

    p.bought-total {
        font-size: 14px;
        color:#6d6e70;
        float: left;
        line-height: 27px;
    }

}

.detail-info>.row>.col-lg-12{padding-left: 0;}

.shedual-drop-content h4{font-size: 16px!important;text-align: center!important; width: 100%!important;margin:10px auto;
    padding: 0!important;text-transform: uppercase!important;}
.shedual-drop-content h3{font-size: 18px;color: #6d6e70; text-align: center; margin:5px;}
.shedual-drop-content p{color: #6d6e70; text-align: left;}
.shedual-drop-content h4{ margin: 5px auto!important;}

input:focus, textarea:focus, select:focus, button:focus, a:focus, div:focus{
    outline: none!important;
}

.shedual-drop-content div h4{border-bottom: 1px solid #dbdbdc;padding: 0px!important;width: 100%!important;padding-bottom:8px!important;}
.shedual-drop-content .col{padding: 0; text-align:center; }
.shedual-drop-content p{text-align: center;}