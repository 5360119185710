
.label-file-input{

    background: #eee;
    border-radius: 5px;
    display: flex!important;
    text-align: center;
    height: 80px!important;
    width: 80px!important;
    margin-right: 15px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.file-uploader-thumbnail{
    position: relative
}
.file-uploader-thumbnail{
    height: 80px;
    width: 80px;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    border-radius: 10%;

}


.file-uploader-thumbnail-container{

    display: inline-flex;


}
.file-uploader-thumbnail{margin-right: 15px; margin-bottom: 15px;margin-top: 15px;}
.file-uploader-thumbnail-container>.file-uploader-thumbnail{

    cursor: pointer;
}
.file-uploader-thumbnail-container .file-upload-img-thumbnail-cancel{

    cursor: pointer;
    color: white;
    background: transparent;
    border-radius: 50%;
    position: relative;
    margin: 2px;


}


.center-spinner{
    position: absolute;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    margin: auto;

}

.file-uploader-thumbnail-container .file-upload-img-thumbnail-check{


    position: absolute;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;

    /*border: 1px solid #cccccc;*/
    /*color: white;*/
    /*background: transparent;*/
    /*border-radius: 50%;*/


}


.file-upload-img-thumbnail-error{

    position: absolute;
    width: 20px;
    height:20px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    color:#ccc ;
}

.file-uploader-thumbnail{

    -webkit-box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.4);
    -moz-box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.4);
    box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.4);
}
.file-upload-img-thumbnail-cancel{border: 1px solid #cccccc; background: #cccccc!important; }


