.admin-haeder{
    padding: 40px 0;
    width: 100%;
    float: left;
}

.avatar-upload {
    position: relative;
    width: 100px;
    float: left;
}

.avatar-upload .avatar-edit {
    position: absolute;
    right: 11px;
    z-index: 1;
    top: 0px;
}

.avatar-upload .avatar-edit label {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-bottom: 0;
    border-radius: 100%;
    background: #ec1c24;
    color:#fff;
    border: 1px solid#fff;
    cursor: pointer;
    font-weight: normal;
    transition: all 0.2s ease-in-out;
}

.avatar-upload .avatar-edit label::after {
    content: "\f040";
    font-family: 'FontAwesome';
    color:
            #ffffff;
    position: absolute;
    top: 44%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;
    content: '';
}

.avatar-upload .avatar-preview {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 100%;
    border: 5px solid #F8F8F8;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.avatar-upload .avatar-preview > div {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(./img/bollywood-1.png);
}

.user-name {
    float: left;
    width: auto;
    color: #fff;
    margin: 19px 0 0 15px;
}

.user-name h5 {
    font-size: 16px;
    margin: 0;
    color: #fff;
}

.user-name h2 {
    font-size: 24px;
    font-weight: bold;
    color:#fff;
}

.activity-status_info {
    float: left;
    color: #fff;
    margin: 0 0 0 30px;
}

.activity-status_info a {
    color: #fff;
    text-decoration: underline;
}

.Admintab{
    width: 100%;
    float: left;
    background: #00649d;
}

.Admintab ul.nav{
    margin: 0 !important;
}

.Admintab .nav-pills .nav-link.active, .Admintab .nav-pills .show > .nav-link {
    color: #fff;
    background-color: transparent;
    border-bottom:3px solid #00ADEE;
}

.Admintab .nav-pills .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
}

.Admintab .nav-pills .nav-link {
    border-radius:0px;
    color: #fff;
    padding: 1rem 1rem;
    border-bottom:3px solid transparent;
}

.tab-detail{
    width: 100%;
    float: left;
    background-image: url(./img/vender-png.png);
    background-size: contain;
    background-repeat: no-repeat;
    float: left;
    width: 100%;
    background-position: top right;
    padding: 45px 0;
}

.tab-content {
    width: 100%;
    float: left;
}

.tile-bg {
    box-shadow: -2px 3px 15px rgba(0, 0, 0, .2);
    background:#fff;
    border-radius: 10px;
    font-size: 18px;
    padding: 10px 10px 30px !important;
    width: 100%;
    float: left;
}
.tab-content .tab-pane.shdaow-bg {
    width: 100%;
    float: left;
    box-shadow: -2px 3px 15px rgba(0, 0, 0, .2);
    background:#fff;
    border-radius: 10px;
    font-size: 18px;
    padding: 30px 10px !important;
}

.tile-bg .MuiTabs-flexContainer {
    display: flex;
    border-bottom: 3px solid #e5e6e7;
}

.MuiAppBar-colorPrimary {
    color: #fff;
    background-color:#00649d !important;
    box-shadow: none;
}

.PrivateTabIndicator-colorSecondary-73 {
    background-color: #00aeef !important;
}

.tab-content .tab-pane label{
    font-size: 14px;
    color:#6d6e70;
    font-weight: normal;
    line-height: 22px;
    display: block;
    width: 100%;
    margin-bottom: .5rem;
}

.small-text {
    font-size: 12px !important;
    opacity: 0.4;
    margin: 0 !important;
}

.tab-content .tab-pane input, .tab-content .tab-pane textarea {
    border: none;
    background-color: #EFEFEF;
    padding: 8px;
    width: 100%;
    border-radius: 5px;
    font-size: 14px;
    margin: 0;
    min-height:43px;
}

.btn.btn-red {
    font-weight: bold;
    font-size: 16px;
    border-radius: 0;
    padding: 10px 35px;
    background:#ec1c24;
    border-radius: 5px !important;
    color:white;
    line-height: 26px;
    border: 2px solid #ec1c24;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3) !important;
}

.title-goes img {
    width: 100%;
    height: 100%;
    object-fit: cover;
object-position: center;
}

.border-raidus {
    border-radius: 5px;
}

.title_here {
    width: 100%;
    float: left;
    color:#000a12;
}

.title_pirce {
    color:
            #000a12;
    font-weight: bold;
    font-size: 24px;
    margin: 10px 0 10px;
}

.rating {
    width: 100%;
    float: left;
}

.rating img {
    float: left;
}

.tab-content .tab-pane .title_here p {
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
}

.tab-content .tab-pane .title_here p {
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    color: #6d6e70;
}

.tab-content h4{
    font-size: 18px;
}

.bottom-block {
    width: 100%;
    float: left;
}

.bottom-block .title_price2 {
    color:
            #000 !important;
    font-weight: bold !important;
    font-size: 24px !important;
    float: left;
    line-height: 41px;
    margin: 0 !important;
}

.bottom-block .button_edit {
    width: 180px;
    height: 40px;
    font-size: 18px;
    text-align: center;
    line-height: 32px;
    float: right;
    border-radius: 5px;
    padding: 4px 8px;
    text-transform: uppercase;
    color: #fff;
    background: #007dc4;
    border-color: #007dc4
}

#pills-mybooking p{
    margin: 0;
}

.prize-block-booked {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 32px 0 0;
    float: left;
    border-top: 1px solid #ddd;
    padding: 10px 0 0;
}

.block-action {
    width: 100%;
    float: right;
    border-left:none !important;
    min-height: 180px;
}

.block-action .rating {
    float: right;
    text-align: right;
}

.block-action .rating h5 {
    color:
            #6d6e70;
    margin: 0;
    font-size: 18px;
}

.block-action .rating p {
    margin: 0;
    float: right;
}

.block-action .rating img {
    float: right;
}

.action-button {
    text-align: right;
    width: 100%;
    float: left;
    margin: 5px 0 0;
}

.block-action .action-button .btn-blue{
    width: 140px;
    text-align: center;
    background:#007dc4;
    border-radius: 5px !important;
    font-size: 16px;
    color:white;
    border: 2px solid#007dc4;
    padding:5px 5px;
    float: right;
}

.block-action .action-button .btn{
    background:#ec1c24;
    border-radius: 5px !important;
    border: 2px solid #ec1c24;
    color:white;
    margin: 5px 0;
    font-size: 16px;
    float: none;
    line-height: 13px;
    float: right;
    width: 140px;
    text-align: center;
    padding: 10px 5px;
}

.note-p {
    font-size: 14px !important;
    margin: 10px 0 0 !important;
    width: 100%;
    color: #6d6e70 !important;
    float: left;
}
.rating.reviewtab p {
    color: #00adee;
    margin: 0 13px 0 0;
    float: left;
    width: auto;
}

.rating.reviewtab img {
    float: left;
}

.bottom-block .readmore-red {
    float: left;
    border-radius: 5px;
    height: 40px;
    line-height: 35px;
    padding: 4px 8px;
    text-transform: uppercase;
    font-size: 18px;
    background: #ec1c24;
    color:#fff !important;
    width: 150px;
    text-align: center;
}


.review-saller-title h4 {
    width: auto;
    float: left;
}

.act-booking-block {
    width: 100%;
    float: left;
    background: #fff;
    border: 1px solid#ddd;
    border-radius: 10px;
    padding: 15px;
}

.tab-pane p i {
    color:#b2b2b2;
}

.act-booking-block h5 {
    color:#007dc4;
    margin: 20px 0;
    font-size: 24px;
}

.act-booking-block .detail {
    width: 100%;
    float: left;
}

.act-booking-block .detail p {
    font-size: 13px;
    float: left;
    font-weight: normal;
    line-height: 22px;
    margin: 0;
    width: 100%;
}

.act-booking-block .act-booking-block {
    border: none;
    padding: 0;
    border-radius: 0;
}

.act-booking-block .act-booking-block span {
    width: 100%;
    float: left;
    display: block;
}



.detail p span.font-24 {
    font-size: 24px;
    width: 100%;
    float: left;
    color:#007dc4;
}

.w-45 {
    width: 46% !important;
}

.act-booking-block table tr td {
    color: #6d6e70 !important;
    border-top: 1px solid#dee2e6;
    font-size: 14px;
}

.act-booking-block .Verify {
    background: #e4f0c3;
    color:#6a8e06;
    border: 1px solid#6a8e06;
    padding: 7px 15px 5px;
    border-radius: 3px;
}

.review-saller-title {
    width: 100%;
    float: left;
}

.review-saller-title .rating {
    width: auto;
    float: left;
}

.review-saller-title .rating img {
    margin: 0 10px 0 10px;
}

.review-saller-title .rating p {
    margin: 0;
    float: left;
}

.review-user-img {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
    float: left;
    margin: 0 20px 0 0;
}

.review-user-img img {
    width: 100%;
}

.review-user-info {
    float: left;
    width: 90%;
}

.review-user-info .review-user-name {
    font-size: 18px;
    text-transform: capitalize;
}

.review-user-info p {
    color: #6d6e70;
    font-size: 14px;
}

.review-blog-img img {
    height: 128px;
    border-radius: 5px;
}

.review-blog-img h5 {
    font-size: 16px;
    margin: 10px 0 0;
}

.title-goes {
    width: 100%;
    float: left;
    height:200px;
    border-radius: 5px;
    overflow: hidden;
}

.title-top {
    width: 100%;
    float: left;
}

.best-seller {
    float: left;
    background:#f5821f;
    color:#fff;
    font-size: 12px;
    padding: 2px 7px 1px;
    border-radius: 3px;
    margin: 0 5px 0 0;
}

.title_here {
    width: 100%;
    float: left;
    color:  #000a12;
}

.title_pirce {
    color:#000a12;
    font-weight: bold;
    font-size: 24px;
    margin: 10px 0 10px;
}
.vendor-bottom-block {
    width: 100%;
    float: left;
    margin: 30px 0 0;
}

.title_price2 {
    color: #000 !important;
    font-weight: bold !important;
    font-size: 21px !important;
    float: left;
}

.title_price2 .line-through {
    display: block;
    text-decoration: line-through;
    color: #999;
    font-size: 16px;
    margin: 0;
    line-height: 16px;
}

.vendor-bottom-button {
    float: right;
}

.vendor-bottom-button a {
    height: 35px;
    width: 100px;
    padding: 5px 18px !important;
}

.button_view {
    background:#00adee !important;
    border-color:#00adee !important;
}

.vendor-bottom-button form {
    margin: 0;
    float: right;
}

.tab-content .row .vendor-bottom-button .btn {
    margin: 0 0 0 15px;
    line-height: 21px;
    height: 35px;
    width: 100px;
    padding: 5px 18px !important;
}

.manage-activity .pro-detail {
    background:transparent;
}

.manage-activity .pro-detail .productinfo {
    background:transparent;
}

.manage-activity .pro-detail .nav-pills {
    padding-left: 0 !important;
}

.manage-activity .carousel {
    position: relative;
    width: 100%;
    float: left;
    height: 240px;
    border-radius: 10px !important;
    overflow: hidden;
}

.priceOption .table-input{
    border: none !important;
}

button.add-table {
    transition: all 0.5s;
    border: 2px solid #00aeef;
    background-color:#00aeef;
    padding: 4px 15px;
    font-size: 18px;
    color:#fff;
    font-weight: bold;
    border-radius: 5px;
    width: 160px;
    height: 50px;
    margin: 10px 0 0;
}

.form-inlines .white-btn {
    margin-right: 15px !important;
    margin: 5px 0 0;
    margin-right: 0px;
    border: none;
    background:#e2e3e4;
    color:#26ABE2;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 600;
    padding: 0;
}

.form-inlines .label-containers {
    display: block;
    position: relative;
    padding: 10px 10px 7px 31px;
    cursor: pointer;
    font-size: 13px !important;
    user-select: none;
    margin-bottom: .1rem !important;
}

.form-inlines .label-containers input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 0;
}

.form-inlines .label-containers .checkmarks {
    position: absolute;
    top: 11px;
    left: 8px;
    height: 16px;
    width: 16px;
    background-color:#fff;
}

.form-inlines .red-btn {
    background: #ec1c24;
    color: #fff;
}

.form-inlines .red-btn .label-containers {
    color: #fff;
}

.add-activity-block .nav-tabs {
    border-bottom: none !important;
}

.add-activity-block .tab-content {
    border: 1px solid  #ddd;
    border-radius: 0 5px 5px 5px;
    padding: 15px;

}

.add-activity-block .nav-tabs .nav-item.active {
    color: #495057 !important;
    background-color:#fff !important;
    border-color: #dee2e6 #dee2e6 #fff !important;
    border: 1px solid transparent !important;
    border-color: #dee2e6 #dee2e6 #fff !important;
    padding: 8px 15px !important;
}

.add-activity-block .nav-tabs .nav-item {
    font-size: 15px;
    color: #495057 !important;
    background-color:#efefef !important;
    border-color: #dee2e6 #dee2e6 #fff !important;
    border: 1px solid transparent !important;
    padding: 8px 15px !important;
    margin: 0 5px !important;
}

.custom-color .MuiTabs-indicator, .tile-bg .MuiTabs-indicator{
    background-color: #00ADEE;
    height: 4px;
}

.form-block {
    width: 100%;
    float: left;
}

.inner-activity .tab-content {
    padding: 15px;
    border-width: 0 1px 1px 1px;
    border-color:#ddd;
    border-style: solid;
}

.manage-ul .nav-link{
    border-radius: .25rem;
    background: #efefef;
    color: #6d6e70;
    margin: 0 10px 0 0;
    position: relative;
    font-size: 14px;
    padding: 5px 15px;
    border:none;
}

.manage-ul .nav-link.active{
    color:#fff;
background-color:#ec1c24;
}

.manage-ul .nav-link.active::after {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #ec1c24;
    position: absolute;
    content: '';
    left: 49%;
    bottom: -7px;
    right: 0;
    text-align: center;
    transform: translateX(-49%);
}